import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'flex-start', hint: 'Align items to the start of the flex direction' },
  { value: 'center', hint: 'Align items to the middle of the flex direction' },
  { value: 'flex-end', hint: 'Align items to the end of the flex direction' },
  { value: 'space-around', hint: 'Equalize space around all items' },
  { value: 'space-between', hint: 'Equalize space between all items – first and last at edges' },
]

const JustifyContent: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default JustifyContent
