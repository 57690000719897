import * as React from 'react'
import styled from 'styled-components/macro'
import { extname } from 'path'
import { folders } from '../../Sidebar'
import { ReactComponent as Yes } from './yes.svg'
import { ReactComponent as No } from './no.svg'

const Wrapper = styled.div`
  display: flex;
`

const TextField = styled.input`
  background: ${({ theme }) => theme.colors.BACKGROUND};
  border: 2px solid ${({ theme }) => theme.colors.FOREGROUND_20};
  border-radius: 0;
  color: ${({ theme }) => theme.colors.FOREGROUND_90};
  font-weight: 700;
  height: 35px;
  outline: 0;
  padding: 7px 10px;
  width: 138px;
  -webkit-appearance: none;
`

const Options = styled.div`
  align-items: center;
  display: flex;

  svg {
    margin-right: 0 !important;
  }
`

const Namer: React.FunctionComponent<{
  initialFilename?: string
  done: (filename: string) => void
  close: () => void
  defaultExtension: string | undefined
}> = ({ close, done, initialFilename, defaultExtension }) => {
  const [filename, setFilename] = React.useState(initialFilename || '')
  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const checkFilename = () => {
    if (filename.includes('.') || !defaultExtension) {
      if (initialFilename) {
        const currentExtension = extname(initialFilename)
        const newExtension = extname(filename)
        const currentFolder = folders.find((folder) =>
          folder.validExtensions.includes(currentExtension),
        )
        const newFolder = folders.find((folder) => folder.validExtensions.includes(newExtension))
        if (currentFolder?.slug === newFolder?.slug) {
          done(filename)
        } else {
          done(filename.split(newExtension).join(currentExtension))
        }
      } else {
        done(filename)
      }
    } else {
      done(`${filename}${defaultExtension}`)
    }
    close()
  }

  return (
    <Wrapper>
      <TextField
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            checkFilename()
          } else if (e.key === 'Escape') {
            close()
          }
        }}
        onChange={(e) => setFilename(e.target.value)}
        value={filename}
      />
      <Options>
        <Yes onClick={() => checkFilename()} />
        <No onClick={() => close()} />
      </Options>
    </Wrapper>
  )
}

export default Namer
