import * as monaco from 'monaco-editor'
import { debounce } from 'lodash-es'
import { File, isLockedFile } from '../../namespaces/files/state'
import { Popper, Button, Decoration } from '../../namespaces/poppers/state'
import { Marker } from '../../namespaces/markers/state'
import { getLanguageFromFile, isCodeFile, lineAndColumnToIndex, indexToLineAndColumn } from './lib'
import models from './models'
import theme from './theme'
import * as css from '../../../workers/css.worker'
import * as html from '../../../workers/html.worker'
import * as js from '../../../workers/js.worker'

export type CachedModel = {
  id: string
  filename: string
  rank: number
  model: monaco.editor.ITextModel
  language: string | undefined
  changeListener?: monaco.IDisposable
  prevState: monaco.editor.ICodeEditorViewState | null
  isLocked: boolean
  isActive: boolean
}
type OnCodeChanged = ({ id, blob }: { id: string; blob: string }) => Promise<void>
type GetDraftFiles = () => File[]
type SetPopper = (popper: Popper | null | undefined) => void
type SetMarkers = (markers: Marker[]) => void
type ResetMarkers = () => void
type HandleFontFile = (blob: string) => void
type Prettify = (
  file: { filename: string; blob: string | null },
  cursorIndex: number,
) => Promise<{ formatted: string; cursorOffset: number }>
type ToggleBetweenViews = () => void

export default (() => {
  let editor: monaco.editor.IStandaloneCodeEditor | null
  let getDraftFiles: GetDraftFiles | null
  let onCodeChanged: OnCodeChanged | null
  let setPopper: SetPopper | null
  let setMarkers: SetMarkers | null
  let resetMarkers: ResetMarkers | null
  let handleFontFile: HandleFontFile | null
  let cachedDecorations: Decoration[] = []
  let cachedDecorationPositions: string[] = []
  let lastChangeExternal: boolean = false
  let activePopper: Popper | null = null
  let prettier: { prettify: Prettify } | null
  let browser: { toggleBetweenViews: ToggleBetweenViews } | null

  const initialise = (options: {
    getDraftFiles: GetDraftFiles
    onCodeChanged: OnCodeChanged
    setPopper: SetPopper
    setMarkers: SetMarkers
    resetMarkers: ResetMarkers
    handleFontFile: HandleFontFile
    prettier: { prettify: Prettify }
    browser: { toggleBetweenViews: ToggleBetweenViews }
  }) => {
    getDraftFiles = options.getDraftFiles
    onCodeChanged = options.onCodeChanged
    setPopper = options.setPopper
    setMarkers = options.setMarkers
    resetMarkers = options.resetMarkers
    handleFontFile = options.handleFontFile
    prettier = options.prettier
    browser = options.browser

    monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true)
    monaco.editor.defineTheme('SuperHi', theme)
    monaco.editor.setTheme('SuperHi')

    const usePrettier = {
      async provideDocumentFormattingEdits(model: monaco.editor.ITextModel) {
        const cursorIndex = getCursorIndex()
        const activeModel = models.getActiveModel()
        if (prettier && activeModel && activeModel.model.id === model.id) {
          const { formatted } = await prettier.prettify(
            { filename: activeModel.filename, blob: model.getValue() },
            cursorIndex,
          )
          return [
            {
              range: model.getFullModelRange(),
              text: formatted,
            },
          ]
        }
      },
    }

    monaco.languages.registerDocumentFormattingEditProvider('javascript', usePrettier)
    monaco.languages.registerDocumentFormattingEditProvider('html', usePrettier)
    monaco.languages.registerDocumentFormattingEditProvider('css', usePrettier)
  }

  const findDecoration = (element: Element) => {
    const className = [...element.classList].find((className) => className.startsWith('id-'))
    return {
      decoration: cachedDecorations.find((face) => `id-${face.id}` === className),
      className,
    }
  }

  const mount = async (el: HTMLElement) => {
    editor = monaco.editor.create(el, {
      fontSize: 15,
      fontFamily: `PlexMono, Menlo, Monaco, "Courier New", monospace`,
      fontWeight: '600',
      lineHeight: 26,
      tabSize: 2,
      folding: false,
      renderIndentGuides: true,
      glyphMargin: true,
      minimap: { enabled: false },
      automaticLayout: true,
    })

    const captureCursor = (e: MouseEvent) => {
      if (
        setPopper &&
        activePopper &&
        !(e.target as HTMLElement)?.classList.contains('monaco-face')
      ) {
        setPopper(null)
        document.removeEventListener('mousemove', captureCursor)
      }
    }

    editor.onMouseMove((e) => {
      if (setPopper && e.target.element?.classList.contains('monaco-face')) {
        const { decoration: face, className } = findDecoration(e.target.element)
        if (face) {
          const faceWithClassName = { ...face, className }
          activePopper = faceWithClassName
          setPopper(faceWithClassName)
          document.addEventListener('mousemove', captureCursor)
        }
      } else if (setPopper && activePopper) {
        activePopper = null
        setPopper(null)
        document.removeEventListener('mousemove', captureCursor)
      }
    })

    editor.onMouseDown((e) => {
      if (setPopper && e.target.element?.classList.contains('monaco-button')) {
        const { decoration: button, className } = findDecoration(e.target.element)
        setPopper(button ? { ...button, className } : button)
        e.event.preventDefault()
      } else if (setPopper) {
        // setPopper(null)
      }
    })

    editor.addAction({
      id: 'switch-views',
      label: 'Switch Between Editor & Preview',
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_K],
      run() {
        if (browser?.toggleBetweenViews) {
          browser.toggleBetweenViews()
        }
      },
    })

    if (getDraftFiles) {
      getDraftFiles().forEach((file) => {
        if (isCodeFile(file)) {
          createModel(file)
        }
      })
    }
  }

  const unMount = () => {
    models.getAllModels().forEach((model) => {
      if (getLanguageFromFile(model.filename) === 'javascript') {
        monaco.editor.setModelMarkers(model.model, 'eslint', [])
      }
    })
    models.deleteAllModels()
    css.reset()
    js.reset()
    if (editor) {
      cachedDecorations = []
      cachedDecorationPositions = editor.deltaDecorations(cachedDecorationPositions, [])
      editor.dispose()
    }
    if (resetMarkers) {
      resetMarkers()
    }
  }

  const createModel = (file: File) => {
    const language = getLanguageFromFile(file.filename)
    const modelModel = monaco.editor.createModel(
      file.blob || '',
      language,
      new monaco.Uri().with({ path: file.filename }),
    )
    const model = models.addModel({
      id: file.id,
      filename: file.filename,
      model: modelModel,
      rank: file.rank,
      language,
      prevState: null,
      isLocked: isLockedFile(file),
      isActive: file.isActive ? true : false,
    })
    if (model.isActive) {
      activateModel(model.id)
    } else {
      lint(model, file.blob || '')
    }
    addModelListener(model.id)
    debouncedErrorsAndWarnings()
  }

  const deleteModel = (file: File) => {
    const model = models.getModel(file.id)
    if (model) {
      if (getLanguageFromFile(model.filename) === 'javascript') {
        monaco.editor.setModelMarkers(model.model, 'eslint', [])
      }
      models.deleteModel(model)
      css.deleteHtml(model.id)
      js.deleteFile(model.id)
    }
  }

  const renameModel = (file: File) => {
    const activeModel = models.getActiveModel()
    deleteModel(file)
    createModel(file)
    if (activeModel?.id === file.id) {
      activateModel(file.id)
    }
  }

  const activateModel = (activeModelId: string) => {
    const model = models.getModel(activeModelId)
    const activeModel = models.getActiveModel()
    if (editor && model) {
      if (activeModel) {
        activeModel.prevState = editor.saveViewState()
        activeModel.isActive = false
      }
      editor.setModel(model.model)
      editor.updateOptions({ readOnly: model.isLocked })
      if (model.prevState) {
        editor.restoreViewState(model.prevState)
      }
      lint(model, model.model.getValue())
      model.isActive = true
    }
  }

  const addModelListener = (modelId: string) => {
    const model = models.getModel(modelId)
    if (model) {
      model.changeListener = model.model.onDidChangeContent(() => {
        const code = model.model.getValue()
        if (onCodeChanged && !lastChangeExternal) {
          onCodeChanged({ id: modelId, blob: model.model.getValue() })
        }
        lastChangeExternal = false
        debouncedLint(model, code)
      })
    }
  }

  const setErrorsAndWarnings = () => {
    const markers = monaco.editor.getModelMarkers({})
    if (setMarkers) {
      setMarkers(
        markers.map(
          ({
            code,
            endColumn,
            endLineNumber,
            message,
            resource,
            severity,
            startColumn,
            startLineNumber,
          }) => ({
            code: (code as any)?.value || code || '',
            endColumn,
            endLineNumber,
            message,
            severity:
              severity === monaco.MarkerSeverity.Error
                ? 'ERROR'
                : severity === monaco.MarkerSeverity.Warning
                ? 'WARNING'
                : 'OTHER',
            startColumn,
            startLineNumber,
            filename: resource.path.split('/')[1],
          }),
        ),
      )
    }
  }

  // give monaco enough time to do it's thing internally
  const debouncedErrorsAndWarnings = debounce(setErrorsAndWarnings, 1000)

  const buildButton = (decoration: Button, additionalColumns: number = 1) => {
    const { line } = decoration.start
    const column = decoration.start.column + decoration.property.length + additionalColumns
    return {
      range: new monaco.Range(line, column, line, column),
      options: {
        isWholeLine: false,
        afterContentClassName: `monaco-button monaco-button--${decoration.property} id-${decoration.id}`,
        stickiness: monaco.editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
      },
    }
  }

  const buildFonts = async (model: CachedModel, value: string) => {
    const { fontString, version } = await css.buildFonts({
      value,
      id: model.id,
      filename: model.filename,
      version: model.model.getVersionId(),
    })
    // if we switch back-and-forth from editor to preview too quickly monaco
    // won't have access to the `model.model` when it thinks it does, so lets
    // capture any errors here
    try {
      if (handleFontFile && model.model?.getVersionId() === version) {
        handleFontFile(fontString)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const lintCss = async (model: CachedModel, value: string) => {
    buildFonts(model, value)
    const { decorations, version } = await css.checkCode({
      value,
      version: model.model.getVersionId(),
    })
    const activeModel = models.getActiveModel()
    // only update the decorations if the model is currently active
    if (activeModel?.id === model.id && activeModel?.model.getVersionId() === version && editor) {
      cachedDecorations = decorations
      cachedDecorationPositions = editor.deltaDecorations(
        cachedDecorationPositions,
        decorations.map((decoration) => {
          if (decoration.type === 'FACE') {
            return {
              range: new monaco.Range(decoration.line, 1, decoration.line, 1),
              options: {
                isWholeLine: false,
                glyphMarginClassName: `monaco-face monaco-face--${decoration.state} id-${decoration.id}`,
                stickiness: monaco.editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
              },
            }
          } else {
            return buildButton(decoration)
          }
        }),
      )
    }
  }

  const lintHtml = async (model: CachedModel, value: string) => {
    css.addorUpdateHtml({
      id: model.id,
      filename: model.filename,
      value,
      version: model.model.getVersionId(),
    })
    const { buttons, version } = await html.checkCode({
      value,
      version: model.model.getVersionId(),
    })
    const activeModel = models.getActiveModel()
    if (activeModel?.id === model.id && activeModel?.model.getVersionId() === version && editor) {
      cachedDecorations = buttons
      cachedDecorationPositions = editor.deltaDecorations(
        cachedDecorationPositions,
        buttons.map((button) => buildButton(button, 2)),
      )
    }
  }

  const lintJs = async (model: CachedModel, value: string) => {
    const { messages, version } = await js.checkJs({
      id: model.id,
      filename: model.filename,
      value,
      rank: model.rank,
      version: model.model.getVersionId(),
    })
    const activeModel = models.getActiveModel()
    if (activeModel?.id === model.id && activeModel?.model.getVersionId() === version && editor) {
      monaco.editor.setModelMarkers(
        model.model,
        'eslint',
        messages.map((message) => ({
          startLineNumber: message.line,
          startColumn: message.column,
          endLineNumber: message.endLine || message.line,
          endColumn: message.endColumn || message.column,
          severity: [2, 4, 8][message.severity],
          message: message.message,
        })),
      )
    }
  }

  const lint = async (model: CachedModel, value: string) => {
    if (model.language === 'css' && !model.isLocked) {
      await lintCss(model, value)
    } else if (model.language === 'html') {
      await lintHtml(model, value)
    } else if (model.language === 'javascript') {
      await lintJs(model, value)
    }
    debouncedErrorsAndWarnings()
  }

  const debouncedLint = debounce(lint, 400)

  const updateModelCode = ({
    id,
    blob,
    externalChange,
  }: {
    id: string
    blob: string
    externalChange?: boolean
  }) => {
    const model = models.getModel(id)
    if (externalChange) {
      lastChangeExternal = true
    }
    if (model && blob !== model.model.getValue()) {
      model.model.pushEditOperations(
        [],
        [{ range: model.model.getFullModelRange(), text: blob }],
        () => null,
      )
    }
  }

  const updateModelCodeAndCursor = ({
    id,
    blob,
    cursorIndex,
  }: {
    id: string
    blob: string
    cursorIndex: number
  }) => {
    updateModelCode({ id, blob })
    requestAnimationFrame(() => {
      const model = editor?.getModel()
      if (model && editor) {
        const position = indexToLineAndColumn(model.getLinesContent(), cursorIndex)
        editor.setPosition(position)
      }
    })
  }

  const updatePartialActiveModelCode = ({
    range,
    text,
  }: {
    range: [number, number, number, number]
    text: string
  }) => {
    const activeModel = models.getActiveModel()
    if (activeModel) {
      activeModel.model.pushEditOperations(
        [],
        [{ range: new monaco.Range(...range), text }],
        () => null,
      )
    }
  }

  const getCursorIndex = (): number => {
    const position = editor?.getPosition()
    const model = editor?.getModel()
    if (position && model) {
      const lines = model.getLinesContent()
      return lineAndColumnToIndex(lines, position.lineNumber, position.column)
    } else {
      return 0
    }
  }

  const getClassNames = async (): Promise<string[]> => {
    const cachedModels = models.getAllModels()
    const cssValues = cachedModels
      .filter((model) => model.language === 'css' && !model.isLocked)
      .map((model) => model.model.getValue())
    const htmlValues = cachedModels
      .filter((model) => model.language === 'html' && !model.isLocked)
      .map((model) => model.model.getValue())
    const [cssClassNames, htmlClassNames] = await Promise.all([
      css.getClasses(cssValues),
      html.getClasses(htmlValues),
    ])
    return [...new Set(cssClassNames.concat(htmlClassNames))]
  }

  return {
    initialise,
    mount,
    unMount,
    createModel,
    deleteModel,
    renameModel,
    activateModel,
    updateModelCode,
    updateModelCodeAndCursor,
    updatePartialActiveModelCode,
    getCursorIndex,
    getClassNames,
  }
})()
