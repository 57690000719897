import * as React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Heading, Body, Button, Link } from '@superhi/design'
import { useActions, useEffects } from '../overmind'

const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.FOREGROUND_10};
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow-y: scroll;
  padding: 20px;
  position: absolute;
  right: 0;
  width: calc(100vw - 60px);
  z-index: 1;
`

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.BACKGROUND};
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  padding: 20px;
  text-align: center;
  width: 600px;
`

const Text = styled(Body)`
  margin: 0 auto 20px;
  max-width: 400px;
`

const SmallText = styled(Body).attrs(() => ({ level: '4' }))`
  background: ${({ theme }) => theme.colors.FOREGROUND_10};
  padding: 20px;
`

const Publish: React.FunctionComponent = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const {
    setTitleWithProject,
    projects: { buildUrl },
    versions: { publish },
  } = useActions()
  const {
    browser: { openWindow },
  } = useEffects()
  const [hasPublished, setHasPublished] = React.useState(false)

  React.useEffect(() => {
    setTitleWithProject('Publish')
  }, [setTitleWithProject])

  const projectUrl = buildUrl()

  return (
    <Wrapper>
      <Container>
        <div>
          <Heading level="3">
            {hasPublished ? 'Your project has been published' : 'Publish your project'}
          </Heading>
          <Text>
            {hasPublished
              ? `Great! Your project is now online. You can add a custom domain to your project or continue updating your site.`
              : `Once you're happy, publish your changes and your website will be found at:`}
          </Text>
          <Link href={projectUrl} isExternal={true}>
            <Heading level="4">{projectUrl}</Heading>
          </Link>
        </div>
        <Button
          onClick={() => {
            if (hasPublished) {
              openWindow(projectUrl)
            } else {
              publish()
              setHasPublished(true)
            }
          }}
        >
          {hasPublished ? 'View your site' : 'Publish changes'}
        </Button>
      </Container>
      <NavLink to={`/projects/${projectId}/versions`}>
        <SmallText>Not ready to publish but want to save your code?</SmallText>
      </NavLink>
    </Wrapper>
  )
}

export default Publish
