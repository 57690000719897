import * as React from 'react'
import styled from 'styled-components/macro'
import { Heading, Body } from '@superhi/design'
import { useState, useActions } from '../overmind'
import Domain from '../components/Domain'
import CreateDomain from '../components/CreateDomain'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.BACKGROUND};
  bottom: 0;
  height: 100vh;
  overflow-y: scroll;
  padding: 20px 40px;
  position: absolute;
  right: 0;
  width: calc(100vw - 60px);
  z-index: 1;
`

const Container = styled.div`
  width: 500px;
`

const Text = styled(Body).attrs(() => ({ level: '4' }))`
  background: ${({ theme }) => theme.colors.FOREGROUND_10};
  margin: 20px 0 40px;
  padding: 20px;
`

const Settings: React.FunctionComponent = () => {
  const {
    projects: { active: project },
    domains,
  } = useState()
  const {
    setTitleWithProject,
    projects: { buildUrl },
  } = useActions()

  React.useEffect(() => {
    setTitleWithProject('Domains')
  }, [setTitleWithProject])

  return (
    <Wrapper>
      <Container>
        <Heading level="3">{project ? project.name : 'Project'}'s Domains</Heading>
        <Text>
          Want your project to be hosted on your own domain? Just add your custom domain here and
          follow the instructions in the video below.
        </Text>
        {project && <Domain url={buildUrl(project.id)} />}
        {Object.values(domains.entries).map((domain) => (
          <Domain key={domain.id} domain={domain} />
        ))}
        <CreateDomain />
      </Container>
    </Wrapper>
  )
}

export default Settings
