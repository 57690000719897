import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'row', hint: 'Items within will order across the tag' },
  { value: 'column', hint: 'Items within will order downwards the tag' },
]

const FlexDirection: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default FlexDirection
