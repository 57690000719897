import * as React from 'react'
import styled from 'styled-components/macro'
import Markers from './Markers'
import PageOptions from '../Poppers/PageOptions'
import { useState, useActions } from '../../overmind'
import { isLockedFile, FolderFile } from '../../overmind/namespaces/files/state'
import { isCodeFile } from '../../overmind/effects/monaco/lib'

const Wrapper = styled.div<{ isLocked: boolean }>`
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.FOREGROUND_10};
  color: ${({ isLocked, theme }) => (isLocked ? theme.colors.ERROR : theme.colors.PRIMARY)};
  display: flex;
  font-family: 'Px';
  font-size: 100%;
  flex: 0 0 62px;
  justify-content: space-between;
  padding: 0 30px;
  width: 100%;
`

const H2 = styled.h2`
  font-family: 'Px';
  font-size: 100%;
  font-weight: 700;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Span = styled.span`
  cursor: pointer;
  margin-left: 10px;
`

const Options: React.FunctionComponent<{ file: FolderFile }> = ({ file }) => {
  const [showPopper, setShowPopper] = React.useState(false)
  const buttonEl = React.useRef<HTMLSpanElement>(null)
  return (
    <>
      <Span ref={buttonEl} onClick={() => setShowPopper((showPopper) => !showPopper)}>
        Options
      </Span>
      {showPopper && buttonEl.current && <PageOptions reference={buttonEl.current} file={file} />}
    </>
  )
}

const Top: React.FunctionComponent = () => {
  const {
    files: { active: file },
  } = useState()
  const {
    files: { prettify },
  } = useActions()
  const isLocked = file ? isLockedFile(file) : false
  const isCode = file ? isCodeFile(file) : null
  const isHtml = file?.folder === 'html' || null

  return (
    <Wrapper isLocked={isLocked}>
      {file && <H2>{file.filename}</H2>}
      <div>
        <Markers />
        {file && isHtml && <Options file={file} />}
        {isLocked ? (
          <Span>Locked</Span>
        ) : isCode ? (
          <Span onClick={() => file && prettify(file.id)}>Prettify</Span>
        ) : null}
      </div>
    </Wrapper>
  )
}

export default Top
