import { theme } from '@superhi/design'

export const light = {
  colors: {
    BACKGROUND: theme.colors.WHITE,
    FOREGROUND_10: theme.colors.GREY_10,
    FOREGROUND_20: theme.colors.GREY_20,
    FOREGROUND_50: theme.colors.GREY_50,
    FOREGROUND_70: theme.colors.GREY_70,
    FOREGROUND_80: theme.colors.GREY_80,
    FOREGROUND_90: theme.colors.GREY_90,
    PRIMARY: theme.colors.BLUE_50,
    PRIMARY_SHADE: theme.colors.BLUE_40,
    WARNING: theme.colors.YELLOW_50,
    ERROR: theme.colors.RED_50,
    ERROR_DARKEN: theme.colors.RED_60,
  },
}
