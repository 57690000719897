export default (() => {
  const get = (token: string) => {
    if (token === 'NODE_ENV') {
      return process.env.NODE_ENV || 'development'
    } else {
      return process.env[`REACT_APP_${token}`] || ''
    }
  }

  return { get }
})()
