import { Popper } from './state'
import { Action } from '../../../overmind'

export const updateActive: Action<Popper | null | undefined> = ({ state }, popper) => {
  if (!popper && state.poppers.active) {
    state.poppers.active = null
  } else if (popper && popper.id) {
    if ((state.poppers.active && state.poppers.active.id !== popper.id) || !state.poppers.active) {
      state.poppers.active = popper
    }
  }
}
