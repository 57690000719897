import { Query, gql } from 'overmind-graphql'
import { ProjectCreate, ProjectCreateVariables } from './graphql-types/projectCreate'
import { ProjectUpdate, ProjectUpdateVariables } from './graphql-types/projectUpdate'
import { ProjectDelete, ProjectDeleteVariables } from './graphql-types/projectDelete'
import { projectFields } from './queries'

export const projectCreate: Query<ProjectCreate, ProjectCreateVariables> = gql`
  mutation ProjectCreate($name: String!) {
    projectCreate(name: $name) {
      ...ProjectFields
    }
  }
  ${projectFields}
`

export const projectUpdate: Query<ProjectUpdate, ProjectUpdateVariables> = gql`
  mutation ProjectUpdate(
    $id: ID!
    $name: String
    $slug: String
    $draftVersionId: ID
    $liveVersionId: ID
    $isStarred: Boolean
    $isPasswordProtected: Boolean
    $password: String
    $passwordConfirmation: String
  ) {
    projectUpdate(
      id: $id
      name: $name
      slug: $slug
      draftVersionId: $draftVersionId
      liveVersionId: $liveVersionId
      isStarred: $isStarred
      isPasswordProtected: $isPasswordProtected
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      ...ProjectFields
    }
  }
  ${projectFields}
`

export const projectDelete: Query<ProjectDelete, ProjectDeleteVariables> = gql`
  mutation ProjectDelete($id: ID!) {
    projectDelete(id: $id) {
      id
    }
  }
`
