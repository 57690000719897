import * as React from 'react'
import styled from 'styled-components/macro'
import Menu from './Menu'
import Frame from './Frame'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const InlinePreview: React.FunctionComponent = () => {
  return (
    <Wrapper>
      <Menu mode="INLINE" />
      <Frame mode="INLINE" />
    </Wrapper>
  )
}

export default InlinePreview
