import { v4 as uuidv4 } from 'uuid'

export default (() => {
  let sessionId: string = ''

  const initialise = () => {
    sessionId = uuidv4()
  }

  const get = () => sessionId

  return { initialise, get }
})()
