import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'block', hint: 'Act like a full width box' },
  { value: 'inline', hint: 'Act like part of text, think like a typical link or italicized text' },
  { value: 'inline-block', hint: 'Act like inline but you can have width and height' },
  { value: 'flex', hint: "Make the tag's contents layout using flexbox" },
  { value: 'none', hint: 'Hide element completely' },
]

const Display: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default Display
