import * as React from 'react'
import styled from 'styled-components/macro'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { FolderSlug } from '../../overmind/namespaces/files/state'
import Name from './Name'
import FolderComponent from './Folder'
import Uploader from './Uploader'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.FOREGROUND_10};
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  height: 100vh;
  overflow: hidden;
  width: 250px;
  z-index: 2;
`

const ScrollArea = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: nowrap;
  overflow-y: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.FOREGROUND_10};
    background-color: rgba(0, 0, 0, 0.4);
  }
`

export type Folder = {
  name: string
  slug: FolderSlug
  defaultExtension?: string
  validExtensions: string[]
  showOnEmpty?: boolean
  useUpload?: boolean
}

export const folders: Folder[] = [
  {
    name: 'Pages',
    slug: 'html',
    defaultExtension: '.html',
    validExtensions: ['.html'],
    showOnEmpty: true,
  },
  {
    name: 'Stylesheets',
    slug: 'css',
    defaultExtension: '.css',
    validExtensions: ['.css'],
    showOnEmpty: true,
  },
  {
    name: 'Scripts',
    slug: 'js',
    defaultExtension: '.js',
    validExtensions: ['.js', '.mjs', '.glsl'],
    showOnEmpty: true,
  },
  {
    name: 'Images',
    slug: 'image',
    validExtensions: ['.png', '.jpg', '.jpeg', '.gif', '.svg', '.ico'],
    useUpload: true,
  },
  {
    name: 'Fonts',
    slug: 'font',
    validExtensions: ['.woff', '.woff2', '.ttf', '.otf', 'eot'],
    useUpload: true,
  },
  {
    name: 'Videos',
    slug: 'video',
    validExtensions: ['.mov', '.mp4'],
    useUpload: true,
  },
  {
    name: 'Audio',
    slug: 'audio',
    validExtensions: ['.mp3', '.m4a'],
    useUpload: true,
  },
  {
    name: 'Data',
    slug: 'data',
    validExtensions: ['.json', '.xml', '.csv', '.obj', '.mtl', '.txt'],
    defaultExtension: '.txt',
  },
  {
    name: 'Documents',
    slug: 'document',
    validExtensions: ['.doc', '.docx', '.pdf', '.xls', '.xlsx'],
    useUpload: true,
  },
  {
    name: 'Other',
    slug: 'other',
    validExtensions: [],
  },
]

const Folders: React.FunctionComponent = () => (
  <ScrollArea>
    <DndProvider backend={HTML5Backend}>
      {folders.map((folder) => (
        <FolderComponent key={folder.slug} folder={folder} />
      ))}
    </DndProvider>
  </ScrollArea>
)

const Sidebar: React.FunctionComponent = () => {
  return (
    <Wrapper>
      <Name />
      <Folders />
      <Uploader />
    </Wrapper>
  )
}

export default Sidebar
