import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'uppercase', hint: 'SHOUT IT OUT LOUD' },
  { value: 'lowercase', hint: 'every letter is lower case' },
  { value: 'capitalize', hint: 'Capitalize Every Word In A Sentence' },
  { value: 'none', hint: 'Just the default' },
]

const TextTransform: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default TextTransform
