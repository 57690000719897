import * as React from 'react'
import { Switch, Route, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useState, useActions } from '../overmind'
import Loading from '../components/Loading'
import Nav from '../components/Nav'
import Poppers from '../components/Poppers'
import PreviewTitle from '../components/PreviewTitle'
import Editor from './Editor'
import Preview from './Preview'
import Publish from './Publish'
import Settings from './Settings'
import Versions from './Versions'
import Domains from './Domains'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  flex-wrap: nowrap;
  height: 100vh;
  width: 100vw;
`

const Project: React.FunctionComponent = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const {
    files: { haveLoaded },
  } = useState()
  const {
    projects: { activate, deactivate },
  } = useActions()

  React.useEffect(() => {
    activate(projectId)
    return () => {
      deactivate()
    }
  }, [projectId, activate, deactivate])

  return haveLoaded ? (
    <Wrapper>
      <Poppers />
      <Nav />
      <Preview />
      <Switch>
        <Route exact path="/projects/:projectId">
          <Editor />
        </Route>
        <Route path="/projects/:projectId/view">
          <PreviewTitle />
        </Route>
        <Route path="/projects/:projectId/publish">
          <Publish />
        </Route>
        <Route path="/projects/:projectId/settings">
          <Settings />
        </Route>
        <Route path="/projects/:projectId/versions">
          <Versions />
        </Route>
        <Route path="/projects/:projectId/domains">
          <Domains />
        </Route>
      </Switch>
    </Wrapper>
  ) : (
    <Loading />
  )
}

export default Project
