import { Query, gql } from 'overmind-graphql'
import { Session } from './graphql-types/session'
import { SessionFields } from './graphql-types/sessionFields'

export const sessionFields: Query<SessionFields> = gql`
  fragment SessionFields on Session {
    id
    email
    username
    isAdmin
    isFrozen
    token
    editorSettings {
      esLintEnabled
      previewEnabled
      inlinePreviewMode
      showSidebar
      useDarkMode
      hideWarnings
    }
    type
    membership {
      status
      plan {
        product {
          id
        }
      }
    }
  }
`

export const session: Query<Session> = gql`
  query Session {
    session {
      ...SessionFields
    }
  }
  ${sessionFields}
`
