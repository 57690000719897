import * as React from 'react'
import styled from 'styled-components/macro'
import { theme as designTheme } from '@superhi/design'
import { FolderFile } from '../../overmind/namespaces/files/state'

const Img = styled.img`
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
`

const SwitchingWrapper = styled.div<{ $isDark: boolean }>`
  align-items: center;
  background-color: ${({ $isDark }) =>
    $isDark ? designTheme.colors.WHITE : designTheme.colors.GREY_90};
  color: ${({ $isDark }) => ($isDark ? designTheme.colors.GREY_90 : designTheme.colors.WHITE)};
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  padding: 30px;
  position: relative;
  text-align: center;
  width: 100%;
`

const Size = styled.div`
  bottom: 30px;
  left: 30px;
  position: absolute;
`

const Image: React.FunctionComponent<{ file: FolderFile; src: string }> = ({ file, src }) => {
  const [isDark, setIsDark] = React.useState(true)
  const [size, setSize] = React.useState<[number, number]>([0, 0])
  const imageRef = React.useRef<HTMLImageElement>(null)

  React.useEffect(() => {
    if (imageRef.current) {
      // via http://jsfiddle.net/s7Wx2/328/
      const fuzziness = 0.1
      let img = imageRef.current
      img.crossOrigin = 'Anonymous'
      img.addEventListener('load', () => {
        const canvas = document.createElement('canvas')
        setSize([img.naturalWidth, img.naturalHeight])
        canvas.width = img.width
        canvas.height = img.height
        const context = canvas.getContext('2d')
        if (context) {
          context.drawImage(img, 0, 0)
          const { data } = context.getImageData(0, 0, canvas.width, canvas.height)
          let light = 0
          let dark = 0
          // TODO: we're doing this every 4 pixels rather than every pixel
          // but it's possible we can still optimise this further
          for (let x = 0, len = data.length; x < len; x += 16) {
            const r = data[x]
            const g = data[x + 1]
            const b = data[x + 2]
            if (Math.max(Math.max(r, g), b) < 128) {
              dark = dark + 1
            } else {
              light = light + 1
            }
          }
          const diff = (light - dark) / (img.width * img.height)
          setIsDark(diff + fuzziness < 0)
        }
        canvas.remove()
      })
    }
  }, [])

  return (
    <SwitchingWrapper $isDark={isDark}>
      <Img ref={imageRef} src={src} />
      {size[0] ? (
        <Size>
          {size[0]}px × {size[1]}px
        </Size>
      ) : null}
    </SwitchingWrapper>
  )
}

export default Image
