import { Query, gql } from 'overmind-graphql'
import { SessionCreate, SessionCreateVariables } from './graphql-types/sessionCreate'
import { sessionFields } from './queries'

export const sessionCreate: Query<SessionCreate, SessionCreateVariables> = gql`
  mutation SessionCreate($username: String!, $password: String!) {
    sessionCreate(username: $username, password: $password) {
      ...SessionFields
    }
  }
  ${sessionFields}
`
