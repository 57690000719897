import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'wrap', hint: 'Allow items to wrap onto another line' },
  { value: 'nowrap', hint: "Don't allow items to wrap" },
]

const FlexWrap: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default FlexWrap
