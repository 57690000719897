import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [{ value: 'solid' }, { value: 'dotted' }, { value: 'dashed' }]

const BorderStyle: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default BorderStyle
