import { extname } from 'path'
import { File, FolderFile } from '../../namespaces/files/state'

export const getLanguageFromFile = (filename: string): string | undefined => {
  const ext = extname(filename)
  const entry = Object.entries({
    '.html': 'html',
    '.js': 'javascript',
    '.mjs': 'javascript',
    '.css': 'css',
    '.json': 'json',
    '.xml': 'xml',
    '.csv': 'plaintext',
    '.obj': 'plaintext',
    '.mtl': 'plaintext',
    '.txt': 'plaintext',
    '.glsl': 'cpp',
  }).find(([key]) => key === ext)
  return entry ? entry[1] : undefined
}

export const isCodeFile = (file: File | FolderFile): boolean => !!getLanguageFromFile(file.filename)

// from https://github.com/codesandbox/codesandbox-client/blob/91407b08e9dd039f5ce0e782a9828f9e75746b6c/packages/app/src/app/utils/monaco-index-converter.js#L1-L14
export const lineAndColumnToIndex = (lines: string[], lineNumber: number, column: number) => {
  let currentLine = 0
  let index = 0

  while (currentLine + 1 < lineNumber) {
    index += lines[currentLine].length
    index += 1 // Linebreak character
    currentLine += 1
  }

  index += column - 1
  return index
}

// from https://github.com/codesandbox/codesandbox-client/blob/91407b08e9dd039f5ce0e782a9828f9e75746b6c/packages/app/src/app/utils/monaco-index-converter.js#L16-L36
export const indexToLineAndColumn = (lines: string[], index: number) => {
  let offset = 0
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]
    if (offset + line.length + 1 > index) {
      return {
        lineNumber: i + 1,
        column: index - offset + 1,
      }
    }
    // + 1 is for the linebreak character which is not included
    offset += line.length + 1
  }

  // +2 for column (length is already a +1), because +1 for Monaco and +1 for linebreak
  return {
    lineNumber: lines.length,
    column: (lines[lines.length - 1] || '').length + 1,
  }
}
