import { Query, gql } from 'overmind-graphql'

export const editorSettingsFields: Query<any> = gql`
  fragment EditorSettingsFields on EditorSettings {
    esLintEnabled
    previewEnabled
    inlinePreviewMode
    showSidebar
    useDarkMode
    hideWarnings
  }
`
