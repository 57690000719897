type FrameState = {
  isLoading: boolean
  currentLocation: string
  canGoBack: boolean
  canGoForward: boolean
}

export type Mode = 'FULLSCREEN' | 'INLINE'

const initialState: FrameState = {
  isLoading: true,
  currentLocation: '/index.html',
  canGoBack: false,
  canGoForward: false,
}

type State = {
  FULLSCREEN: FrameState
  INLINE: FrameState
}

export const state: State = {
  FULLSCREEN: initialState,
  INLINE: initialState,
}
