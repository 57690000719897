export type Domain = {
  id: string
  url: string
  insertedAt: any
  updatedAt: any
}

type State = {
  haveLoaded: boolean
  entries: { [id: string]: Domain }
}

export const state: State = {
  haveLoaded: false,
  entries: {},
}
