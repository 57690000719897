import * as React from 'react'
import styled from 'styled-components/macro'
import { format, formatDistance } from 'date-fns'
import { Heading, Body, Button } from '@superhi/design'
import { useActions, useEffects } from '../overmind'
import { Version } from '../overmind/namespaces/versions/state'

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.FOREGROUND_50};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const FriendlyKey = styled.span`
  background: ${({ theme }) => theme.colors.FOREGROUND_20};
  padding: 0 3px;
`

const Time = styled.span`
  color: ${({ theme }) => theme.colors.FOREGROUND_70};
`

const Spacer = styled.div`
  height: 10px;
`

const VersionComponent: React.FunctionComponent<{ version: Version }> = ({ version }) => {
  const {
    versions: { getFriendlyKey, getUrl, revert },
  } = useActions()
  const {
    browser: { openWindow },
  } = useEffects()
  const updatedAt = new Date(`${version.updatedAt}Z`)

  return (
    <Wrapper>
      <div>
        <Heading level="5">{version.title}</Heading>
        <Body level="4">
          Version <FriendlyKey>{getFriendlyKey(version.id)}</FriendlyKey>
          <br />
          {version.description && (
            <>
              {version.description}
              <br />
            </>
          )}
          <Time>
            {format(updatedAt, 'MMMM do yyyy, h:mma')} ({formatDistance(updatedAt, new Date())} ago)
          </Time>
        </Body>
      </div>
      <Column>
        <Button onClick={() => openWindow(getUrl(version.id))}>View</Button>
        <Spacer />
        <Button onClick={() => revert(version.id)}>Revert</Button>
      </Column>
    </Wrapper>
  )
}

export default VersionComponent
