import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'fixed', hint: 'Background will position to the browser view' },
  { value: 'scroll', hint: 'Background will position within the HTML tag as you scroll' },
]

const BackgroundAttachment: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default BackgroundAttachment
