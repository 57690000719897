import * as React from 'react'
import styled from 'styled-components/macro'
import { PageHeader, Button, PageSection as Section, VerticalSpacer, Grid } from '@superhi/design'
import { ProjectSort } from '../overmind/effects/gql/graphql-global-types'
import ProjectCard from '../components/ProjectCard'
import ProjectListing from '../components/ProjectListing'
import { useState, useActions } from '../overmind'

const Orderings = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin: 0 8px 8px 0;
  }
`

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 8px;
  }
`

const descriptiveSort: { [key in ProjectSort]: string } = {
  A_Z: 'A to Z',
  NEWEST: 'Newest',
  OLDEST: 'Oldest',
  Z_A: 'Z to A',
}

const GRID_1_CELL_SPAN_1: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 'full',
  medium: 4,
  large: 4,
}

const GRID_FULL: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 'full',
  medium: 'full',
  large: 'full',
}

const Projects: React.FunctionComponent = () => {
  const { projects } = useState()
  const {
    setTitle,
    projects: { updateCurrentSort, loadNextPage, toggleView },
  } = useActions()

  React.useEffect(() => {
    setTitle('Projects')
  }, [setTitle])

  return (
    <>
      <PageHeader title="Projects" backgroundVersion="BLUE_01" />
      <Section>
        <VerticalSpacer>
          <Grid alignItems="stretch">
            <Grid.Cell span={{ small: 2, medium: 4, large: 6 }}>
              <Orderings>
                {Object.entries(ProjectSort).map(([key, value]) => (
                  <Button
                    key={key}
                    version={key === projects.currentSort ? 'filled' : 'outline'}
                    onClick={() => updateCurrentSort(key as keyof typeof ProjectSort)}
                  >
                    {descriptiveSort[value]}
                  </Button>
                ))}
              </Orderings>
            </Grid.Cell>
            <Grid.Cell span={{ small: 2, medium: 4, large: 6 }}>
              <Right>
                <Button onClick={toggleView}>Toggle view</Button>
                <Button href="/projects/new">Create New Project</Button>
              </Right>
            </Grid.Cell>
          </Grid>
          <VerticalSpacer>
            <Grid>
              {projects.ordered.map((project) =>
                projects.currentView === 'CARD' ? (
                  <Grid.Cell key={project.id} span={GRID_1_CELL_SPAN_1}>
                    <ProjectCard project={project} />
                  </Grid.Cell>
                ) : (
                  <Grid.Cell key={project.id} span={GRID_FULL}>
                    <ProjectListing key={project.id} project={project} />
                  </Grid.Cell>
                ),
              )}
            </Grid>
          </VerticalSpacer>
          <VerticalSpacer>
            <Grid alignItems="center">
              <Grid.Cell span={GRID_1_CELL_SPAN_1}>
                {projects.hasNextPage && <Button onClick={loadNextPage}>Load more projects</Button>}
              </Grid.Cell>
            </Grid>
          </VerticalSpacer>
        </VerticalSpacer>
      </Section>
    </>
  )
}

export default Projects
