import { Query, gql } from 'overmind-graphql'
import { VersionFields } from './graphql-types/versionFields'
import { Versions, VersionsVariables } from './graphql-types/versions'

export const versionFields: Query<VersionFields> = gql`
  fragment VersionFields on Version {
    id
    key
    title
    description
    isDraft
    isLive
    metaTitle
    metaDescription
    sharingTitle
    sharingDescription
    sharingImage
    twitterHandle
    favicon
    homeScreenImage
    insertedAt
    updatedAt
  }
`

export const versions: Query<Versions, VersionsVariables> = gql`
  query Versions($page: Int) {
    versions(page: $page) {
      nodes {
        ...VersionFields
      }
      pageInfo {
        hasNextPage
        totalCount
      }
      totalCount
    }
  }
  ${versionFields}
`
