import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'flex-start', hint: 'Align items to the start of the flex direction' },
  { value: 'center', hint: 'Align items to the middle of the flex direction' },
  { value: 'flex-end', hint: 'Align items to the end of the flex direction' },
  { value: 'stretch', hint: 'Equalize space around all items' },
]

const AlignItems: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default AlignItems
