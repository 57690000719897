import { Query, gql } from 'overmind-graphql'
import { VersionCreate, VersionCreateVariables } from './graphql-types/versionCreate'
import { VersionUpdate, VersionUpdateVariables } from './graphql-types/versionUpdate'
import { VersionDelete, VersionDeleteVariables } from './graphql-types/versionDelete'
import { versionFields } from './queries'

export const versionCreate: Query<VersionCreate, VersionCreateVariables> = gql`
  mutation VersionCreate(
    $projectId: ID!
    $fromVersionId: ID
    $title: String!
    $description: String
    $isDraft: Boolean
    $isLive: Boolean
  ) {
    versionCreate(
      projectId: $projectId
      fromVersionId: $fromVersionId
      title: $title
      description: $description
      isDraft: $isDraft
      isLive: $isLive
    ) {
      ...VersionFields
    }
  }
  ${versionFields}
`

export const versionUpdate: Query<VersionUpdate, VersionUpdateVariables> = gql`
  mutation VersionUpdate(
    $id: ID!
    $title: String
    $description: String
    $metaTitle: String
    $metaDescription: String
    $sharingTitle: String
    $sharingDescription: String
    $sharingImage: String
    $twitterHandle: String
    $favicon: String
    $homeScreenImage: String
    $isDraft: Boolean
    $isLive: Boolean
  ) {
    versionUpdate(
      id: $id
      title: $title
      description: $description
      metaTitle: $metaTitle
      metaDescription: $metaDescription
      sharingTitle: $sharingTitle
      sharingDescription: $sharingDescription
      sharingImage: $sharingImage
      twitterHandle: $twitterHandle
      favicon: $favicon
      homeScreenImage: $homeScreenImage
      isDraft: $isDraft
      isLive: $isLive
    ) {
      ...VersionFields
    }
  }
  ${versionFields}
`

export const versionDelete: Query<VersionDelete, VersionDeleteVariables> = gql`
  mutation VersionDelete($id: ID!) {
    versionDelete(id: $id) {
      id
    }
  }
`
