import * as React from 'react'
import { string } from 'yup'
import { Form, FormTextInput } from '@superhi/design'
import { useActions } from '../overmind'

type FormData = {
  domain: string
}

const Settings: React.FunctionComponent = () => {
  const {
    domains: { create },
  } = useActions()

  return (
    <Form<FormData>
      name="create-domain"
      initialValues={{ domain: '' }}
      validationSchema={{ domain: string().required() }}
      submitButtonText="Add domain"
      onSubmit={async ({ domain }, { resetForm, setFieldError }) => {
        const error = await create({ url: domain })
        if (error) {
          if (error.message.includes('has already been taken')) {
            setFieldError('domain', `${domain} has already been taken`)
          } else if (error.message.includes('has invalid format')) {
            setFieldError('domain', `${domain} is an invalid format for a domain name`)
          } else {
            setFieldError('domain', error.message)
          }
        } else {
          resetForm()
        }
      }}
    >
      <Form.Row>
        <FormTextInput
          name="domain"
          ariaLabel="New domain name"
          label="New domain name"
          placeholder="e.g. www.superhi.com"
          required
        />
      </Form.Row>
    </Form>
  )
}

export default Settings
