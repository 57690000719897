import { isEqual } from 'lodash-es'
import { Marker, Markers } from './state'
import { Action } from '../../../overmind'

// TODO: use `files` namepsace `isLocked`
const isLocked = (filename: string) => filename.startsWith('_') || filename.includes('.min')

export const reset: Action = ({ state }) => {
  state.markers.entries = {}
}

export const replace: Action<Marker[]> = ({ state }, markers) => {
  const newEntries = markers.reduce((markers: Markers, marker) => {
    const isntLocked = !isLocked(marker.filename)
    if (isntLocked && markers[marker.filename]) {
      markers[marker.filename].push(marker)
    } else if (isntLocked) {
      markers[marker.filename] = [marker]
    }
    return markers
  }, {})
  // compare old entries vs new, only update them if they've changed, this way we can
  // prevent unecessary re-rendering
  if (!isEqual(newEntries, state.markers.entries)) {
    state.markers.entries = newEntries
  }
}
