import * as monaco from 'monaco-editor'
import { light as theme } from '../../../components/theme'

const codeTheme: monaco.editor.IStandaloneThemeData = {
  base: 'vs',
  inherit: false,
  rules: [
    {
      token: '',
      foreground: theme.colors.FOREGROUND_90,
      background: theme.colors.BACKGROUND,
    },
    { token: 'invalid', foreground: theme.colors.ERROR },
    { token: 'emphasis', fontStyle: 'italic' },
    { token: 'strong', fontStyle: 'bold' },

    { token: 'variable', foreground: theme.colors.PRIMARY },
    { token: 'variable.predefined', foreground: theme.colors.PRIMARY },
    { token: 'comment', foreground: theme.colors.FOREGROUND_70 },
    { token: 'number', foreground: theme.colors.PRIMARY_SHADE },
    { token: 'number.hex', foreground: theme.colors.PRIMARY_SHADE },
    { token: 'regexp', foreground: theme.colors.ERROR },
    { token: 'annotation', foreground: theme.colors.FOREGROUND_70 },
    { token: 'type', foreground: theme.colors.FOREGROUND_70 },

    { token: 'delimiter', foreground: theme.colors.FOREGROUND_90 },
    { token: 'delimiter.html', foreground: theme.colors.ERROR },

    { token: 'tag', foreground: theme.colors.ERROR },
    { token: 'metatag', foreground: theme.colors.ERROR },
    { token: 'metatag.content.html', foreground: theme.colors.PRIMARY },
    { token: 'metatag.html', foreground: theme.colors.FOREGROUND_70 },

    { token: 'key', foreground: theme.colors.PRIMARY },
    { token: 'string.key.json', foreground: theme.colors.PRIMARY },
    { token: 'string.value.json', foreground: theme.colors.PRIMARY_SHADE },

    { token: 'attribute.name', foreground: theme.colors.PRIMARY },
    { token: 'attribute.value', foreground: theme.colors.PRIMARY_SHADE },
    { token: 'attribute.value.number', foreground: theme.colors.PRIMARY_SHADE },
    { token: 'attribute.value.unit', foreground: theme.colors.PRIMARY_SHADE },
    { token: 'attribute.value.html', foreground: theme.colors.PRIMARY_SHADE },

    { token: 'string', foreground: theme.colors.PRIMARY_SHADE },
    { token: 'string.html', foreground: theme.colors.PRIMARY_SHADE },

    { token: 'keyword', foreground: theme.colors.PRIMARY },
    { token: 'keyword.json', foreground: theme.colors.PRIMARY_SHADE },
  ],
  colors: {
    'editor.background': theme.colors.BACKGROUND,
    'editor.foreground': theme.colors.FOREGROUND_90,
    'editorIndentGuide.background': theme.colors.FOREGROUND_20,
    'editorIndentGuide.activeBackground': theme.colors.WARNING,
    'editorLineNumber.foreground': theme.colors.FOREGROUND_50,
    'editorLineNumber.activeForeground': theme.colors.FOREGROUND_70,
    'editorCursor.foreground': theme.colors.PRIMARY,
    'editor.lineHighlightBackground': theme.colors.FOREGROUND_10,
    'editor.lineHighlightBorder': theme.colors.FOREGROUND_10,
    'editorOverviewRuler.background': theme.colors.BACKGROUND,
    'editorOverviewRuler.border': theme.colors.BACKGROUND,
    'editorError.foreground': theme.colors.ERROR,
    'editorWarning.foreground': theme.colors.WARNING,
  },
}

export default codeTheme
