import * as React from 'react'
import Fuse from 'fuse.js'
import styled from 'styled-components/macro'
import List, { Value } from './List'
import { ButtonComponent, Wrapper, Label } from '../Button'
import { useEffects } from '../../../overmind'

const initialFonts: Value[] = [
  'Abril Fatface',
  'Actor',
  'Amaranth',
  'Arimo',
  'Arvo',
  'Biryani',
  'Bree Serif',
  'David Libre',
  'Droid Sans',
  'Droid Serif',
  'EB Garamond',
  'Fjord One',
  'Forum',
  'Gentium Basic',
  'Italiana',
  'Josefin Sans',
  'Kreon',
  'Lato',
  'Ledger',
  'Libre Baskerville',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Noto Sans',
  'Noto Serif',
  'Old Standard TT',
  'Open Sans',
  'Oswald',
  'Playfair Display',
  'Poly',
  'PT Sans',
  'PT Serif',
  'Raleway',
  'Roboto',
  'Signika',
  'Source Sans Pro',
  'Ubuntu',
  'Vollkorn',
  'Work Sans',
  'Yellowtail',
].map((fontName) => ({ value: fontName }))

const Input = styled.input`
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.BACKGROUND};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.BACKGROUND};
  font-family: 'PlexMono';
  font-weight: 400;
  font-size: 15px;
  padding: 6px 4px;
  width: 100%;
`

const FontFamily: ButtonComponent = ({ button, forceUpdate }) => {
  const { fonts } = useEffects()
  const [fontValues, setFontValues] = React.useState<Value[]>(initialFonts)
  const [googleFonts, setGoogleFonts] = React.useState<Value[]>([])
  const fuse = new Fuse<Value>(googleFonts, { keys: ['value'], shouldSort: true, threshold: 0.6 })

  React.useEffect(() => {
    const fetchFonts = async () => {
      const googleFonts = await fonts.get()
      setGoogleFonts(Object.values(googleFonts).map((font) => ({ value: font.family })))
      if (forceUpdate) {
        forceUpdate()
      }
    }
    fetchFonts()
  }, [fonts, forceUpdate, setGoogleFonts])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const searchResults = fuse.search(e.target.value).slice(0, 40)
      setFontValues(searchResults.map(({ item }) => item))
    } else {
      setFontValues(initialFonts)
    }
  }

  return (
    <>
      <Wrapper>
        <Label>Search Google Fonts</Label>
        <Input onChange={handleChange} />
      </Wrapper>
      {fontValues.length ? (
        <List values={fontValues} button={button} />
      ) : (
        <Wrapper>
          <Label>No fonts found</Label>
        </Wrapper>
      )}
    </>
  )
}

export default FontFamily
