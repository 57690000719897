import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'left', hint: 'Stop tag wrapping on the left' },
  { value: 'right', hint: 'Stop tag wrapping on the right' },
  { value: 'both', hint: 'Stop tag wrapping on the left and right' },
  { value: 'none', hint: 'Keep the tag wrapping' },
]

const Clear: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default Clear
