import * as React from 'react'
import { string } from 'yup'
import styled from 'styled-components/macro'
import { Form, FormTextInput } from '@superhi/design'
import { useActions } from '../overmind'

type FormData = {
  username: string
  password: string
}

const FormWrapper = styled.div`
  text-align: left;
  width: 100%;
`

const LoginForm = () => {
  const { signIn } = useActions()

  return (
    <FormWrapper>
      <Form<FormData>
        name="login"
        initialValues={{ username: '', password: '' }}
        validationSchema={{ username: string().required(), password: string().required() }}
        submitButtonAlign="center"
        submitButtonText="Log in"
        onSubmit={async ({ username, password }, { setFieldError, resetForm }) => {
          const error = await signIn({ username, password })
          if (error) {
            const message = error.message.split(':')[0]
            if (message.includes('Login not found')) {
              setFieldError('username', message)
            } else {
              setFieldError('password', message)
            }
          }
        }}
      >
        <Form.Row>
          <FormTextInput
            name="username"
            ariaLabel="Username or email address"
            label="Username or email address"
            placeholder="e.g. elainebenes"
            required
          />
        </Form.Row>
        <Form.Row>
          <FormTextInput
            type="password"
            name="password"
            ariaLabel="Password"
            label="Password"
            placeholder="··········"
            required
          />
        </Form.Row>
      </Form>
    </FormWrapper>
  )
}

export default LoginForm
