import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'left', hint: 'Make other tags wrap around tag on the right' },
  { value: 'right', hint: 'Make other tags wrap around tag on the left' },
  { value: 'none', hint: 'Stop HTML tag from floating' },
]

const Float: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default Float
