import * as React from 'react'
import styled from 'styled-components/macro'
import { Colour } from '../Poppers'
import { toCanvas } from 'qrcode'
import Popper from './Popper'
import { MarkerArrow } from './Markers'

const PopperWrapper = styled.div<{ colour: Colour }>`
  background-color: ${({ theme }) => theme.colors.FOREGROUND_90};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.BACKGROUND};
  max-width: 400px;
  padding: 16px 16px 12px;
  z-index: 999;
`

const QRPopper: React.FunctionComponent<{ reference: HTMLElement; to: string }> = ({
  reference,
  to,
}) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null)

  React.useEffect(() => {
    if (canvasRef.current) {
      toCanvas(canvasRef.current, to, { width: 148 })
    }
  }, [to])
  return (
    <Popper
      PopperWrapper={PopperWrapper}
      PopperArrow={MarkerArrow}
      reference={reference}
      placement="bottom"
      colour="FOREGROUND_90"
      offset={10}
    >
      {() => <canvas ref={canvasRef} />}
    </Popper>
  )
}

export default QRPopper
