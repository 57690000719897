import * as React from 'react'
import styled from 'styled-components/macro'
import MarkersPopper from '../Poppers/Markers'
import { Severity } from '../../overmind/namespaces/markers/state'
import { useState } from '../../overmind'
import { ReactComponent as Error } from './error.svg'
import { ReactComponent as Warning } from './warning.svg'
import { Colour } from '../Poppers'

const Span = styled.span<{ colour: Colour }>`
  color: ${({ colour, theme }) => theme.colors[colour]};
  font-weight: 700;
`

const Hexagon = styled(Error)`
  height: 15px;
  margin: 0 3px -2px 6px;
  width: 15px;
`

const Triangle = styled(Warning)`
  height: 14px;
  margin: 0 3px -2px 6px;
  width: 16px;
`

const Padding = styled.span`
  padding: 20px 0;
`

const Marker: React.FunctionComponent<{ severity: Severity; total: number }> = ({
  severity,
  total,
}) => (
  <>
    {severity === 'ERROR' ? <Hexagon /> : <Triangle />}
    <Span colour={severity === 'ERROR' ? 'ERROR' : 'WARNING'}>{total}</Span>
  </>
)

const Markers: React.FunctionComponent = () => {
  const {
    markers: { errors, warnings },
  } = useState()
  const [isHovering, setIsHovering] = React.useState(false)
  const wrapperEl = React.useRef<HTMLSpanElement>(null)

  const handleMouseEnter = () => setIsHovering(true)
  const handleMouseLeave = () => setIsHovering(false)

  return (
    <Padding ref={wrapperEl} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {errors ? <Marker total={errors} severity="ERROR" /> : null}
      {warnings ? <Marker total={warnings} severity="WARNING" /> : null}
      {isHovering && wrapperEl.current && <MarkersPopper reference={wrapperEl.current} />}
    </Padding>
  )
}

export default Markers
