import * as React from 'react'
import styled from 'styled-components/macro'
import { useActions, useEffects } from '../../overmind'
import { Mode } from '../../overmind/namespaces/preview/state'

const IFrame = styled.iframe`
  border: none;
  height: 100%;
  width: 100%;
`

const Frame: React.FunctionComponent<{ mode: Mode }> = ({ mode }) => {
  const {
    projects: { buildEditorUrl },
    preview: { updateIsLoading },
  } = useActions()
  const { preview } = useEffects()
  const iFrameRef = React.useRef<HTMLIFrameElement>(null)

  // set isLoading on load
  React.useEffect(() => {
    updateIsLoading({ isLoading: true, mode })
  }, [mode, updateIsLoading])

  // add the iframe to the preview effect on load, remove it on onload
  React.useEffect(() => {
    if (iFrameRef.current) {
      preview.addIFrame(iFrameRef.current, mode)
      return () => {
        preview.removeIFrame(mode)
      }
    }
  }, [preview, mode])

  return (
    <IFrame
      ref={iFrameRef}
      src={`${buildEditorUrl()}/index.html`}
      onLoad={() => updateIsLoading({ isLoading: false, mode })}
    />
  )
}

export default Frame
