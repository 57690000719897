import * as React from 'react'
import styled from 'styled-components/macro'
import { useState, useActions, useEffects } from '../../overmind'
import { Mode } from '../../overmind/namespaces/preview/state'
import { ReactComponent as Arrow } from './arrow.svg'
import { ReactComponent as Loader } from './loader.svg'
import { ReactComponent as Refresh } from './refresh.svg'

const MenuBar = styled.div`
  background-color: ${({ theme }) => theme.colors.FOREGROUND_20};
  align-items: center;
  color: ${({ theme }) => theme.colors.FOREGROUND_90};
  display: flex;
  flex: 0 0 62px;
  justify-content: space-between;
  padding: 0 15px;
`

const Url = styled.div`
  background: ${({ theme }) => theme.colors.BACKGROUND};
  border-radius: 20px;
  flex: 1;
  height: 30px;
  margin: 0 3px 0 5px;
  overflow: hidden;
  padding: 5px 16px 6px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const LoadingIcon = styled(Loader)`
  animation: spin 1400ms linear infinite;
  display: block;
  height: 18px;
  position: absolute;
  right: 6px;
  top: 6px;
  width: 18px;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

const Button = styled.div<{ isEnabled: boolean }>`
  align-items: center;
  background: ${({ theme }) => theme.colors.FOREGROUND_20};
  border-radius: 100%;
  cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'auto')};
  display: flex;
  height: 31px;
  justify-content: center;
  margin: 0 3px;
  opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.3)};
  pointer-events: ${({ isEnabled }) => (isEnabled ? 'auto' : 'none')};
  transition: background-color 200ms ease-in-out;
  width: 31px;

  &:hover {
    background: ${({ isEnabled }) =>
      isEnabled
        ? ({ theme }) => theme.colors.BACKGROUND
        : ({ theme }) => theme.colors.FOREGROUND_20};
  }
`

const RightArrow = styled(Arrow)`
  width: 16px;
`

const LeftArrow = styled(RightArrow)`
  transform: rotate(180deg);
`

const Reload = styled(Refresh)`
  margin-top: 1px;
  width: 18px;
`

const Menu: React.FunctionComponent<{ mode: Mode }> = ({ mode }) => {
  const { preview } = useState()
  const {
    projects: { buildEditorUrl },
  } = useActions()
  const {
    preview: { reload, goBack, goForward },
  } = useEffects()
  const frame = preview[mode]
  const url = frame.currentLocation.split(`${buildEditorUrl()}/`).join('')

  return (
    <MenuBar>
      <Url>
        {url}
        {frame.isLoading && <LoadingIcon />}
      </Url>
      <Button onClick={() => goBack(mode)} isEnabled={frame.canGoBack}>
        <LeftArrow />
      </Button>
      <Button onClick={() => goForward(mode)} isEnabled={frame.canGoForward}>
        <RightArrow />
      </Button>
      <Button onClick={() => reload(mode)} isEnabled={true}>
        <Reload />
      </Button>
    </MenuBar>
  )
}

export default Menu
