import * as React from 'react'
// @ts-ignore
import Split from 'react-split'
import styled, { css } from 'styled-components/macro'
import { useState } from '../overmind'
import Sidebar from '../components/Sidebar'
import Top from '../components/Top'
import Panel from '../components/Panel'
import InlinePreview from '../components/Preview/Inline'
import Monaco from '../components/Editor/Monaco'
import { useActions, useEffects } from '../overmind'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.BACKGROUND};
  bottom: 0;
  height: 100vh;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  width: calc(100vw - 60px);
  z-index: 1;
`

const SplitDefaults = css`
  bottom: 0;
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  right: 0;
  width: calc(100vw - 310px);
`

const NoSplit = styled.div`
  ${SplitDefaults}
`

const StyledHorizontalSplit = styled(Split)`
  ${SplitDefaults}

  .gutter {
    background-color: ${({ theme }) => theme.colors.FOREGROUND_20};
    display: block;
    overflow: visible;
    position: relative;

    &:before {
      content: '';
      cursor: col-resize;
      height: 100%;
      left: -3px;
      opacity: 0;
      position: absolute;
      top: 0px;
      width: 8px;
      z-index: 2;
    }
  }

  & > div {
    height: 100%;
    position: relative;
    width: auto;
  }
`

const StyledVerticalSplit = styled(StyledHorizontalSplit)`
  flex-direction: column;

  .gutter {
    &:before {
      cursor: row-resize;
      height: 8px;
      left: 0px;
      top: -3px;
      width: 100%;
    }
  }

  & > div {
    height: auto;
    width: 100%;
  }
`

const CodeWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
`

const Panels: React.FunctionComponent = () => (
  <CodeWrapper>
    <Top />
    <Panel />
    <Monaco />
  </CodeWrapper>
)

const SplitComponent: React.FC<{ isHorizontal: boolean }> = ({ isHorizontal, children }) => {
  const {
    browser: { storage },
  } = useEffects()
  const [dimensions, setDimensions] = React.useState<[number, number]>([60, 40])
  const StyledSplit = isHorizontal ? StyledHorizontalSplit : StyledVerticalSplit

  React.useEffect(() => {
    const savedDimensions = storage.get('inlinePreviewDimensions')
    if (savedDimensions) {
      setDimensions(savedDimensions)
    }
  }, [storage])

  return (
    <StyledSplit
      sizes={dimensions}
      minSize={260}
      expandToMin={true}
      gutterSize={2}
      gutterAlign="center"
      snapOffset={0}
      dragInterval={1}
      direction={isHorizontal ? 'horizontal' : 'vertical'}
      onDragEnd={(sizes: [number, number]) => {
        storage.set('inlinePreviewDimensions', sizes)
      }}
    >
      {children}
    </StyledSplit>
  )
}

const SideBySide: React.FunctionComponent = () => {
  const {
    editorSettings: { previewEnabled, inlinePreviewMode },
  } = useState()

  return inlinePreviewMode !== 'OFF' && previewEnabled ? (
    <SplitComponent isHorizontal={inlinePreviewMode === 'RIGHT'}>
      <div>
        <Panels />
      </div>
      <div>
        <InlinePreview />
      </div>
    </SplitComponent>
  ) : (
    <NoSplit>
      <Panels />
    </NoSplit>
  )
}

const Editor: React.FunctionComponent = () => {
  const { setTitleWithProject } = useActions()

  React.useEffect(() => {
    setTitleWithProject('Edit')
  }, [setTitleWithProject])

  return (
    <Wrapper>
      <Sidebar />
      <SideBySide />
    </Wrapper>
  )
}

export default Editor
