import * as React from 'react'
import styled from 'styled-components/macro'
import { useActions } from '../../overmind'
import { FolderFile } from '../../overmind/namespaces/files/state'

const Wrapper = styled.div`
  align-items: center;
  min-height: 100%;
  overflow-y: scroll;
  padding: 30px;
  width: 100%;
`

const Preview = styled.div`
  margin-bottom: 30px;
`

const H3 = styled.h3`
  color: ${({ theme }) => theme.colors.PRIMARY};
  font-family: 'Px';
  font-weight: 700;
  margin-bottom: 5px;
  text-transform: uppercase;
`

const P = styled.p`
  margin: 0;
  word-break: break-all;
`

const Li = styled.li`
  list-style-type: none;
`

const Font: React.FunctionComponent<{ file: FolderFile }> = ({ file }) => {
  const {
    files: { buildUrl },
  } = useActions()
  const sizes = [72, 48, 36, 24, 18, 16, 14, 12]

  return (
    <Wrapper>
      <style
        dangerouslySetInnerHTML={{
          __html: `@font-face {
        font-family: "SuperHi-Preview-Font";
        src: url(${buildUrl(file)});
      }`,
        }}
      />
      <ul>
        {sizes.map((size) => (
          <Li key={size}>
            <H3>
              {size}
              px
            </H3>
            <Preview style={{ fontFamily: 'SuperHi-Preview-Font', fontSize: `${size}px` }}>
              <P>ABCDEFGHIJKLMNOPQRSTUVWXYZ</P>
              <P>abcdefghijklmnopqrstuvwxyz</P>
              <P>0123456789</P>
            </Preview>
          </Li>
        ))}
      </ul>
    </Wrapper>
  )
}

export default Font
