import * as React from 'react'
import styled from 'styled-components'
import { formatDistance } from 'date-fns'
import { Heading, Link, Body, TextVerticalSpacer } from '@superhi/design'
import { useActions } from '../overmind'
import { Project } from '../overmind/namespaces/projects/state'

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.FOREGROUND_90};
`

const StyledSpacer = styled(TextVerticalSpacer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.FOREGROUND_50};
  padding-bottom: 30px;
`

const ProjectListing: React.FC<{ project: Project }> = ({ project }) => {
  const {
    projects: { buildUrl },
  } = useActions()

  return (
    <StyledLink href={`/projects/${project.id}`}>
      <StyledSpacer level="5">
        <Heading level="5">{project.name}</Heading>
        <Body level="4">
          <span>{buildUrl(project.id)}</span>{' '}
          <span>Updated {formatDistance(new Date(`${project.updatedAt}Z`), new Date())} ago</span>
        </Body>
      </StyledSpacer>
    </StyledLink>
  )
}

export default ProjectListing
