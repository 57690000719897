import { Query, gql } from 'overmind-graphql'
import { ProjectCreated, ProjectCreatedVariables } from './graphql-types/projectCreated'
import { ProjectUpdated, ProjectUpdatedVariables } from './graphql-types/projectUpdated'
import { ProjectDeleted, ProjectDeletedVariables } from './graphql-types/projectDeleted'
import { projectFields } from './queries'

export const projectCreated: Query<ProjectCreated, ProjectCreatedVariables> = gql`
  subscription ProjectCreated($userId: ID!) {
    projectCreated(userId: $userId) {
      ...ProjectFields
    }
  }
  ${projectFields}
`

export const projectUpdated: Query<ProjectUpdated, ProjectUpdatedVariables> = gql`
  subscription ProjectUpdated($userId: ID!) {
    projectUpdated(userId: $userId) {
      ...ProjectFields
    }
  }
  ${projectFields}
`

export const projectDeleted: Query<ProjectDeleted, ProjectDeletedVariables> = gql`
  subscription ProjectDeleted($userId: ID!) {
    projectDeleted(userId: $userId) {
      id
    }
  }
`
