import * as React from 'react'
import styled from 'styled-components/macro'
import { createGlobalStyle } from 'styled-components/macro'
import { useEffects } from '../../overmind'
import HappyFace from './margin-happy.svg'
import SadFace from './margin-sad.svg'

const EditorContainer = styled.div`
  height: calc(100% - 62px);
  width: 100%;
`

const EditorGlobalStyle = createGlobalStyle`
  .monaco-face {
    margin-left: 6px;
    
    &:after {
      background-image: url(${HappyFace});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 80%;
      border-radius: 100%;
      content: "";
      height: 18px;
      width: 18px;
    }

    &.monaco-face--happy:after {
      background-color: ${({ theme }) => theme.colors.FOREGROUND_50};
    }

    &.monaco-face--happy:hover:after {
      background-color: ${({ theme }) => theme.colors.PRIMARY};
    }

    &.monaco-face--meh:after {
      background-color: ${({ theme }) => theme.colors.WARNING};
    }

    &.monaco-face--sad:after {
      background-image: url(${SadFace});
      background-color: ${({ theme }) => theme.colors.ERROR};
    }
  }

  .monaco-button {
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    --color: ${({ theme }) => theme.colors.FOREGROUND_20};

    /* this pseudo sets the dimensions */
    &::after {
      content: " "; /* using &nbsp; */
      display: inline-block;
      pointer-events: none;
      height: 1.3em;
      width: 1.1em;
    }

    /* this pseudo shows the button itself */
    &::before {
      background-color: var(--color);
      border: 2px solid ${({ theme }) => theme.colors.FOREGROUND_50};
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
      cursor: pointer;
      display: inline-block;
      left: 50%;
      height: 0.8em;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 45%;
      width: 0.8em;
    }
    
    &:hover {
      --color: ${({ theme }) => theme.colors.PRIMARY_SHADE};
      &::before {
        border-color: ${({ theme }) => theme.colors.PRIMARY};
      }
    }
  }
`

const Monaco: React.FunctionComponent = () => {
  const { monaco } = useEffects()
  const editorRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (editorRef.current) {
      monaco.mount(editorRef.current)
      return () => {
        monaco.unMount()
      }
    }
  }, [monaco])

  return (
    <>
      <EditorGlobalStyle />
      <EditorContainer ref={editorRef} />
    </>
  )
}

export default Monaco
