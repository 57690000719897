import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import FullscreenPreview from '../components/Preview/Fullscreen'
import { useState } from '../overmind'

const Wrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: absolute;
  right: 0;
  width: calc(100vw - 60px);
  z-index: 1;
`

const P = styled.p`
  object-fit: contain;
  font-size: 140%;
  max-height: 100%;
  max-width: 500px;
  text-align: center;
`

const Preview: React.FunctionComponent = () => {
  const {
    editorSettings: { previewEnabled },
    projects: { activeId },
  } = useState()

  return (
    <Wrapper>
      {previewEnabled ? (
        <FullscreenPreview />
      ) : (
        <P>
          Previews have been disabled, you can turn them back on in the{' '}
          <Link to={`/projects/${activeId}/settings`}>Settings page</Link>.
        </P>
      )}
    </Wrapper>
  )
}

export default Preview
