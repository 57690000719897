import * as React from 'react'
import { ButtonComponent, Wrapper, Label } from '../Button'
import List from './List'
import { FolderSlug } from '../../../overmind/namespaces/files/state'
import { useActions } from '../../../overmind'

const Src: ButtonComponent = ({ button }) => {
  const {
    files: { getByFolder },
  } = useActions()
  const folders: { [key: string]: FolderSlug } = {
    script: 'js',
    img: 'image',
    video: 'video',
    audio: 'audio',
  }
  const folder = button.tag && folders[button.tag]
  const files = folder ? getByFolder(folder).map((file) => ({ value: file.filename })) : []

  return files.length ? (
    <List values={files} button={button} />
  ) : (
    <Wrapper>
      <Label>No files found</Label>
    </Wrapper>
  )
}

export default Src
