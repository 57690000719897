import * as React from 'react'
import { ButtonComponent, Wrapper, Label } from '../Button'
import List from './List'
import { useActions } from '../../../overmind'

const Href: ButtonComponent = ({ button }) => {
  const {
    files: { getByFolder },
  } = useActions()

  const files = getByFolder('html').map((file) => ({ value: file.filename }))

  return files.length ? (
    <List values={files} button={button} />
  ) : (
    <Wrapper>
      <Label>No files found</Label>
    </Wrapper>
  )
}

export default Href
