import { IAction, IConfig, IOnInitialize, IOperator } from 'overmind'
import { merge, namespaced } from 'overmind/config'
import {
  createStateHook,
  createActionsHook,
  createEffectsHook,
  createReactionHook,
} from 'overmind-react'
import { state } from './state'
import { onInitialize } from './onInitialize'
import * as effects from './effects'
import * as actions from './actions'
import * as projects from './namespaces/projects'
import * as versions from './namespaces/versions'
import * as files from './namespaces/files'
import * as domains from './namespaces/domains'
import * as preview from './namespaces/preview'
import * as poppers from './namespaces/poppers'
import * as markers from './namespaces/markers'

export const config = merge(
  {
    onInitialize,
    state,
    actions,
    effects,
  },
  namespaced({ projects, versions, files, domains, preview, poppers, markers }),
)

export interface Config
  extends IConfig<{
    state: typeof config.state
    actions: typeof config.actions
    effects: typeof config.effects
  }> {}

export interface OnInitialize extends IOnInitialize<Config> {}

export interface Action<Input = void, Output = void> extends IAction<Config, Input, Output> {}

export interface AsyncAction<Input = void, Output = void>
  extends IAction<Config, Input, Promise<Output>> {}

export interface Operator<Input = void, Output = Input> extends IOperator<Config, Input, Output> {}

export const useState = createStateHook<typeof config>()
export const useActions = createActionsHook<typeof config>()
export const useEffects = createEffectsHook<typeof config>()
export const useReaction = createReactionHook<typeof config>()
