import axios from 'axios'

export type Variant =
  | '100'
  | '100italic'
  | '200'
  | '200italic'
  | '300'
  | '300italic'
  | '400'
  | '400italic'
  | 'regular'
  | 'italic'
  | '500'
  | '500italic'
  | '600'
  | '600italic'
  | '700'
  | '700italic'
  | '800'
  | '800italic'
  | '900'
  | '900italic'

export type Font = {
  family: string
  variants: Variant[]
}

export type Fonts = {
  [family: string]: Font
}

const key = 'AIzaSyArUI0tIW_t79EPzmSyq1-RCUbrFz1UxwI'
let fonts: Fonts = {}

export default (() => {
  const initialise = async (): Promise<Fonts> => {
    const googleFonts = await axios.get<{ sort: string; key: string }, { data: { items: Font[] } }>(
      `https://www.googleapis.com/webfonts/v1/webfonts?`,
      { params: { sort: 'popularity', key } },
    )
    fonts = googleFonts.data.items.reduce(
      (fonts: Fonts, font) => ({
        ...fonts,
        [font.family]: { family: font.family, variants: font.variants },
      }),
      {},
    )
    return fonts
  }

  const get = async (): Promise<Fonts> => {
    return Object.keys(fonts).length ? fonts : initialise()
  }

  return { initialise, get }
})()
