import { derived } from 'overmind'
import { ProjectSort, ProjectTag } from '../../effects/gql/graphql-global-types'

export type Project = {
  id: string
  name: string
  slug: string
  isStarred: boolean
  tag: string | null
  isPasswordProtected: boolean
  draftVersion: {
    id: string
  }
  liveVersion: {
    id: string
  } | null
  archiveDate: any | null
  insertedAt: any
  updatedAt: any
}

export type ProjectView = 'LIST' | 'CARD'

type State = {
  currentSort: keyof typeof ProjectSort
  currentTag: keyof typeof ProjectTag | null
  hasNextPage: boolean
  currentPage: number
  entries: { [id: string]: Project }
  activeId: string | null
  active: Project | null
  ordered: Project[]
  currentView: ProjectView
}

export const state: State = {
  currentSort: ProjectSort.NEWEST,
  currentTag: null,
  hasNextPage: false,
  currentPage: 1,
  entries: {},
  activeId: null,
  active: derived((state: State) => (state.activeId ? state.entries[state.activeId] : null)),
  ordered: [],
  currentView: 'CARD',
}
