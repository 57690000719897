import * as React from 'react'
import styled from 'styled-components/macro'
import Popper, { ForceUpdate } from '../Popper'
import { Colour } from '../../Poppers'
import AlignContent from './AlignContent'
import AlignItems from './AlignItems'
import BackgroundAttachment from './BackgroundAttachment'
import BackgroundImage from './BackgroundImage'
import BackgroundRepeat from './BackgroundRepeat'
import BorderStyle from './BorderStyle'
import Classname from './Classname'
import Clear from './Clear'
import Display from './Display'
import FlexDirection from './FlexDirection'
import FlexWrap from './FlexWrap'
import Float from './Float'
import FontFamily from './FontFamily'
import FontWeight from './FontWeight'
import Href from './Href'
import JustifyContent from './JustifyContent'
import Position from './Position'
import Src from './Src'
import TextAlign from './TextAlign'
import TextDecoration from './TextDecoration'
import TextTransform from './TextTransform'
import { PopperButton } from '../../../overmind/namespaces/poppers/state'
import { useActions } from '../../../overmind'

export type ButtonComponent = React.FunctionComponent<{
  button: PopperButton
  forceUpdate: ForceUpdate
}>

const components: { [key: string]: ButtonComponent } = {
  'align-content': AlignContent,
  'align-items': AlignItems,
  'background-attachment': BackgroundAttachment,
  'background-image': BackgroundImage,
  'background-repeat': BackgroundRepeat,
  'border-style': BorderStyle,
  class: Classname,
  clear: Clear,
  display: Display,
  'flex-direction': FlexDirection,
  'flex-wrap': FlexWrap,
  float: Float,
  'font-family': FontFamily,
  'font-weight': FontWeight,
  href: Href,
  'justify-content': JustifyContent,
  position: Position,
  src: Src,
  'text-align': TextAlign,
  'text-decoration': TextDecoration,
  'text-transform': TextTransform,
}

export const Wrapper = styled.div`
  padding: 20px 20px;
  width: 100%;
`

export const Label = styled.div`
  font-family: 'PlexMono';
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 6px;
  width: 100%;
`

const BookmarkPopper = styled.div<{ colour: Colour }>`
  background-color: ${({ theme }) => theme.colors.FOREGROUND_90};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.BACKGROUND};
  padding: 0;
  width: 385px;
  z-index: 999;
`

const BookmarkArrow = styled.div<{ colour: Colour; 'data-popper-placement': 'top' | 'bottom' }>`
  border-color: ${(props) =>
    props['data-popper-placement'] === 'top'
      ? `${props.theme.colors[props.colour]} transparent transparent transparent`
      : `transparent transparent ${props.theme.colors[props.colour]} transparent;`};
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-width: ${(props) =>
    props['data-popper-placement'] === 'top' ? `6px 4px 0 4px` : `0px 4px 6px 4px`};
  ${(props) => (props['data-popper-placement'] === 'top' ? 'bottom' : 'top')}: -6px;
  height: 0;
  position: absolute;
  width: 0;
`

const PopperInner = styled.div`
  border-radius: 4px;
  width: 100%;
  max-height: 290px;
  overflow-y: scroll;
`

const ButtonInner: ButtonComponent = ({ button, forceUpdate }) => {
  const Component = components[button.property] || null
  return Component && <Component button={button} forceUpdate={forceUpdate} />
}

const ButtonComponentActual: React.FunctionComponent<{ button: PopperButton }> = ({ button }) => {
  const { poppers } = useActions()
  const wrapperEl = React.useRef<HTMLSpanElement>(null)

  React.useEffect(() => {
    const popperEl = document.querySelector<Element>(`.${button.className}`)
    const handleClick = (e: MouseEvent) => {
      const target = e.target as Node
      if (!popperEl?.contains(target) && !wrapperEl.current?.contains(target)) {
        poppers.updateActive(null)
        e.preventDefault()
      }
    }
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [button.className, poppers])

  return (
    <span ref={wrapperEl}>
      <Popper
        PopperWrapper={BookmarkPopper}
        PopperArrow={BookmarkArrow}
        popper={button}
        placement="top"
        colour="FOREGROUND_90"
      >
        {({ forceUpdate }: { forceUpdate: ForceUpdate }) => (
          <PopperInner>
            <ButtonInner button={button} forceUpdate={forceUpdate} />
          </PopperInner>
        )}
      </Popper>
    </span>
  )
}

export default ButtonComponentActual
