import * as React from 'react'
import styled from 'styled-components/macro'
import { Heading, Link, Button } from '@superhi/design'
import { useActions } from '../overmind'
import { Domain } from '../overmind/namespaces/domains/state'

const Wrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.FOREGROUND_50};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 500px;
  padding-bottom: 20px;
  width: 100%;
`

const DomainComponent: React.FunctionComponent<{
  domain?: Domain
  url?: string
}> = ({ domain, url }) => {
  const {
    domains: { remove },
  } = useActions()

  return (
    <Wrapper>
      <Link href={domain?.url || url || ''} isExternal={true}>
        <Heading level="5">{domain ? domain.url : url}</Heading>
      </Link>
      {domain && <Button onClick={() => remove(domain.id)}>Delete</Button>}
    </Wrapper>
  )
}

export default DomainComponent
