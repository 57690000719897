import { Query, gql } from 'overmind-graphql'
import { DocumentCreate, DocumentCreateVariables } from './graphql-types/documentCreate'
import { DocumentUpload, DocumentUploadVariables } from './graphql-types/documentUpload'
import { DocumentUpdate, DocumentUpdateVariables } from './graphql-types/documentUpdate'
import { DocumentDelete, DocumentDeleteVariables } from './graphql-types/documentDelete'
import { documentFields } from './queries'

export const documentCreate: Query<DocumentCreate, DocumentCreateVariables> = gql`
  mutation DocumentCreate($filename: String!, $versionId: ID!, $blob: String, $rank: Int) {
    documentCreate(filename: $filename, versionId: $versionId, blob: $blob, rank: $rank) {
      ...DocumentFields
    }
  }
  ${documentFields}
`

export const documentUpload: Query<DocumentUpload, DocumentUploadVariables> = gql`
  mutation DocumentUpload($versionId: ID!, $file: Upload!) {
    documentUpload(versionId: $versionId, file: $file) {
      ...DocumentFields
    }
  }
  ${documentFields}
`

export const documentUpdate: Query<DocumentUpdate, DocumentUpdateVariables> = gql`
  mutation DocumentUpdate(
    $id: ID!
    $sessionId: String
    $filename: String
    $blob: String
    $rank: Int
    $bodyClass: String
    $metaTitle: String
    $metaDescription: String
    $sharingTitle: String
    $sharingDescription: String
    $sharingImage: String
    $isPasswordProtected: Boolean
    $password: String
    $passwordConfirmation: String
  ) {
    documentUpdate(
      id: $id
      sessionId: $sessionId
      filename: $filename
      blob: $blob
      rank: $rank
      bodyClass: $bodyClass
      metaTitle: $metaTitle
      metaDescription: $metaDescription
      sharingTitle: $sharingTitle
      sharingDescription: $sharingDescription
      sharingImage: $sharingImage
      isPasswordProtected: $isPasswordProtected
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      ...DocumentFields
    }
  }
  ${documentFields}
`

export const documentDelete: Query<DocumentDelete, DocumentDeleteVariables> = gql`
  mutation DocumentDelete($id: ID!) {
    documentDelete(id: $id) {
      id
    }
  }
`
