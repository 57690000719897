import * as React from 'react'
import { StyledComponent } from 'styled-components/macro'
import { usePopper, Modifier, PopperChildrenProps } from 'react-popper'
import { Popper } from '../../overmind/namespaces/poppers/state'
import { Colour } from '../Poppers'
import { useState } from '../../overmind'

export type ForceUpdate = (() => void) | null

const PopperComponent: React.FunctionComponent<{
  PopperWrapper: StyledComponent<'div', any, { colour: Colour }>
  PopperArrow: StyledComponent<'div', any, { colour: Colour }>
  popper?: Popper
  reference?: HTMLElement
  offset?: number
  colour: Colour
  children: (props: { forceUpdate: ForceUpdate }) => JSX.Element
  placement: PopperChildrenProps['placement']
}> = ({ children, popper, reference, offset, PopperWrapper, PopperArrow, colour, placement }) => {
  const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null)
  const [arrowElement, setArrowElement] = React.useState<HTMLElement | null>(null)
  useState()

  const applyArrowHide: Modifier<'applyArrowHide'> = React.useMemo(
    () => ({
      name: 'applyArrowHide',
      enabled: true,
      phase: 'write',
      fn({ state }) {
        const { arrow } = state.elements

        if (arrow && state.modifiersData.arrow) {
          if (state.modifiersData.arrow.centerOffset !== 0) {
            arrow.setAttribute('data-hide', '')
          } else {
            arrow.removeAttribute('data-hide')
          }
        }
      },
    }),
    [],
  )

  React.useEffect(() => {
    if (popper?.className) {
      setReferenceElement(document.querySelector<HTMLElement>(`.${popper.className}`))
    } else if (reference) {
      setReferenceElement(reference)
    }
  }, [popper, reference])

  const { styles, attributes, forceUpdate } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: placement === 'left' ? ['left', 'right'] : ['top', 'bottom'],
          padding: 20,
          rootBoundary: 'viewport',
        },
      },
      { name: 'offset', options: { offset: [0, offset || 20] } },
      { name: 'preventOverflow', options: { padding: 20, rootBoundary: 'viewport' } },
      { name: 'arrow', options: { element: arrowElement, padding: 12 } },
      applyArrowHide,
    ],
  })

  return (
    <PopperWrapper
      ref={setPopperElement}
      style={styles.popper}
      colour={colour}
      {...attributes.popper}
    >
      {children({ forceUpdate })}
      <PopperArrow
        ref={setArrowElement}
        style={styles.arrow}
        colour={colour}
        {...attributes.popper}
      ></PopperArrow>
    </PopperWrapper>
  )
}

export default PopperComponent
