import * as React from 'react'
import { string } from 'yup'
import { FormTextInput, Form, FormLongTextInput } from '@superhi/design'
import { useActions } from '../overmind'

type FormData = {
  title: string
  description?: string
}

const CreateVersion: React.FunctionComponent = () => {
  const {
    versions: { create },
  } = useActions()

  return (
    <Form<FormData>
      name="create-version"
      initialValues={{ title: '', description: '' }}
      validationSchema={{ title: string().required(), description: string() }}
      submitButtonText="Create Version"
      onSubmit={async (values, helpers) => {
        await create(values)
        helpers.resetForm()
      }}
    >
      <Form.Row>
        <FormTextInput
          name="title"
          ariaLabel="Version Title"
          label="Version Title"
          placeholder="e.g. Updated about page"
          required
        />
      </Form.Row>
      <Form.Row>
        <FormLongTextInput
          name="description"
          ariaLabel="Version Description"
          label="Version Description"
          placeholder="e.g. This version updated the about page to include a map"
          height={6}
        />
      </Form.Row>
    </Form>
  )
}

export default CreateVersion
