import { derived } from 'overmind'

export type FolderSlug =
  | 'html'
  | 'css'
  | 'js'
  | 'image'
  | 'font'
  | 'video'
  | 'audio'
  | 'data'
  | 'document'
  | 'other'

type BaseFile = {
  id: string
  filename: string
  rank: number
  isActive?: boolean
  isRenaming?: boolean
  bodyClass: string | null
  metaDescription: string | null
  metaTitle: string | null
  sharingDescription: string | null
  sharingImage: string | null
  sharingTitle: string | null
  isPasswordProtected: boolean
  insertedAt: any
  updatedAt: any
}

export type FolderFile = BaseFile & {
  folder: FolderSlug
}

export type File = BaseFile & {
  version: {
    id: string
  }
  blob: string | null
}

type State = {
  haveLoaded: boolean
  entries: { [id: string]: File }
  draftFiles: File[]
  folderedFiles: FolderFile[]
  activeId: string | null
  active: FolderFile | undefined | null
}

const folders: { [folder in FolderSlug]: string[] } = {
  html: ['.html'],
  css: ['.css'],
  js: ['.js', '.mjs', '.glsl'],
  image: ['.png', '.jpg', '.jpeg', '.gif', '.svg', '.ico'],
  font: ['.woff', '.woff2', '.ttf', '.otf', '.eot'],
  video: ['.mov', '.mp4'],
  audio: ['.mp3', '.m4a'],
  data: ['.json', '.xml', '.csv', '.obj', '.mtl', '.txt'],
  document: ['.doc', '.docx', '.pdf', '.xls', '.xlsx'],
  other: [],
}

// TODO: should these be internal actions instead?
const getFolderForFile = (file: BaseFile): FolderSlug => {
  const folder = Object.entries(folders).reduce(
    (currentFolder, [folder, extensions]) =>
      extensions.some((ext) => file.filename.endsWith(ext)) ? folder : currentFolder,
    'other',
  )
  return folder as FolderSlug
}

export const isLockedFile = (file: FolderFile | File): boolean =>
  file.filename.startsWith('_') || file.filename.includes('.min')

export const state: State = {
  haveLoaded: false,
  entries: {},
  draftFiles: derived((state: State) => Object.values(state.entries)),
  // we need to spread each property here to make sure overmind doesn't think
  // the foldered files are changing when we're only updating the `blob`
  folderedFiles: derived((state: State) =>
    state.draftFiles
      .map(
        ({
          id,
          filename,
          rank,
          insertedAt,
          isActive,
          isRenaming,
          updatedAt,
          bodyClass,
          metaDescription,
          metaTitle,
          sharingDescription,
          sharingImage,
          sharingTitle,
          isPasswordProtected,
        }) => ({
          id,
          filename,
          rank,
          isActive,
          isRenaming,
          insertedAt,
          updatedAt,
          bodyClass,
          metaDescription,
          metaTitle,
          sharingDescription,
          sharingImage,
          sharingTitle,
          isPasswordProtected,
        }),
      )
      .map((file): FolderFile => ({ ...file, folder: getFolderForFile(file) })),
  ),
  activeId: derived((state: State) => {
    const activeFile = Object.values(state.entries).find((file) => file.isActive)
    return activeFile ? activeFile.id : null
  }),
  active: derived((state: State) =>
    state.activeId ? state.folderedFiles.find((file) => file.id === state.activeId) : null,
  ),
}
