import { Query, gql } from 'overmind-graphql'
import { DomainFields } from './graphql-types/domainFields'
import { Domains, DomainsVariables } from './graphql-types/domains'

export const domainFields: Query<DomainFields> = gql`
  fragment DomainFields on Domain {
    id
    url
    insertedAt
    updatedAt
  }
`

export const domains: Query<Domains, DomainsVariables> = gql`
  query Domains($page: Int) {
    domains(page: $page) {
      nodes {
        ...DomainFields
      }
      pageInfo {
        hasNextPage
        totalCount
      }
      totalCount
    }
  }
  ${domainFields}
`
