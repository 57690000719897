import * as React from 'react'
import styled from 'styled-components/macro'
import { Decoration, Lockup } from '@superhi/design'

const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`

const Loading: React.FunctionComponent = () => (
  <LoadingWrapper>
    <Decoration name="star" />
    <Lockup align="center" titleColor="BLUE_50" title="Loading..." />
  </LoadingWrapper>
)

export default Loading
