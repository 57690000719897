import * as React from 'react'
import styled from 'styled-components/macro'
import { useActions } from '../../../overmind'
import { isCodeFile } from '../../../overmind/effects/monaco/lib'
import { FolderFile } from '../../../overmind/namespaces/files/state'
import { ReactComponent as Dots } from './dots.svg'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  transition: opacity 0.25s ease 0s;
`

const Panel = styled.div`
  background: ${({ theme }) => theme.colors.PRIMARY};
  color: ${({ theme }) => theme.colors.BACKGROUND};
  cursor: default;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 2px;
  width: 200px;
  z-index: 2;
`

const PanelItem = styled.div`
  color: ${({ theme }) => theme.colors.BACKGROUND};
  cursor: pointer;
  display: block;
  line-height: 2;
  padding: 2px 10px;
  text-decoration: none;

  :hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

const Toggle = styled(
  ({
    showPanel,
    ...rest
  }: {
    showPanel: boolean
    onClick: (event: React.MouseEvent<SVGElement>) => void
  }) => <Dots {...rest} />,
)`
  height: 100%;
  margin-right: 0 !important;
  padding: 0 10px;
  z-index: 3;
  width: 35px !important;

  #dots {
    fill: ${(props) =>
      props.showPanel
        ? ({ theme }) => theme.colors.BACKGROUND
        : ({ theme }) => theme.colors.FOREGROUND_70};
  }
`

const Menu: React.FunctionComponent<{ file: FolderFile; rename: () => void }> = ({
  file,
  rename,
}) => {
  const {
    files: { remove, duplicate },
  } = useActions()
  const [showPanel, setShowPanel] = React.useState(false)
  const canDuplicateFile = isCodeFile(file)
  const canRenameOrDeleteFile = !['index.html', '_base.css'].includes(file.filename)

  const handleClick = (fn?: () => void) => (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (fn) {
      fn()
    }
    setShowPanel(!showPanel)
  }

  return (
    <Wrapper>
      {showPanel && (
        <Panel>
          {canRenameOrDeleteFile && (
            <PanelItem onClick={handleClick(() => rename())}>Rename</PanelItem>
          )}
          {canDuplicateFile && (
            <PanelItem onClick={handleClick(() => duplicate(file.id))}>Duplicate</PanelItem>
          )}
          {canRenameOrDeleteFile && (
            <PanelItem onClick={handleClick(() => remove(file.id))}>Delete</PanelItem>
          )}
        </Panel>
      )}
      <Toggle showPanel={showPanel} onClick={handleClick()} />
    </Wrapper>
  )
}

export default Menu
