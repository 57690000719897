export default (() => {
  const initialise = (heapId: string) => {
    let script = document.createElement('script')
    script.text = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load("${heapId}");`
    document.body.appendChild(script)
  }

  const identify = (id: string) => {
    window.heap?.identify(id)
  }

  const resetIdentity = () => {
    window.heap?.resetIdentity()
  }

  const addUserProperties = (data: object) => {
    window.heap?.addUserProperties(data)
  }

  const clearEventProperties = () => {
    window.heap?.clearEventProperties()
  }

  const addEventProperties = (data: object) => {
    window.heap?.addEventProperties(data)
  }

  const track = (eventName: string, data: object) => {
    window.heap?.track(eventName, data)
  }

  return {
    initialise,
    identify,
    resetIdentity,
    addUserProperties,
    clearEventProperties,
    addEventProperties,
    track,
  }
})()
