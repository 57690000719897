import * as React from 'react'
import styled from 'styled-components/macro'
import { Colour } from '../Poppers'
import Popper from './Popper'
import tagsToSentence from '../../lib/tagsToSentence'
import { PopperFace, FaceState } from '../../overmind/namespaces/poppers/state'
import { useState } from '../../overmind'

const FacePopper = styled.div<{ colour: Colour }>`
  background-color: ${({ colour, theme }) => theme.colors[colour]};
  border-radius: 4px;
  color: ${({ colour, theme }) =>
    theme.colors[colour === 'WARNING' ? 'FOREGROUND_90' : 'BACKGROUND']};
  padding: 16px 20px;
  pointer-events: none;
  width: 250px;
  z-index: 999;
`

const FaceArrow = styled.div<{ colour: Colour }>`
  border-color: transparent transparent transparent ${({ colour, theme }) => theme.colors[colour]};
  border-style: solid;
  border-width: 4px 0 4px 6px;
  height: 0;
  position: absolute;
  right: -6px;
  width: 0;

  &[data-hide] {
    visibility: hidden;
  }
`

const Text = styled.div`
  margin-bottom: 20px;
`

const Code = styled.code`
  display: block;
  font-family: 'PlexMono';
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
`

const SmallText = styled.div`
  font-size: 12px;
  margin-bottom: 3px;
`

const getColour = (state: FaceState) => {
  const colours: { [key in FaceState]: Colour } = {
    happy: 'PRIMARY',
    meh: 'WARNING',
    sad: 'ERROR',
  }
  return colours[state] || 'PRIMARY'
}

const Total: React.FunctionComponent<{ popper: PopperFace }> = ({ popper }) => {
  if (popper.state === 'sad') {
    return <SmallText>This CSS selector doesn't exist!</SmallText>
  } else if (popper.state === 'meh') {
    return (
      <SmallText>
        This CSS selector doesn&apos;t select any tags on any pages you have so far!
      </SmallText>
    )
  } else {
    const filenames = Object.keys(popper.totalFiles)
    return (
      <SmallText>
        This selects {popper.total} element{popper.total !== 1 && 's'} on{' '}
        {filenames.join(filenames.length === 2 ? ' and ' : ', ')}
      </SmallText>
    )
  }
}

const Face: React.FunctionComponent<{ popper: PopperFace }> = ({ popper }) => {
  useState()

  return (
    <Popper
      PopperWrapper={FacePopper}
      PopperArrow={FaceArrow}
      popper={popper}
      placement="left"
      colour={getColour(popper.state)}
    >
      {() => (
        <>
          <Code>{popper.selectors}</Code>
          <Text dangerouslySetInnerHTML={{ __html: tagsToSentence(popper.selectors) }} />
          <Total popper={popper} />
        </>
      )}
    </Popper>
  )
}

export default Face
