import * as React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useState } from '../../overmind'
import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as Editor } from './editor.svg'
import { ReactComponent as Preview } from './preview.svg'
import { ReactComponent as Publish } from './publish.svg'
import { ReactComponent as Settings } from './settings.svg'
import { ReactComponent as Versions } from './versions.svg'
import { ReactComponent as Domains } from './domains.svg'
import { ReactComponent as Back } from './back.svg'

const Wrapper = styled.nav`
  background: ${({ theme }) => theme.colors.FOREGROUND_90};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 60px;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
`

const Link = styled(NavLink)`
  align-items: center;
  background: ${({ theme }) => theme.colors.FOREGROUND_90};
  display: flex;
  height: 60px;
  justify-content: center;
`

const HomeLink = styled(Link)`
  background: ${({ theme }) => theme.colors.PRIMARY};
  border: none;
`

const ProjectLink = styled(Link)`
  &.active {
    background: ${({ theme }) => theme.colors.FOREGROUND_80};
    border-left: 4px solid transparent;
    border-right: 4px solid ${({ theme }) => theme.colors.WARNING};
    transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
  }
`

const Nav: React.FunctionComponent = () => {
  const {
    projects: { activeId },
  } = useState()

  return (
    <Wrapper>
      <Links>
        <HomeLink to="/projects">
          <Logo />
        </HomeLink>
        <ProjectLink exact to={`/projects/${activeId}`}>
          <Editor />
        </ProjectLink>
        <ProjectLink to={`/projects/${activeId}/view`}>
          <Preview />
        </ProjectLink>
        <ProjectLink to={`/projects/${activeId}/publish`}>
          <Publish />
        </ProjectLink>
        <ProjectLink to={`/projects/${activeId}/settings`}>
          <Settings />
        </ProjectLink>
        <ProjectLink to={`/projects/${activeId}/versions`}>
          <Versions />
        </ProjectLink>
        <ProjectLink to={`/projects/${activeId}/domains`}>
          <Domains />
        </ProjectLink>
      </Links>
      <Link to="/projects">
        <Back />
      </Link>
    </Wrapper>
  )
}

export default Nav
