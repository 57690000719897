import { Query, gql } from 'overmind-graphql'
import { DocumentFields } from './graphql-types/documentFields'

export const documentFields: Query<DocumentFields> = gql`
  fragment DocumentFields on Document {
    id
    filename
    blob
    rank
    version {
      id
    }
    rank
    bodyClass
    metaTitle
    metaDescription
    sharingTitle
    sharingDescription
    sharingImage
    isPasswordProtected
    insertedAt
    updatedAt
  }
`
