import * as React from 'react'
import { ButtonComponent, Wrapper, Label } from '../Button'
import List, { Value } from './List'
import { useEffects } from '../../../overmind'

const Classname: ButtonComponent = ({ button, forceUpdate }) => {
  const {
    monaco: { getClassNames },
  } = useEffects()
  const [classNames, setClassNames] = React.useState<Value[]>([])

  React.useEffect(() => {
    const fetchClasses = async () => {
      const classNames = await getClassNames()
      setClassNames(classNames.map((className) => ({ value: className })))
      setTimeout(() => {
        if (forceUpdate) {
          forceUpdate()
        }
      })
    }
    fetchClasses()
  }, [forceUpdate, getClassNames])

  return classNames.length ? (
    <List values={classNames} button={button} />
  ) : (
    <Wrapper>
      <Label>No classes found</Label>
    </Wrapper>
  )
}

export default Classname
