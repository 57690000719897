import * as React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { string } from 'yup'
import { Heading, Form, FormTextInput } from '@superhi/design'
import { useActions } from '../overmind'

type FormData = {
  name: string
}

const Container = styled.div`
  width: 500px;
`

const NewProject: React.FC = () => {
  const { projects } = useActions()
  const history = useHistory()

  return (
    <Container>
      <Heading level="3">New Project</Heading>
      <Form<FormData>
        name="new-project"
        initialValues={{ name: '' }}
        validationSchema={{ name: string().required() }}
        submitButtonText="Create Project"
        onSubmit={async ({ name }, { setFieldError }) => {
          const project = await projects.create(name)
          if (project) {
            history.push(`/projects/${project.id}`)
          } else {
            setFieldError('name', 'Project not created')
          }
        }}
      >
        <Form.Row>
          <FormTextInput
            name="name"
            ariaLabel="Project name"
            label="Project name"
            placeholder="e.g. My Portfolio Website"
            required
          />
        </Form.Row>
      </Form>
    </Container>
  )
}

export default NewProject
