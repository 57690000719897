/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Editor inline preview mode
 */
export enum EditorInlinePreviewMode {
  BOTTOM = "BOTTOM",
  OFF = "OFF",
  RIGHT = "RIGHT",
}

/**
 * Membership status
 */
export enum MembershipStatus {
  ACTIVE = "ACTIVE",
  DEFAULTED = "DEFAULTED",
  INACTIVE = "INACTIVE",
}

/**
 * Project sort
 */
export enum ProjectSort {
  A_Z = "A_Z",
  NEWEST = "NEWEST",
  OLDEST = "OLDEST",
  Z_A = "Z_A",
}

/**
 * Project Tag
 */
export enum ProjectTag {
  BLUE = "BLUE",
  GREEN = "GREEN",
  RED = "RED",
  YELLOW = "YELLOW",
}

/**
 * User Type
 */
export enum UserType {
  FREE = "FREE",
  MEMBER = "MEMBER",
  STUDENT = "STUDENT",
  TEAM_MEMBER = "TEAM_MEMBER",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
