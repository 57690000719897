import { Query, gql } from 'overmind-graphql'
import { VersionCreated, VersionCreatedVariables } from './graphql-types/versionCreated'
import { VersionUpdated, VersionUpdatedVariables } from './graphql-types/versionUpdated'
import { VersionDeleted, VersionDeletedVariables } from './graphql-types/versionDeleted'
import { versionFields } from './queries'

export const versionCreated: Query<VersionCreated, VersionCreatedVariables> = gql`
  subscription VersionCreated($projectId: ID!) {
    versionCreated(projectId: $projectId) {
      ...VersionFields
    }
  }
  ${versionFields}
`

export const versionUpdated: Query<VersionUpdated, VersionUpdatedVariables> = gql`
  subscription VersionUpdated($projectId: ID!) {
    versionUpdated(projectId: $projectId) {
      ...VersionFields
    }
  }
  ${versionFields}
`

export const versionDeleted: Query<VersionDeleted, VersionDeletedVariables> = gql`
  subscription VersionDeleted($projectId: ID!) {
    versionDeleted(projectId: $projectId) {
      id
    }
  }
`
