import * as React from 'react'
import { useActions } from '../overmind'

const VersionComponent: React.FunctionComponent = () => {
  const { setTitleWithProject } = useActions()

  React.useEffect(() => {
    setTitleWithProject('Preview')
  }, [setTitleWithProject])

  return null
}

export default VersionComponent
