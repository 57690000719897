import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'no-repeat', hint: 'Only one image used as background' },
  { value: 'repeat', hint: 'Image will tile to fill space' },
  { value: 'repeat-x', hint: 'Image will only tile left to right' },
  { value: 'repeat-y', hint: 'Image will only tile top to bottom' },
]

const BackgroundRepeat: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default BackgroundRepeat
