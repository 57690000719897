import { createBrowserHistory } from 'history'

export default (() => {
  const history = createBrowserHistory()

  const openWindow = (url: string) => {
    window.open(url, '_blank')
  }

  const toggleBetweenViews = () => {
    const path = history.location.pathname
    const match = path.match(/\/projects\/(\d+)/)
    if (match && match[1]) {
      const projectId = match[1]
      const isOnViewPage = path.includes('/view')
      history.push(`/projects/${projectId}/${isOnViewPage ? '' : 'view'}`)
    }
  }

  const storage = {
    get(key: string): any {
      const value = localStorage.getItem(key)
      return value ? JSON.parse(value) : undefined
    },
    set(key: string, value: any) {
      localStorage.setItem(key, JSON.stringify(value))
    },
  }

  return { history, toggleBetweenViews, openWindow, storage }
})()
