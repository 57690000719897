import React from 'react'
import {
  Body,
  Decoration,
  HorizontalSpacer,
  Icon,
  Link,
  Lockup,
  theme,
  VerticalSpacer,
} from '@superhi/design'
import { ButtonLinkConfig } from '@superhi/design/dist/cjs/components/basics/Button/types'

import { Wrapper, Content, Footer } from './styles'

const COLORS: (keyof typeof theme.colors)[] = ['GREEN_50', 'BLUE_40', 'RED_50']
const SHAPE_NAMES: Extract<
  React.ComponentProps<typeof Icon>['name'],
  'shape-circle' | 'shape-wedge' | 'shape-square' | 'shape-diamond'
>[] = ['shape-circle', 'shape-diamond', 'shape-square', 'shape-wedge']

type Props = {
  decoration?: React.ComponentProps<typeof Decoration>['name'] | React.ReactElement
  title: string
  subtitle?: string
  footerItems?: Required<Pick<ButtonLinkConfig, 'display' | 'href'>>[]
}

const VS_1 = { small: 8, medium: 8, large: 8 }

const CardPage: React.FC<Props> = ({ decoration, title, subtitle, children, footerItems = [] }) => (
  <Wrapper>
    <Content>
      <VerticalSpacer amount={VS_1}>
        {typeof decoration === 'string' ? <Decoration name={decoration} /> : decoration}
        <Lockup title={title} subtitle={subtitle} level="3" />
      </VerticalSpacer>
      {children}
    </Content>

    {footerItems.length > 0 && (
      <Footer>
        {footerItems.map((footerItem, i) => (
          <HorizontalSpacer key={i} amount={VS_1}>
            <Icon name={SHAPE_NAMES[i % SHAPE_NAMES.length]} color={COLORS[i % COLORS.length]} />

            <Body level="3" inline>
              <Link href={footerItem.href}>{footerItem.display}</Link>
            </Body>
          </HorizontalSpacer>
        ))}
      </Footer>
    )}
  </Wrapper>
)

export default CardPage
