import { extname } from 'path'
import { CursorResult, BuiltInParserName } from 'prettier'
import * as prettier from '../../workers/prettier.worker'

interface FilePartial {
  filename: string
  blob: string | null
}

export default (() => {
  const getParserFromFile = (file: FilePartial): BuiltInParserName | undefined => {
    const ext = extname(file.filename)
    const entry = Object.entries({
      '.html': 'html',
      '.js': 'babel',
      '.mjs': 'babel',
      '.css': 'css',
      '.json': 'json',
    }).find(([key]) => key === ext)
    return entry ? (entry[1] as BuiltInParserName) : undefined
  }

  const isCursorResult = (result: void | CursorResult | Error): result is CursorResult => {
    return (result as CursorResult).formatted !== undefined
  }

  const prettify = async (file: FilePartial, cursorIndex: number): Promise<CursorResult> => {
    const parser = getParserFromFile(file)
    if (file.blob && parser) {
      const result = await prettier.prettify({
        code: file.blob,
        cursorIndex,
        parser,
      })
      if (isCursorResult(result)) {
        return result
      } else {
        throw new Error(result?.toString())
      }
    } else {
      throw new Error('file or parser not found')
    }
  }

  return { prettify }
})()
