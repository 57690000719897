import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'fixed', hint: 'Fix tags to the browser window, even if scrolling' },
  { value: 'relative', hint: 'Move tag in relation with where the tag would usually be' },
  {
    value: 'absolute',
    hint: 'Move tag in relation to the page itself OR to any parent tag with positioning',
  },
]

const Position: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default Position
