import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'left', hint: 'Align text on the left' },
  { value: 'right', hint: 'Align text on the right' },
  { value: 'center', hint: 'Align text in the middle' },
  { value: 'justify', hint: 'Justify text (generally looks ugly though)' },
]

const TextAlign: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default TextAlign
