import { debounce } from 'lodash-es'
import { Action, AsyncAction } from '../../'
import { DocumentCreateVariables } from '../../effects/gql/documents/graphql-types/documentCreate'
import { DocumentUpdateVariables } from '../../effects/gql/documents/graphql-types/documentUpdate'
import { DocumentCreated } from '../../effects/gql/documents/graphql-types/documentCreated'
import { DocumentUpdated } from '../../effects/gql/documents/graphql-types/documentUpdated'
import { DocumentDeleted } from '../../effects/gql/documents/graphql-types/documentDeleted'
import { Project_project } from '../../effects/gql/projects/graphql-types/project'
import { isCodeFile } from '../../effects/monaco/lib'
import { FolderSlug, FolderFile, File as InternalFile } from './state'

export const load: Action<Project_project> = ({ state, effects, actions }, project) => {
  if (project.documents) {
    // first filter out any settings files that are left over from the old beta editor
    state.files.entries = project.documents
      .filter(
        (document) =>
          !(
            document.filename === 'settings.json' &&
            document.blob?.includes('javascript.eslint.disabled')
          ),
      )
      .reduce((files, file) => ({ ...files, [file.id]: file }), {})
    const mostRecentFile = state.files.draftFiles.sort(
      (fileA, fileB) => new Date(fileB.updatedAt).getTime() - new Date(fileA.updatedAt).getTime(),
    )[0]
    if (mostRecentFile) {
      actions.files.activate(mostRecentFile.id)
    } else if (state.files.draftFiles.length) {
      actions.files.activate(state.files.draftFiles[0].id)
    } else {
      console.error(
        new Error(
          `No files found for project ${state.projects.active?.id} and version ${state.projects.active?.draftVersion.id}`,
        ),
      )
    }
  }
  state.files.haveLoaded = true
  effects.gql.subscriptions.documentCreated({ projectId: project.id }, actions.files.onCreated)
  effects.gql.subscriptions.documentUpdated({ projectId: project.id }, actions.files.onUpdated)
  effects.gql.subscriptions.documentDeleted({ projectId: project.id }, actions.files.onDeleted)
}

export const unload: Action = ({ state, effects }) => {
  state.files.entries = {}
  state.files.haveLoaded = false
  effects.gql.subscriptions.documentCreated.dispose()
  effects.gql.subscriptions.documentUpdated.dispose()
  effects.gql.subscriptions.documentDeleted.dispose()
}

export const updateFileRankInFolder: Action<FolderFile> = ({ state, actions }, file) => {
  let currentFiles = actions.files.getByFolder(file.folder)
  const currentIndex = currentFiles.findIndex((folderedFile) => folderedFile.id === file.id)
  if (currentIndex !== -1) {
    // update the position of dragged file by removing it from the previous position and
    // adding it to the new
    currentFiles.splice(file.rank, 0, currentFiles.splice(currentIndex, 1)[0])
    currentFiles.forEach((file, index) => {
      state.files.entries[file.id].rank = index
      actions.files.optimisticUpdate({ ...file, rank: index })
    })
  }
}

export const setIsRenaming: Action<{ isRenaming: boolean; fileId: string }> = (
  { state },
  { isRenaming, fileId },
) => {
  const file = state.files.entries[fileId]
  if (file) {
    file.isRenaming = isRenaming
  }
}

export const findByName: Action<string, InternalFile | undefined> = ({ state }, filename) =>
  state.files.draftFiles.find((file) => file.filename === filename)

export const activate: Action<string> = ({ state, effects }, fileId) => {
  const currentlyActiveFile = Object.values(state.files.entries).find((file) => file.isActive)
  if (currentlyActiveFile && currentlyActiveFile.id !== fileId) {
    currentlyActiveFile.isActive = false
  }
  state.files.entries[fileId].isActive = true
  if (isCodeFile(state.files.entries[fileId])) {
    effects.monaco.activateModel(fileId)
  }
  // TODO: can we do better here? we don't activate the model for the initially active file
  // on mount because the editor isn't ready yet, so we handle this inside the effect instead
  // effects.monaco.activateModel(fileId)
}

export const prettify: AsyncAction<string> = async ({ state, effects }, fileId) => {
  try {
    const file = state.files.entries[fileId]
    const cursorIndex = effects.monaco.getCursorIndex()
    if (file) {
      const { formatted, cursorOffset } = await effects.prettier.prettify(
        { filename: file.filename, blob: file.blob },
        cursorIndex,
      )
      effects.monaco.updateModelCodeAndCursor({
        id: file.id,
        blob: formatted,
        cursorIndex: cursorOffset,
      })
    } else {
      throw new Error('no file found')
    }
  } catch (error) {
    console.error(error)
  }
}

export const newFromFilename: AsyncAction<string> = async ({ state, actions }, filename) => {
  try {
    const activeProject = state.projects.active
    if (activeProject) {
      await actions.files.create({ filename, versionId: activeProject.draftVersion.id })
    } else {
      throw new Error('no project found to attach file to')
    }
  } catch (error) {
    console.error(error)
  }
}

export const getByFolder: Action<FolderSlug, FolderFile[]> = ({ state }, folder) => {
  return state.files.folderedFiles
    .filter((file) => file.folder === folder)
    .sort((fileA, fileB) => fileA.rank - fileB.rank)
}

export const buildUrl: Action<FolderFile, string> = ({ actions }, file) => {
  return `${actions.projects.buildDraftUrl()}/${file.filename}`
}

export const create: AsyncAction<DocumentCreateVariables> = async (
  { state, effects, actions },
  file,
) => {
  try {
    const { documentCreate: newFile } = await effects.gql.mutations.documentCreate(file)
    if (newFile) {
      state.files.entries[newFile.id] = newFile
      if (isCodeFile(newFile)) {
        effects.monaco.createModel(newFile)
      }
      if (newFile.filename !== '_fonts.css') {
        actions.files.activate(newFile.id)
      }
    } else {
      throw new Error('file not created')
    }
  } catch (error) {
    console.error(error)
  }
}

export const duplicate: AsyncAction<string> = async ({ state, effects, actions }, fileId) => {
  try {
    const currentFile = state.files.entries[fileId]
    const activeProject = state.projects.active
    if (currentFile && activeProject) {
      await actions.files.create({
        filename: currentFile.filename.split('.').join('-2.'),
        versionId: activeProject.draftVersion.id,
        blob: currentFile.blob || '',
      })
    } else {
      throw new Error('file or project not found')
    }
  } catch (error) {
    console.error(error)
  }
}

export const upload: AsyncAction<{ file: File; onProgress: (percent: number) => void }> = async (
  { state, actions, effects },
  { file, onProgress },
) => {
  try {
    const activeProject = state.projects.active
    if (activeProject) {
      const { uploadDocument: newFile } = await effects.api.uploadDocument({
        projectId: activeProject.id,
        versionId: activeProject.draftVersion.id,
        file,
        onProgress,
      })
      if (newFile) {
        const oldFile = actions.files.findByName(newFile.filename)
        if (oldFile) {
          // this file has been replaced by the new file
          delete state.files.entries[oldFile.id]
          if (isCodeFile(oldFile)) {
            effects.monaco.deleteModel(oldFile)
          }
        }
        const mergedFile = {
          ...newFile,
          id: newFile.id.toString(),
          version: { id: activeProject.draftVersion.id },
        }
        state.files.entries[mergedFile.id] = mergedFile
        if (isCodeFile(mergedFile)) {
          effects.monaco.createModel(mergedFile)
        }
        actions.files.activate(mergedFile.id)
        // wait 300ms then clear progress
        setTimeout(() => {
          onProgress(0)
        }, 300)
      } else {
        onProgress(0)
        throw new Error('file not uploaded')
      }
    } else {
      throw new Error('no project or found to attach file to')
    }
  } catch (error) {
    console.error(error)
  }
}

export const handleFontFile: Action<string> = ({ state, actions }, blob) => {
  const filename = '_fonts.css'
  const fontFile = Object.values(state.files.entries).find((file) => file.filename === filename)
  if (fontFile && blob) {
    actions.files.updateBlob({ id: fontFile.id, blob })
  } else if (fontFile) {
    actions.files.remove(fontFile.id)
  } else if (blob) {
    const activeProject = state.projects.active
    if (activeProject) {
      actions.files.create({ filename, blob, versionId: activeProject.draftVersion.id })
    }
  }
}

export const rename: AsyncAction<DocumentUpdateVariables> = async ({ state, actions }, file) => {
  try {
    if (file.filename) {
      state.files.entries[file.id].filename = file.filename
      actions.files.optimisticUpdate(file)
    } else {
      throw new Error('files need filenames')
    }
  } catch (error) {
    console.error(error)
  }
}

export const optimisticUpdate: AsyncAction<DocumentUpdateVariables> = async ({ effects }, file) => {
  try {
    const { documentUpdate: updatedFile } = await effects.gql.mutations.documentUpdate(file)
    if (updatedFile) {
    } else {
      throw new Error('file not updated')
    }
  } catch (error) {
    console.error(error)
  }
}

export const update: AsyncAction<DocumentUpdateVariables> = async ({ state, effects }, file) => {
  const originalFile = state.files.entries[file.id]
  try {
    const { documentUpdate: updatedFile } = await effects.gql.mutations.documentUpdate(file)
    if (updatedFile) {
      // make sure to merge the changes into the current file to keep overmind happy
      state.files.entries[updatedFile.id] = {
        ...state.files.entries[updatedFile.id],
        ...updatedFile,
      }
      if (originalFile.filename !== updatedFile.filename) {
        effects.monaco.renameModel(updatedFile)
      }
    } else {
      throw new Error('file not updated')
    }
  } catch (error) {
    console.error(error)
  }
}

const updateBlobToApi: AsyncAction<{
  file: { id: string; blob: string }
  prevBlob: string | null
}> = async ({ state, effects }, { file, prevBlob }) => {
  const sessionId = effects.sessionId.get()
  try {
    const { documentUpdate: updatedFile } = await effects.gql.mutations.documentUpdate({
      sessionId,
      ...file,
    })
    if (!updatedFile) {
      state.files.entries[file.id].blob = prevBlob
      throw new Error('file not updated')
    } else {
      effects.preview.reload('ALL')
    }
  } catch (error) {
    console.error(error)
  }
}

const debouncedUpdateBlobToApi = debounce(updateBlobToApi, 300)

export const updateBlob: AsyncAction<{ id: string; blob: string }> = async (overmind, file) => {
  const { state, effects } = overmind
  const prevBlob = state.files.entries[file.id].blob
  effects.monaco.updateModelCode({ id: file.id, blob: file.blob })
  state.files.entries[file.id].blob = file.blob

  debouncedUpdateBlobToApi(overmind, { file, prevBlob })
}

export const remove: AsyncAction<string> = async ({ state, effects, actions }, id) => {
  try {
    const file = state.files.entries[id]
    const wasActive = file.isActive
    const { documentDelete } = await effects.gql.mutations.documentDelete({ id })
    if (documentDelete && file) {
      delete state.files.entries[file.id]
      if (wasActive) {
        const mostRecentFile = state.files.draftFiles.sort(
          (fileA, fileB) =>
            new Date(fileB.updatedAt).getTime() - new Date(fileA.updatedAt).getTime(),
        )[0]
        if (mostRecentFile) {
          actions.files.activate(mostRecentFile.id)
        }
      }
      if (isCodeFile(file)) {
        effects.monaco.deleteModel(file)
      }
    } else {
      throw new Error('file not deleted')
    }
  } catch (error) {
    console.error(error)
  }
}

export const onCreated: Action<DocumentCreated> = ({ state }, documentCreated) => {
  const { documentCreated: file } = documentCreated
  if (file) {
    state.files.entries[file.id] = file
  }
}

export const onUpdated: Action<DocumentUpdated> = ({ state, effects }, documentUpdated) => {
  const sessionId = effects.sessionId.get()
  const { documentUpdated: file } = documentUpdated
  if (file?.sessionId && file.sessionId !== sessionId) {
    const prevFile = state.files.entries[file.id]
    if (file.blob && prevFile.blob !== file.blob) {
      // if the `blob` has changed then we don't want to merge the entire file and also
      // we need to tell monaco
      state.files.entries[file.id].blob = file.blob
      effects.monaco.updateModelCode({ id: file.id, blob: file.blob, externalChange: true })
    } else {
      state.files.entries[file.id] = {
        ...prevFile,
        ...file,
      }
    }
  }
}

export const onDeleted: Action<DocumentDeleted> = ({ state, effects }, documentDeleted) => {
  const { documentDeleted: file } = documentDeleted
  if (file) {
    delete state.files.entries[file.id]
  }
}
