import * as React from 'react'
import styled from 'styled-components/macro'
import { useActions } from '../../../overmind'
import { FileWrapper } from '../File'
import Namer from './Namer'
import { ReactComponent as FileNew } from './file-new.svg'

export const GreyFileWrapper = styled(FileWrapper)`
  color: ${({ theme }) => theme.colors.FOREGROUND_50} !important;
`

export const GreyFileNew = styled(FileNew)`
  path {
    fill: ${({ theme }) => theme.colors.FOREGROUND_50} !important;
  }
`

export const Upload: React.FunctionComponent<{ value: string }> = ({ value }) => {
  return (
    <GreyFileWrapper
      onClick={() => {
        const fileUploader = document.querySelector<HTMLInputElement>('input[type=file]')
        if (fileUploader) fileUploader.click()
      }}
    >
      <GreyFileNew />
      Upload {value}
    </GreyFileWrapper>
  )
}

export const Add: React.FunctionComponent<{
  value: string
  defaultExtension: string | undefined
}> = ({ value, defaultExtension }) => {
  const {
    files: { newFromFilename },
  } = useActions()
  const [showNamer, setShowNamer] = React.useState(false)

  return (
    <GreyFileWrapper onClick={() => !showNamer && setShowNamer(true)}>
      <GreyFileNew />
      {showNamer ? (
        <Namer
          done={(filename) => newFromFilename(filename)}
          close={() => setShowNamer(false)}
          defaultExtension={defaultExtension}
        />
      ) : (
        `Add ${value} file`
      )}
    </GreyFileWrapper>
  )
}
