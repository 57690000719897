import * as React from 'react'
import styled from 'styled-components/macro'
import { Heading, Body } from '@superhi/design'
import CreateVersion from '../components/CreateVersion'
import { useState, useActions } from '../overmind'
import Version from '../components/Version'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.BACKGROUND};
  bottom: 0;
  height: 100vh;
  overflow-y: scroll;
  padding: 20px 40px;
  position: absolute;
  right: 0;
  width: calc(100vw - 60px);
  z-index: 1;
`

const Container = styled.div`
  width: 500px;
`

const Text = styled(Body).attrs(() => ({ level: '4' }))`
  background: ${({ theme }) => theme.colors.FOREGROUND_10};
  margin: 20px 0 40px;
  padding: 20px;
`

const Versions: React.FunctionComponent = () => {
  const {
    projects,
    versions: { nonDraft },
  } = useState()
  const { setTitleWithProject } = useActions()

  React.useEffect(() => {
    setTitleWithProject('Versions')
  }, [setTitleWithProject])

  return (
    <Wrapper>
      <Container>
        <Heading level="3">{projects.active ? projects.active.name : 'Project'}'s Versions</Heading>
        <Text>
          Think of versions as saving a completely new version of the site. If you make a big
          mistake or want to try out a new design without losing your previous progress, just create
          a save point. If you want to go back to the previous save point, just click revert!
        </Text>
        {nonDraft.map((version) => (
          <Version key={version.id} version={version} />
        ))}
        <CreateVersion />
      </Container>
    </Wrapper>
  )
}

export default Versions
