import { CachedModel } from '../monaco'

export default (() => {
  let cachedModels: { [id: string]: CachedModel } = {}

  const addModel = (model: CachedModel): CachedModel => {
    cachedModels[model.id] = model
    return model
  }

  const deleteModel = (model: CachedModel) => {
    model.model.dispose()
    delete cachedModels[model.id]
  }

  const deleteAllModels = () => {
    getAllModels().forEach((model) => {
      deleteModel(model)
    })
  }

  const getModel = (id: string): CachedModel | null => cachedModels[id]

  const getAllModels = () => Object.values(cachedModels)

  const getActiveModel = (): CachedModel | null => {
    const activeModel = Object.values(cachedModels).find((model) => model.isActive)
    return activeModel || null
  }

  return { addModel, deleteModel, deleteAllModels, getModel, getAllModels, getActiveModel }
})()
