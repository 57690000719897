import { OnInitialize } from '../overmind'

export const onInitialize: OnInitialize = ({ effects, state, actions }) => {
  const httpProtocol = effects.env.get('HTTP_PROTOCOL')
  const wsProtocol = effects.env.get('WS_PROTOCOL')
  const apiDomain = effects.env.get('API_DOMAIN')

  effects.gql.initialize(
    {
      endpoint: `${httpProtocol}${apiDomain}/api/v3`,
      headers: () => ({
        Authorization: `Bearer ${state.jwt}`,
      }),
    },
    {
      endpoint: `${wsProtocol}${apiDomain}/api/v3`,
      params: () => ({ authorization: state.jwt || effects.jwt.get() || '' }),
    },
  )
  effects.api.initialise({
    endpoint: `${httpProtocol}${apiDomain}/api/v1`,
    headers: () => ({
      Authorization: `Bearer ${state.jwt}`,
    }),
  })
  effects.monaco.initialise({
    getDraftFiles: () => state.files.draftFiles,
    onCodeChanged: actions.files.updateBlob,
    setPopper: actions.poppers.updateActive,
    setMarkers: actions.markers.replace,
    resetMarkers: actions.markers.reset,
    handleFontFile: actions.files.handleFontFile,
    prettier: effects.prettier,
    browser: effects.browser,
  })
  effects.sessionId.initialise()
  effects.fonts.initialise()
  effects.preview.initialise({
    onPopState: actions.preview.updatePreview,
  })
  effects.heap.initialise(effects.env.get('HEAP_ID'))
}
