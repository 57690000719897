import { derived } from 'overmind'
import { EditorInlinePreviewMode } from './effects/gql/graphql-global-types'
export type User = {
  id: string
  email: string
  username: string
  isAdmin: boolean
  isFrozen: boolean
}

type State = {
  title: string
  jwt: string | null
  user: User | null
  hasAppMounted: boolean
  isLoggedIn: boolean
  editorSettings: {
    esLintEnabled: boolean
    previewEnabled: boolean
    inlinePreviewMode: EditorInlinePreviewMode
    showSidebar: boolean
    useDarkMode: boolean
    hideWarnings: boolean
  }
}

export const defaultEditorSettings = {
  esLintEnabled: true,
  previewEnabled: true,
  inlinePreviewMode: EditorInlinePreviewMode.RIGHT,
  showSidebar: true,
  useDarkMode: false,
  hideWarnings: false,
}

export const TITLE = 'SuperHi Editor'

export const state: State = {
  title: TITLE,
  jwt: null,
  user: null,
  editorSettings: defaultEditorSettings,
  hasAppMounted: false,
  isLoggedIn: derived(({ jwt, user }: State) => Boolean(jwt) && Boolean(user)),
}
