import * as React from 'react'
import styled from 'styled-components/macro'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { useActions } from '../../overmind'
import { FolderFile, FolderSlug } from '../../overmind/namespaces/files/state'
import File from './File'

interface DragItem {
  id: string
  index: number
  type: FolderSlug
}

const FileWrapper = styled.div<{ $isDragging: boolean }>`
  opacity: ${(props) => (props.$isDragging ? 0 : 1)};
`

const DraggableFile: React.FunctionComponent<{ file: FolderFile; index: number }> = ({
  file,
  index,
}) => {
  const {
    files: { updateFileRankInFolder },
  } = useActions()
  // via https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_hooks_ts/04-sortable/simple
  const ref = React.useRef<HTMLDivElement>(null)

  const [, drop] = useDrop({
    accept: file.folder,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (ref.current) {
        const dragIndex = item.index
        const hoverIndex = index
        if (dragIndex !== hoverIndex) {
          updateFileRankInFolder({ ...file, rank: dragIndex })
          item.index = hoverIndex
        }
      }
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: file.folder, id: file.id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <FileWrapper ref={ref} $isDragging={isDragging}>
      <File file={file} />
    </FileWrapper>
  )
}

export default DraggableFile
