import { derived } from 'overmind'

export const filterMarkers = (entries: Markers, severity: Severity) =>
  Object.values(entries).reduce(
    (total, markers) => total + markers.filter((marker) => marker.severity === severity).length,
    0,
  )

export type Severity = 'ERROR' | 'WARNING' | 'OTHER'

export type Marker = {
  code: string
  startLineNumber: number
  startColumn: number
  endLineNumber: number
  endColumn: number
  message: string
  filename: string
  severity: Severity
}

export type Markers = { [filename: string]: Marker[] }

export type State = { entries: Markers; errors: number; warnings: number }

export const state: State = {
  entries: {},
  errors: derived((state: State) => filterMarkers(state.entries, 'ERROR')),
  warnings: derived((state: State) => filterMarkers(state.entries, 'WARNING')),
}
