import * as React from 'react'
import styled from 'styled-components/macro'
import Face from './Face'
import Button from './Button'
import { light } from '../theme'
import { useState } from '../../overmind'

export type Colour = keyof typeof light.colors

export const PopperContent = styled.div<{ colour: Colour }>`
  background-color: ${({ colour, theme }) => theme.colors[colour]};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.FOREGROUND_90};
  margin: 6px 0;
  max-width: 400px;
  padding: 16px 20px;
  z-index: 999;
`

export const PopperArrow = styled.div<{ colour: Colour; placement?: 'top' | 'bottom' }>`
  border-color: ${({ colour, theme }) => theme.colors[colour]} transparent transparent transparent;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  ${({ placement }) => (placement === 'top' ? 'bottom' : 'top')}: -6px;
  height: 0;
  position: absolute;
  transform: rotate(${({ placement }) => (placement === 'top' ? 0 : 180)}deg);
  width: 0;
`

const Poppers: React.FunctionComponent = () => {
  const {
    poppers: { active },
  } = useState()

  if (active?.type === 'FACE') {
    return <Face popper={active} />
  } else if (active?.type === 'HTML_BUTTON' || active?.type === 'CSS_BUTTON') {
    return <Button button={active} />
  } else {
    return null
  }
}

export default Poppers
