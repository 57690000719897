import * as React from 'react'
import styled from 'styled-components/macro'
import { useState } from '../../overmind'

const Wrapper = styled.div`
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.FOREGROUND_20};
  display: flex;
  flex: 0 0 62px;
  padding: 0 30px;
`

const H1 = styled.h1`
  font-family: 'Px';
  font-size: 100%;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const Name: React.FunctionComponent = () => {
  const { projects } = useState()
  return (
    <Wrapper>
      <H1>{projects.active && projects.active.name}</H1>
    </Wrapper>
  )
}

export default Name
