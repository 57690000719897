import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: '100', hint: 'Thin' },
  { value: '200', hint: 'Extra light' },
  { value: '300', hint: 'Light' },
  { value: '400', hint: 'Regular, default style' },
  { value: '500', hint: 'Medium' },
  { value: '600', hint: 'Semi-bold' },
  { value: '700', hint: 'Normal bold style' },
  { value: '800', hint: 'Extra-bold' },
  { value: '900', hint: 'Black' },
]

const FontWeight: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default FontWeight
