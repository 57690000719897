import { Action } from '../../../overmind'
import { Mode } from './state'

export const updatePreview: Action<{
  url: string
  back: boolean
  forward: boolean
  mode: Mode
}> = ({ state }, data) => {
  const frame = state.preview[data.mode]
  frame.currentLocation = data.url
  frame.canGoBack = data.back
  frame.canGoForward = data.forward
}

export const updateIsLoading: Action<{ isLoading: boolean; mode: Mode }> = (
  { state },
  { isLoading, mode },
) => {
  state.preview[mode].isLoading = isLoading
}
