import * as React from 'react'
import { ButtonComponent } from '../Button'
import List, { Value } from './List'
import { useActions } from '../../../overmind'

const BackgroundImage: ButtonComponent = ({ button }) => {
  const {
    files: { getByFolder },
  } = useActions()
  const folderImages: Value[] = getByFolder('image').map((file) => ({
    string: file.filename,
    value: `url("${file.filename}")`,
  }))
  const images = folderImages.concat([{ value: 'none' }])
  return <List values={images} button={button} />
}

export default BackgroundImage
