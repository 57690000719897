import * as React from 'react'
import styled from 'styled-components/macro'
import { useState } from '../../overmind'
import Font from './Font'
import Media from './Media'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.BACKGROUND};
  bottom: 0;
  height: calc(100% - 62px);
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
`

const Panel: React.FunctionComponent = () => {
  const {
    files: { active: file },
  } = useState()
  if (file?.folder === 'font' && !file.filename.endsWith('.eot')) {
    return (
      <Wrapper>
        <Font file={file} />
      </Wrapper>
    )
  } else if (
    file &&
    ['image', 'video', 'audio', 'document', 'font', 'other'].includes(file.folder)
  ) {
    return (
      <Wrapper>
        <Media file={file} />
      </Wrapper>
    )
  } else {
    return null
  }
}

export default Panel
