import { Action, AsyncAction } from '../overmind'
import {
  SessionCreateVariables,
  SessionCreate_sessionCreate,
} from './effects/gql/session/graphql-types/sessionCreate'
import { EditorInlinePreviewMode } from './effects/gql/graphql-global-types'
import { defaultEditorSettings, TITLE } from './state'

export const mountApp: AsyncAction = async ({ state, effects, actions }) => {
  state.jwt = effects.jwt.get()

  if (state.jwt) {
    try {
      const { session: user } = await effects.gql.queries.session()
      if (user) {
        actions.signedIn(user)
      } else {
        throw new Error('token not refreshed')
      }
    } catch (error) {
      console.error(error)
      effects.jwt.reset()
    }
  }
  state.hasAppMounted = true
}

export const signIn: AsyncAction<SessionCreateVariables, void | Error> = async (
  { effects, actions },
  loginVariables,
) => {
  try {
    const { sessionCreate: user } = await effects.gql.mutations.sessionCreate(loginVariables)
    if (user) {
      return actions.signedIn(user)
    } else {
      throw new Error('session not created')
    }
  } catch (error) {
    console.error(error)
    if (error instanceof Error) {
      return error
    }
  }
}

export const signedIn: AsyncAction<SessionCreate_sessionCreate> = async (
  { state, actions, effects },
  user,
) => {
  state.user = user
  actions.setJwt(user.token)
  actions.projects.load()
  effects.heap.identify(user.id)
  effects.heap.addUserProperties({
    email: user.email,
    'User type': user.type,
    'Membership status': user.membership?.status,
  })

  effects.heap.clearEventProperties()
  effects.heap.addEventProperties({
    'User type': user.type,
    'Membership status': user.membership?.status,
  })

  if (user.editorSettings) {
    state.editorSettings = user.editorSettings
  } else {
    const { editorSettingsCreate } = await effects.gql.mutations.editorSettingsCreate(
      defaultEditorSettings,
    )
    if (editorSettingsCreate) {
      state.editorSettings = editorSettingsCreate
    }
  }
}

export const updateEditorSettings: AsyncAction<{
  esLintEnabled?: boolean
  inlinePreviewMode?: EditorInlinePreviewMode
  previewEnabled?: boolean
  showSidebar?: boolean
  useDarkMode?: boolean
  hideWarnings?: boolean
}> = async ({ state, effects }, editorSettings) => {
  if (state.user) {
    const fullSettings = { ...state.editorSettings, ...editorSettings }
    const { editorSettingsUpdate } = await effects.gql.mutations.editorSettingsUpdate(fullSettings)
    if (editorSettingsUpdate) {
      state.editorSettings = editorSettingsUpdate
    }
  }
}

export const signOut: Action = ({ state, actions, effects }) => {
  state.user = null
  state.editorSettings = defaultEditorSettings
  actions.resetJwt()
  actions.projects.unload()
  effects.heap.resetIdentity()
  effects.heap.clearEventProperties()
}

export const resetJwt: Action = ({ state, effects }) => {
  effects.jwt.reset()
  state.jwt = null
}

export const setJwt: Action<string | null> = ({ state, effects }, jwt) => {
  effects.jwt.set(jwt)
  state.jwt = jwt
}

export const setTitle: Action<string> = ({ state }, title) => {
  state.title = `${title} | ${TITLE}`
}

export const setTitleWithProject: Action<string> = ({ state }, title) => {
  const projectName = state.projects.active?.name
  if (projectName) {
    state.title = `${title} | ${projectName} | ${TITLE}`
  }
}

export const resetTitle: Action = ({ state }) => {
  state.title = TITLE
}
