import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components/macro'
import { useActions } from '../../overmind'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.BACKGROUND};
  display: flex;
  flex: 0 0 80px;
  font-family: 'Px';
  font-size: 13px;
  font-weight: 700;
  margin: 30px;
  position: relative;
`

const Inner = styled.div<{ width: number }>`
  background-color: ${({ theme }) => theme.colors.PRIMARY};
  height: 100%;
  left: 0;
  position: absolute;
  width: ${({ width }) => width}%;
  z-index: 1;
`

const Text = styled.div`
  align-items: center;
  height: 100%;
  display: flex;
  mix-blend-mode: difference;
  padding: 0 30px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;

  p {
    color: ${({ theme }) => theme.colors.FOREGROUND_10};
    width: 100%;
  }
`

const Uploader: React.FunctionComponent = () => {
  const {
    files: { upload },
  } = useActions()
  const [uploadPercent, setUploadPercent] = React.useState(0)

  const onDrop = React.useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        upload({ file, onProgress: setUploadPercent })
      })
    },
    [upload],
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Wrapper {...getRootProps()}>
      <input {...getInputProps()} />
      <Inner width={uploadPercent} />
      <Text>
        {uploadPercent ? (
          <p>{uploadPercent}%</p>
        ) : isDragActive ? (
          <p>Drop your files here!</p>
        ) : (
          <p>Drag and drop files or click to upload</p>
        )}
      </Text>
    </Wrapper>
  )
}

export default Uploader
