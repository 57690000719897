import * as React from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Body, Grid, PageSection as Section } from '@superhi/design'
import CardPage from '../components/_MarketingSite/CardPage'
import LoginForm from '../components/LoginForm'
import { useState, useActions } from '../overmind'

const FullSection = styled(Section)`
  min-height: 100vh;
`

const GRID_1_CELL_SPAN_1: React.ComponentProps<typeof Grid.Cell>['span'] = {
  small: 'full',
  medium: 'full',
  large: [4, 9],
}

const Login: React.FunctionComponent = () => {
  const { isLoggedIn } = useState()
  const { setTitle } = useActions()

  React.useEffect(() => {
    setTitle('Log in')
  }, [setTitle])

  return isLoggedIn ? (
    <Redirect to="/projects" />
  ) : (
    <FullSection backgroundColor="pale-blue">
      <Grid>
        <Grid.Cell span={GRID_1_CELL_SPAN_1}>
          <CardPage
            decoration="star"
            title="Log in to the SuperHi Editor"
            footerItems={[
              {
                display: 'Need an account?',
                href: 'https://account.superhi.com/signup',
              },
              {
                display: 'Forgot your password?',
                href: 'https://account.superhi.com/forgotten-password',
              },
            ]}
          >
            <LoginForm />
            <Body level="3" color="GREY_60">
              Psst...you can login with the same account info that you use for the SuperHi website.
            </Body>
          </CardPage>
        </Grid.Cell>
      </Grid>
    </FullSection>
  )
}

export default Login
