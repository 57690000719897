import { Query, gql } from 'overmind-graphql'
import { DomainCreate, DomainCreateVariables } from './graphql-types/domainCreate'
import { DomainDelete, DomainDeleteVariables } from './graphql-types/domainDelete'
import { domainFields } from './queries'

export const domainCreate: Query<DomainCreate, DomainCreateVariables> = gql`
  mutation DomainCreate($projectId: ID!, $url: String!) {
    domainCreate(projectId: $projectId, url: $url) {
      ...DomainFields
    }
  }
  ${domainFields}
`

export const domainDelete: Query<DomainDelete, DomainDeleteVariables> = gql`
  mutation DomainDelete($id: ID!) {
    domainDelete(id: $id) {
      id
    }
  }
`
