import { Query, gql } from 'overmind-graphql'
import {
  EditorSettingsCreate,
  EditorSettingsCreateVariables,
} from './graphql-types/editorSettingsCreate'
import {
  EditorSettingsUpdate,
  EditorSettingsUpdateVariables,
} from './graphql-types/editorSettingsUpdate'
import { editorSettingsFields } from './queries'

export const editorSettingsCreate: Query<EditorSettingsCreate, EditorSettingsCreateVariables> = gql`
  mutation EditorSettingsCreate(
    $esLintEnabled: Boolean!
    $inlinePreviewMode: EditorInlinePreviewMode!
    $previewEnabled: Boolean!
    $showSidebar: Boolean!
    $useDarkMode: Boolean!
    $hideWarnings: Boolean!
  ) {
    editorSettingsCreate(
      esLintEnabled: $esLintEnabled
      inlinePreviewMode: $inlinePreviewMode
      previewEnabled: $previewEnabled
      showSidebar: $showSidebar
      useDarkMode: $useDarkMode
      hideWarnings: $hideWarnings
    ) {
      ...EditorSettingsFields
    }
  }
  ${editorSettingsFields}
`

export const editorSettingsUpdate: Query<EditorSettingsUpdate, EditorSettingsUpdateVariables> = gql`
  mutation EditorSettingsUpdate(
    $esLintEnabled: Boolean!
    $inlinePreviewMode: EditorInlinePreviewMode!
    $previewEnabled: Boolean!
    $showSidebar: Boolean!
    $useDarkMode: Boolean!
    $hideWarnings: Boolean!
  ) {
    editorSettingsUpdate(
      esLintEnabled: $esLintEnabled
      inlinePreviewMode: $inlinePreviewMode
      previewEnabled: $previewEnabled
      showSidebar: $showSidebar
      useDarkMode: $useDarkMode
      hideWarnings: $hideWarnings
    ) {
      ...EditorSettingsFields
    }
  }
  ${editorSettingsFields}
`
