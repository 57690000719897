import * as React from 'react'
import styled from 'styled-components/macro'
import { Colour } from '../Poppers'
import Popper from './Popper'
import { Marker, filterMarkers, Severity } from '../../overmind/namespaces/markers/state'
import { useState, useActions } from '../../overmind'

const MarkerPopper = styled.div<{ colour: Colour }>`
  background-color: ${({ theme }) => theme.colors.FOREGROUND_90};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.BACKGROUND};
  max-width: 400px;
  padding: 12px 22px 16px;
  z-index: 999;
`

export const MarkerArrow = styled.div<{ colour: Colour }>`
  border-color: transparent transparent ${({ theme }) => theme.colors.FOREGROUND_90} transparent;
  border-style: solid;
  border-width: 0 4px 6px 4px;
  top: -6px;
  height: 0;
  position: absolute;
  width: 0;

  &[data-hide] {
    visibility: hidden;
  }
`

const Row = styled.div`
  cursor: pointer;
  font-size: 13px;
  padding: 3px 0;
`

const StyledMarker = styled.span<{ colour: Colour }>`
  border-bottom: 2px solid ${({ colour, theme }) => theme.colors[colour]};
  font-weight: 700;
`

const SingleMarker: React.FunctionComponent<{ total: number; severity: Severity }> = ({
  total,
  severity,
}) => (
  <StyledMarker colour={severity === 'ERROR' ? 'ERROR' : 'WARNING'}>
    {total} {severity.toLowerCase()}
    {total !== 1 ? 's' : ''}
  </StyledMarker>
)

const FileMarkers: React.FunctionComponent<{ filename: string; markers: Marker[] }> = ({
  filename,
  markers,
}) => {
  const {
    files: { findByName, activate },
  } = useActions()

  const errors = filterMarkers({ [filename]: markers }, 'ERROR')
  const warnings = filterMarkers({ [filename]: markers }, 'WARNING')

  const handleClick = () => {
    const file = findByName(filename)
    if (file) {
      activate(file.id)
    }
  }

  return (
    <Row onClick={handleClick}>
      {errors ? <SingleMarker total={errors} severity={'ERROR'} /> : null}
      {errors && warnings ? ' and ' : ''}
      {warnings ? <SingleMarker total={warnings} severity={'WARNING'} /> : null} in {filename}
    </Row>
  )
}

const Markers: React.FunctionComponent<{ reference: HTMLElement }> = ({ reference }) => {
  const {
    markers: { entries },
  } = useState()

  return (
    <Popper
      PopperWrapper={MarkerPopper}
      PopperArrow={MarkerArrow}
      reference={reference}
      placement="bottom"
      colour="FOREGROUND_90"
      offset={-10}
    >
      {() => (
        <>
          {Object.entries(entries).map(([filename, markers]) => (
            <FileMarkers key={filename} filename={filename} markers={markers} />
          ))}
        </>
      )}
    </Popper>
  )
}

export default Markers
