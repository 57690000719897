import * as React from 'react'
import styled from 'styled-components/macro'
import { string, boolean } from 'yup'
import {
  Heading,
  Form,
  FormTextInput,
  FormSelectInput,
  FormCheckboxInput,
  Button,
} from '@superhi/design'
import { useState, useActions } from '../overmind'
import { EditorInlinePreviewMode } from '../overmind/effects/gql/graphql-global-types'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.BACKGROUND};
  bottom: 0;
  height: 100vh;
  overflow-y: scroll;
  padding: 20px 40px;
  position: absolute;
  right: 0;
  width: calc(100vw - 60px);
  z-index: 1;
`

const Container = styled.div`
  width: 500px;
`

const BlueHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.PRIMARY};
  margin: 40px 0 0;
`

const Delete = styled(Button)`
  background-color: ${({ theme }) => theme.colors.ERROR};
  border-color: ${({ theme }) => theme.colors.ERROR};
  margin-top: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.ERROR_DARKEN};
    border-color: ${({ theme }) => theme.colors.ERROR_DARKEN};
  }
`

type FormData = {
  name: string
  slug: string
  isPasswordProtected: boolean
  password: string
  passwordConfirmation: string
  esLintEnabled: boolean
  previewEnabled: boolean
  inlinePreviewMode: EditorInlinePreviewMode
  showSidebar: boolean
  useDarkMode: boolean
  hideWarnings: boolean
  metaTitle: string | null
  metaDescription: string | null
  sharingTitle: string | null
  sharingDescription: string | null
  sharingImage: string | null
  twitterHandle: string | null
  favicon: string | null
  homeScreenImage: string | null
}

const Settings: React.FunctionComponent = () => {
  const {
    editorSettings,
    projects: { active: project },
    versions: { currentDraft: version },
  } = useState()
  const {
    setTitleWithProject,
    projects,
    versions,
    updateEditorSettings,
    files: { getByFolder },
  } = useActions()

  React.useEffect(() => {
    setTitleWithProject('Settings')
  }, [setTitleWithProject])

  const selectOptions = [{ value: '', display: 'No image' }].concat(
    getByFolder('image').map((image) => ({
      value: `/${image.filename}`,
      display: image.filename,
    })),
  )

  return (
    <Wrapper>
      {project && version && (
        <Container>
          <Heading level="3">{project.name}'s Settings</Heading>
          <Form<FormData>
            name="settings"
            validationSchema={{
              name: string().required(),
              slug: string(),
              isPasswordProtected: boolean(),
              password: string(),
              passwordConfirmation: string(),
              esLintEnabled: boolean(),
              previewEnabled: boolean(),
              inlinePreviewMode: string(),
              showSidebar: boolean(),
              useDarkMode: boolean(),
              hideWarnings: boolean(),
              metaTitle: string(),
              metaDescription: string(),
              sharingTitle: string(),
              sharingDescription: string(),
              sharingImage: string(),
              twitterHandle: string(),
              favicon: string(),
              homeScreenImage: string(),
            }}
            initialValues={{
              name: project.name,
              slug: project.slug,
              isPasswordProtected: project.isPasswordProtected,
              password: '',
              passwordConfirmation: '',
              esLintEnabled: editorSettings.esLintEnabled,
              previewEnabled: editorSettings.previewEnabled,
              inlinePreviewMode: editorSettings.inlinePreviewMode,
              showSidebar: editorSettings.showSidebar,
              useDarkMode: editorSettings.useDarkMode,
              hideWarnings: editorSettings.hideWarnings,
              metaTitle: version.metaTitle || '',
              metaDescription: version.metaDescription || '',
              sharingTitle: version.sharingTitle || '',
              sharingDescription: version.sharingDescription || '',
              sharingImage: version.sharingImage || '',
              twitterHandle: version.twitterHandle || '',
              favicon: version.favicon || '',
              homeScreenImage: version.homeScreenImage || '',
            }}
            submitButtonText="Update Project"
            onSubmit={async (values, { setFieldError }) => {
              const formProject = {
                id: project.id,
                name: values.name,
                slug: values.slug,
                isPasswordProtected: values.isPasswordProtected,
                password: values.password,
                passwordConfirmation: values.passwordConfirmation,
              }
              const formEditorSettings = {
                esLintEnabled: values.esLintEnabled,
                previewEnabled: values.previewEnabled,
                inlinePreviewMode: values.inlinePreviewMode,
                showSidebar: values.showSidebar,
                useDarkMode: values.useDarkMode,
              }
              const formVersion = {
                id: version.id,
                metaTitle: values.metaTitle,
                metaDescription: values.metaDescription,
                sharingTitle: values.sharingTitle,
                sharingDescription: values.sharingDescription,
                sharingImage: values.sharingImage,
                twitterHandle: values.twitterHandle,
                favicon: values.favicon,
                homeScreenImage: values.homeScreenImage,
              }

              if (formProject.password !== formProject.passwordConfirmation) {
                const message = 'Password and password confirmation must match'
                setFieldError('password', message)
                setFieldError('passwordConfirmation', message)
              } else {
                await Promise.all([
                  projects.update(formProject),
                  versions.update(formVersion),
                  updateEditorSettings(formEditorSettings),
                ])
              }
              //TODO: check for errors from each update, set errors on form
            }}
          >
            <BlueHeading level="5">Project Settings</BlueHeading>
            <Form.Row>
              <FormTextInput
                name="name"
                ariaLabel="Project name"
                label="Project name"
                placeholder={project.name}
                required
              />
            </Form.Row>
            <Form.Row>
              <FormTextInput
                name="slug"
                ariaLabel="Project URL"
                label="Project URL"
                placeholder={project.slug}
                required
              />
            </Form.Row>

            <Form.Row>
              <FormCheckboxInput
                name="isPasswordProtected"
                ariaLabel="Password Protected?"
                label="Password Protected?"
              >
                Is your project password protected?
              </FormCheckboxInput>
            </Form.Row>

            <Form.Peek<FormData, FormData['isPasswordProtected']> name="isPasswordProtected">
              {({ meta }) =>
                meta.value && (
                  <>
                    <Form.Row>
                      <FormTextInput
                        name="password"
                        ariaLabel="Project password"
                        label="Project password"
                        placeholder=""
                        required
                      />
                    </Form.Row>
                    <Form.Row>
                      <FormTextInput
                        name="passwordConfirmation"
                        ariaLabel="Confirm project password"
                        label="Confirm project password"
                        placeholder=""
                        required
                      />
                    </Form.Row>
                  </>
                )
              }
            </Form.Peek>

            <BlueHeading level="5">Search Engine Settings</BlueHeading>
            <Form.Row>
              <FormTextInput
                name="metaTitle"
                ariaLabel="Default search engine title"
                label="Default search engine title"
                placeholder="e.g. My Awesome Website"
              />
            </Form.Row>
            <Form.Row>
              <FormTextInput
                name="metaDescription"
                ariaLabel="Default search engine description"
                label="Default search engine description"
                placeholder="e.g. Come and see all the fantastic projects that I can do for you"
              />
            </Form.Row>

            <BlueHeading level="5">Social Settings</BlueHeading>
            <Form.Row>
              <FormTextInput
                name="sharingTitle"
                ariaLabel="Default sharing title"
                label="Default sharing title"
                placeholder="e.g. My Awesome Website"
              />
            </Form.Row>
            <Form.Row>
              <FormTextInput
                name="sharingDescription"
                ariaLabel="Default sharing description"
                label="Default sharing description"
                placeholder="e.g. Come and see all the fantastic projects that I can do for you"
              />
            </Form.Row>
            <Form.Row>
              <FormSelectInput
                name="sharingImage"
                ariaLabel="Default sharing image"
                label="Default sharing image"
                options={selectOptions}
              />
            </Form.Row>
            <Form.Row>
              <FormTextInput
                name="twitterHandle"
                ariaLabel="Your site's Twitter handle"
                label="Your site's Twitter handle"
                placeholder="e.g. @superhi_"
              />
            </Form.Row>

            <BlueHeading level="5">Project Icon Settings</BlueHeading>
            <Form.Row>
              <FormSelectInput
                name="favicon"
                ariaLabel="Favicon"
                label="Favicon"
                options={selectOptions}
              />
            </Form.Row>
            <Form.Row>
              <FormSelectInput
                name="homeScreenImage"
                ariaLabel="Home screen icon"
                label="Home screen icon"
                options={selectOptions}
              />
            </Form.Row>

            <BlueHeading level="5">Editor Settings</BlueHeading>
            {/* <Form.Row>
              <FormCheckbox name="esLintEnabled" required>
                Use ESLint to validate code
              </FormCheckbox>
            </Form.Row> */}
            <Form.Row>
              <FormCheckboxInput ariaLabel="Enable live previews" name="previewEnabled" required>
                Enable live previews
              </FormCheckboxInput>
            </Form.Row>
            {/* <Form.Row>
              <FormCheckbox name="hideWarnings" required>
                Hide Warnings
              </FormCheckbox>
            </Form.Row> */}
            {/* <Form.Row>
              <FormCheckbox name="showSidebar" required>
                Show sidebar
              </FormCheckbox>
            </Form.Row> */}
            {/* <Form.Row>
              <FormCheckbox name="useDarkMode" required>
                Use dark mode
              </FormCheckbox>
            </Form.Row> */}
            <Form.Row>
              <FormSelectInput
                name="inlinePreviewMode"
                ariaLabel="Inline preview mode"
                label="Inline preview mode"
                options={[
                  { value: EditorInlinePreviewMode.RIGHT, display: 'Right' },
                  { value: EditorInlinePreviewMode.BOTTOM, display: 'Bottom' },
                  { value: EditorInlinePreviewMode.OFF, display: 'Off' },
                ]}
              />
            </Form.Row>
          </Form>

          <Delete
            onClick={() => {
              if (window.confirm('Are you sure you want to delete this project?')) {
                projects.remove(project.id)
              }
            }}
          >
            Delete Project
          </Delete>
        </Container>
      )}
    </Wrapper>
  )
}

export default Settings
