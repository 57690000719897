import * as React from 'react'
import styled from 'styled-components/macro'
import ResizeObserver from 'resize-observer-polyfill'
import { Link } from '@superhi/design'
import { useActions, useEffects } from '../../overmind'
import Menu from './Menu'
import Frame from './Frame'
import QRPopper from '../Poppers/QR'

export type Dimensions = [number, number]

const NAV_WIDTH = 60
const PADDING = 30
const MENU_HEIGHT = 62

const Wrapper = styled.div<{ width: number; height: number }>`
  border: 1px solid ${({ theme }) => theme.colors.FOREGROUND_20};
  border-radius: 3px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height}px;
  margin-bottom: -${PADDING * 2}px;
  max-height: calc(100vh - ${PADDING * 2}px - ${MENU_HEIGHT}px);
  max-width: calc(100% - ${PADDING * 2}px);
  min-width: 320px;
  min-height: calc(360px + ${MENU_HEIGHT}px);
  overflow: auto;
  position: relative;
  resize: both;
  width: ${({ width }) => width}px;

  /* so the iframe doesn't interfere with resizing */
  :active {
    iframe {
      pointer-events: none;
    }
  }
`

const Handle = styled.span`
  border-color: transparent transparent ${({ theme }) => theme.colors.FOREGROUND_20} transparent;
  border-style: solid;
  border-width: 0 0 10px 10px;
  bottom: 0;
  content: '';
  cursor: se-resize;
  height: 0;
  position: absolute;
  right: 0;
  width: 0;
  z-index: 2;
`

const Top = styled.div`
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.FOREGROUND_10};
  color: ${({ theme }) => theme.colors.PRIMARY};
  display: flex;
  font-family: 'Px';
  font-size: 100%;
  height: 62px;
  justify-content: space-between;
  padding: 0 30px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`

const HiddenOption = styled.option`
  display: none;
`

const QRButton = styled.span`
  cursor: pointer;
  margin: 0 20px;
`

const QR: React.FunctionComponent<{ to: string }> = ({ to }) => {
  const [showPopper, setShowPopper] = React.useState(false)
  const buttonEl = React.useRef<HTMLSpanElement>(null)
  return (
    <QRButton ref={buttonEl} onClick={() => setShowPopper((showPopper) => !showPopper)}>
      QR
      {showPopper && buttonEl.current && <QRPopper reference={buttonEl.current} to={to} />}
    </QRButton>
  )
}

const devices: { [deviceName: string]: Dimensions } = {
  'iPad Pro 12.9" ↕': [1024, 1366 + MENU_HEIGHT],
  'iPad Pro 12.9" ↔': [1366, 1024 + MENU_HEIGHT],
  'iPad Pro 11" ↕': [834, 1194 + MENU_HEIGHT],
  'iPad Pro 11" ↔': [1194, 834 + MENU_HEIGHT],
  'iPad Air 10.9" ↕': [820, 1180 + MENU_HEIGHT],
  'iPad Air 10.9" ↔': [1180, 820 + MENU_HEIGHT],
  'iPad 10.2" ↕': [810, 1080 + MENU_HEIGHT],
  'iPad 10.2" ↔': [1080, 810 + MENU_HEIGHT],
  'iPad Mini ↕': [768, 1024 + MENU_HEIGHT],
  'iPad Mini ↔': [1024, 768 + MENU_HEIGHT],
  'iPhone 12 Pro Max ↕': [428, 926 + MENU_HEIGHT],
  'iPhone 12 Pro Max ↔': [926, 428 + MENU_HEIGHT],
  'iPhone 12 ↕': [390, 844 + MENU_HEIGHT],
  'iPhone 12 ↔': [844, 390 + MENU_HEIGHT],
  'iPhone 12 mini ↕': [360, 780 + MENU_HEIGHT],
  'iPhone 12 mini ↔': [780, 360 + MENU_HEIGHT],
}
type DeviceName = keyof typeof devices

const DevicePicker: React.FunctionComponent<{
  updateDimensions: (dimensions: Dimensions) => void
}> = ({ updateDimensions }) => {
  const handleChange = (deviceName: DeviceName) => {
    const dimensions = devices[deviceName]
    if (dimensions) {
      updateDimensions(dimensions)
    }
  }
  return (
    <select
      defaultValue="Choose a device"
      onChange={(event) => handleChange(event?.target.value as DeviceName)}
    >
      <HiddenOption>Choose a device</HiddenOption>
      {Object.entries(devices).map(([deviceName, dimensions]) => (
        <option key={deviceName} value={deviceName}>
          {deviceName}
        </option>
      ))}
    </select>
  )
}

const FullscreenPreview: React.FunctionComponent = () => {
  const {
    projects: { buildDraftUrl },
  } = useActions()
  const {
    browser: { storage },
  } = useEffects()
  const initialDimensions: Dimensions = [
    window.innerWidth - (PADDING * 2 + NAV_WIDTH),
    window.innerHeight - PADDING * 2,
  ]
  const [dimensions, setDimensions] = React.useState<Dimensions>(initialDimensions)
  const [displayDimensions, setDisplayDimensions] = React.useState<Dimensions>(initialDimensions)
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  const wrapperObserver = React.useMemo<ResizeObserver>(
    () =>
      new ResizeObserver((entries) => {
        const resizedWrapper = entries[0]
        if (resizedWrapper) {
          const { width, height } = resizedWrapper.contentRect
          storage.set('previewDimensions', [width, height])
          const [currentWidth, currentHeight] = displayDimensions
          const newHeight = height - MENU_HEIGHT
          if (currentWidth !== width || currentHeight !== newHeight) {
            setDisplayDimensions([width, newHeight])
          }
        }
      }),
    [displayDimensions, storage],
  )

  // track resizing of preview
  React.useEffect(() => {
    const wrapper = wrapperRef.current
    if (wrapper) {
      wrapperObserver.observe(wrapper)
      return () => {
        wrapperObserver.unobserve(wrapper)
      }
    }
  }, [wrapperObserver])

  // set initial dimensions of preview from those saved in localStorage
  React.useEffect(() => {
    const savedDimensions = storage.get('previewDimensions')
    if (savedDimensions) {
      setDimensions(savedDimensions)
    }
  }, [storage])

  const draftUrl = buildDraftUrl()

  return (
    <>
      <Top>
        <span>
          <Link href={draftUrl} isExternal={true}>
            {draftUrl}
          </Link>
          <QR to={draftUrl} />
        </span>
        <span>
          {displayDimensions[0]}px × {displayDimensions[1]}px
        </span>
        <DevicePicker updateDimensions={setDimensions} />
      </Top>
      <Wrapper
        ref={wrapperRef}
        width={dimensions[0]}
        height={dimensions[1]}
        style={{ width: `${dimensions[0]}px`, height: `${dimensions[1]}px` }}
      >
        <Menu mode="FULLSCREEN" />
        <Frame mode="FULLSCREEN" />
        <Handle />
      </Wrapper>
    </>
  )
}

export default FullscreenPreview
