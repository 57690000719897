import { Query, gql } from 'overmind-graphql'
import { Projects, ProjectsVariables } from './graphql-types/projects'
import { Project, ProjectVariables } from './graphql-types/project'
import { ProjectFields } from './graphql-types/projectFields'
import { documentFields } from '../documents/queries'
import { versionFields } from '../versions/queries'
import { domainFields } from '../domains/queries'

export const projectFields: Query<ProjectFields> = gql`
  fragment ProjectFields on Project {
    id
    name
    slug
    isStarred
    tag
    isPasswordProtected
    draftVersion {
      id
    }
    liveVersion {
      id
    }
    archiveDate
    insertedAt
    updatedAt
  }
`

export const projects: Query<Projects, ProjectsVariables> = gql`
  query Projects($sort: ProjectSort, $tag: ProjectTag, $page: Int) {
    projects(sort: $sort, tag: $tag, page: $page) {
      nodes {
        ...ProjectFields
      }
      pageInfo {
        hasNextPage
        totalCount
      }
      totalCount
    }
  }
  ${projectFields}
`

export const project: Query<Project, ProjectVariables> = gql`
  query Project($id: ID!) {
    project(id: $id) {
      id
      draftVersion {
        ...VersionFields
      }
      documents {
        ...DocumentFields
      }
      versions {
        nodes {
          ...VersionFields
        }
        pageInfo {
          hasNextPage
          totalCount
        }
        totalCount
      }
      domains {
        nodes {
          ...DomainFields
        }
        pageInfo {
          hasNextPage
          totalCount
        }
        totalCount
      }
    }
  }
  ${documentFields}
  ${versionFields}
  ${domainFields}
`
