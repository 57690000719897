import * as React from 'react'
import styled from 'styled-components/macro'
import { PopperButton } from '../../../overmind/namespaces/poppers/state'
import { useActions, useEffects } from '../../../overmind'
import Yes from './yes.svg'

export type Value = {
  value: string
  string?: string
  hint?: string
}

const ValueWrapper = styled.div`
  color: ${({ theme }) => theme.colors.FOREGROUND_20};
  padding: 8px 20px;
  position: relative;
  &:hover {
    background-color: ${({ theme }) => theme.colors.FOREGROUND_70};
    color: ${({ theme }) => theme.colors.BACKGROUND};
    cursor: pointer;
  }
  &:first-of-type {
    padding-top: 14px;
  }
  &:last-of-type {
    padding-bottom: 14px;
  }
`

const SelectedValueWrapper = styled(ValueWrapper)`
  background-color: ${({ theme }) => theme.colors.FOREGROUND_80};
  color: ${({ theme }) => theme.colors.BACKGROUND};

  &:after {
    background: url(${Yes}) center no-repeat;
    content: '';
    display: block;
    height: 20px;
    margin-top: -10px;
    position: absolute;
    right: 20px;
    top: 50%;
    width: 20px;
  }
`

const ValueValue = styled.span`
  display: block;
  font-family: 'PlexMono';
  font-size: 15px;
  font-weight: 600;
`

const Hint = styled.span`
  font-size: 13px;
`

const stripQuotes = (string: string) => string.replace(/^"(.+(?="$))"$/, '$1')

const SingleValue: React.FunctionComponent<{ value: Value; button: PopperButton }> = ({
  value,
  button,
}) => {
  const { poppers } = useActions()
  const { monaco } = useEffects()
  const ValueComponent =
    stripQuotes(value.value) === stripQuotes(button.value) ? SelectedValueWrapper : ValueWrapper

  const handleClick = () => {
    const text =
      button.type === 'CSS_BUTTON'
        ? `${button.property}: ${value.value}`
        : `${button.property}="${value.value}"`
    monaco.updatePartialActiveModelCode({
      range: [button.start.line, button.start.column, button.end.line, button.end.column],
      text,
    })
    poppers.updateActive(null)
  }

  return (
    <ValueComponent onClick={handleClick}>
      <ValueValue>{value.string || value.value}</ValueValue>
      {value.hint && <Hint>{value.hint}</Hint>}
    </ValueComponent>
  )
}

const List: React.FunctionComponent<{ values: Value[]; button: PopperButton }> = ({
  values,
  button,
}) => {
  return (
    <>
      {values.map((value) => (
        <SingleValue key={value.value} value={value} button={button} />
      ))}
    </>
  )
}

export default List
