import * as React from 'react'
import { useIntersection } from 'react-use'
import { formatDistance } from 'date-fns'
import { Card, Img, Heading, Body, TextVerticalSpacer } from '@superhi/design'
import { useActions } from '../overmind'
import { Project } from '../overmind/namespaces/projects/state'

const ProjectCard: React.FC<{ project: Project }> = ({ project }) => {
  const {
    projects: { buildUrl },
  } = useActions()
  const intersectionRef = React.useRef<HTMLDivElement>(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '200px 0px',
    threshold: 0,
  })

  const src =
    intersection && intersection.isIntersecting
      ? `https://api.urlbox.io/v1/R1VPJnnyoNfmj7lN/jpg/?url=https://${
          project.slug
        }-draft.superhi.com&width=1440&height=800&retina=true&thumb_width=800&unique=${new Date(
          project.updatedAt,
        ).getTime()}`
      : '#'

  return (
    <div ref={intersectionRef}>
      <Card
        href={`/projects/${project.id}`}
        header={<Img src={src} aspectRatio="16:9" fit="cover" />}
      >
        <TextVerticalSpacer level="5">
          <Heading level="5">{project.name}</Heading>
          <Body level="4">
            <span>{buildUrl(project.id)}</span>
            <br />
            <span>Updated {formatDistance(new Date(`${project.updatedAt}Z`), new Date())} ago</span>
          </Body>
        </TextVerticalSpacer>
      </Card>
    </div>
  )
}

export default ProjectCard
