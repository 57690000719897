export type FaceState = 'happy' | 'sad' | 'meh'

export type Position = {
  line: number
  column: number
}

export type ButtonType = 'HTML_BUTTON' | 'CSS_BUTTON'

export type Face = {
  id: string
  selectors: string
  line: number
  state: FaceState
  total: number
  totalFiles: { [key: string]: number }
  type: 'FACE'
}

export type Button = {
  id: string
  start: Position
  end: Position
  tag?: string
  property: string
  value: string
  type: ButtonType
}

export type Decoration = Face | Button

export type WithClassName = {
  className: string | undefined
}

export type PopperFace = Face & WithClassName

export type PopperButton = Button & WithClassName

export type Popper = PopperFace | PopperButton

type State = {
  active: Popper | null
}

export const state: State = { active: null }
