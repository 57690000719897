import * as React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useEffects } from '../overmind'

const Keys: React.FC = () => {
  const { browser } = useEffects()
  useHotkeys('command+k', () => {
    browser.toggleBetweenViews()
  })

  return null
}

export default Keys
