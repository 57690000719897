import { derived } from 'overmind'
import { Config } from '../../../overmind'

export type Version = {
  id: string
  key: string
  title: string
  description: string | null
  isDraft: boolean
  isLive: boolean
  metaTitle: string | null
  metaDescription: string | null
  sharingTitle: string | null
  sharingDescription: string | null
  sharingImage: string | null
  twitterHandle: string | null
  favicon: string | null
  homeScreenImage: string | null
  insertedAt: any
  updatedAt: any
}

type State = {
  entries: { [id: string]: Version }
  haveLoaded: boolean
  nonDraft: Version[]
  currentDraft: Version | undefined
}

export const state: State = {
  haveLoaded: false,
  entries: {},
  nonDraft: derived((state: State) =>
    Object.values(state.entries)
      .filter((version) => !version.isDraft)
      .sort(
        (versionA, versionB) =>
          new Date(versionB.updatedAt).getTime() - new Date(versionA.updatedAt).getTime(),
      ),
  ),
  currentDraft: derived((state: State, rootState: Config['state']) =>
    Object.values(state.entries).find(
      (version) =>
        rootState.projects.active && version.id === rootState.projects.active.draftVersion.id,
    ),
  ),
}
