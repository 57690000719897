import * as React from 'react'
import styled from 'styled-components/macro'
import { extname } from 'path'
import { useActions, useState } from '../../../overmind'
import { FolderFile, isLockedFile } from '../../../overmind/namespaces/files/state'
import Menu from './Menu'
import Namer from './Namer'
import { ReactComponent as FileHappy } from './file-happy.svg'
import { ReactComponent as FileLocked } from './file-locked.svg'

export const FileWrapper = styled.div<{ isActive?: boolean }>`
  align-items: center;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.BACKGROUND : 'transparent'};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.PRIMARY : theme.colors.FOREGROUND_80)};
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-flow: row;
  flex-wrap: nowrap;
  font-family: 'Px';
  font-size: 14px;
  font-weight: 700;
  height: 35px;
  justify-content: flex-start;
  line-height: 35px;
  max-width: 100%;
  padding: 0 0 0 30px;
  position: relative;

  svg {
    margin-right: 10px;
    width: 20px;
  }

  svg path {
    fill: ${({ isActive, theme }) =>
      isActive ? theme.colors.PRIMARY : theme.colors.FOREGROUND_80};
  }
`

const Name = styled.div`
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Flex = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

const File: React.FunctionComponent<{ file: FolderFile }> = ({ file }) => {
  const {
    files: { activate, rename, setIsRenaming },
  } = useActions()
  useState()
  const [isHovering, setIsHovering] = React.useState(false)

  return (
    <FileWrapper
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      isActive={file.isActive}
      onClick={() => activate(file.id)}
    >
      {isLockedFile(file) ? <FileLocked /> : <FileHappy />}
      <Flex>
        {file.isRenaming ? (
          <Namer
            initialFilename={file.filename}
            done={(filename) => rename({ id: file.id, filename })}
            close={() => setIsRenaming({ isRenaming: false, fileId: file.id })}
            defaultExtension={extname(file.filename)}
          />
        ) : (
          <>
            <Name>{file.filename}</Name>
            {isHovering && (
              <Menu
                file={file}
                rename={() => setIsRenaming({ isRenaming: true, fileId: file.id })}
              />
            )}
          </>
        )}
      </Flex>
    </FileWrapper>
  )
}

export default File
