import { Action, AsyncAction } from '../../../overmind'
import { Project_project } from '../../effects/gql/projects/graphql-types/project'

export const load: Action<Project_project> = ({ state }, project) => {
  if (project.domains) {
    state.domains.entries = project.domains.nodes.reduce(
      (domains, domain) => ({ ...domains, [domain.id]: domain }),
      {},
    )
  }
  state.domains.haveLoaded = true
}

export const unload: Action = ({ state }) => {
  state.domains.entries = {}
  state.domains.haveLoaded = false
}

export const create: AsyncAction<{ url: string }, void | Error> = async (
  { state, effects },
  { url },
) => {
  try {
    const projectId = state.projects.activeId
    if (projectId) {
      const { domainCreate: newDomain } = await effects.gql.mutations.domainCreate({
        url,
        projectId,
      })
      if (newDomain) {
        state.domains.entries[newDomain.id] = newDomain
      } else {
        throw new Error('domain not created')
      }
    } else {
      throw new Error('no project found to attach domain to')
    }
  } catch (error) {
    console.error(error)
    if (error instanceof Error) {
      return error
    }
  }
}

export const remove: AsyncAction<string, void | Error> = async ({ state, effects }, id) => {
  try {
    const domain = state.domains.entries[id]
    const { domainDelete } = await effects.gql.mutations.domainDelete({ id })
    if (domainDelete && domain) {
      delete state.domains.entries[domain.id]
    } else {
      throw new Error('domain not deleted')
    }
  } catch (error) {
    console.error(error)
    if (error instanceof Error) {
      return error
    }
  }
}
