import { Query, gql } from 'overmind-graphql'
import { DocumentCreated, DocumentCreatedVariables } from './graphql-types/documentCreated'
import { DocumentUpdated, DocumentUpdatedVariables } from './graphql-types/documentUpdated'
import { DocumentDeleted, DocumentDeletedVariables } from './graphql-types/documentDeleted'
import { documentFields } from './queries'

export const documentCreated: Query<DocumentCreated, DocumentCreatedVariables> = gql`
  subscription DocumentCreated($projectId: ID!) {
    documentCreated(projectId: $projectId) {
      ...DocumentFields
    }
  }
  ${documentFields}
`

export const documentUpdated: Query<DocumentUpdated, DocumentUpdatedVariables> = gql`
  subscription DocumentUpdated($projectId: ID!) {
    documentUpdated(projectId: $projectId) {
      sessionId
      ...DocumentFields
    }
  }
  ${documentFields}
`

export const documentDeleted: Query<DocumentDeleted, DocumentDeletedVariables> = gql`
  subscription DocumentDeleted($projectId: ID!) {
    documentDeleted(projectId: $projectId) {
      id
    }
  }
`
