import * as React from 'react'
import styled from 'styled-components/macro'
import { string, boolean } from 'yup'
import { Form, FormTextInput, FormSelectInput, FormCheckboxInput } from '@superhi/design'
import { Colour } from '../Poppers'
import Popper from './Popper'
import { MarkerArrow } from './Markers'
import { FolderFile } from '../../overmind/namespaces/files/state'
import { useActions } from '../../overmind'

type FormData = {
  bodyClass: string | null
  metaDescription: string | null
  metaTitle: string | null
  sharingDescription: string | null
  sharingImage: string | null
  sharingTitle: string | null
  isPasswordProtected: boolean
  password: string
  passwordConfirmation: string
}

const PopperWrapper = styled.div<{ colour: Colour }>`
  padding: 0;
  width: 400px;
  z-index: 999;
`

const PopperInner = styled.div`
  background-color: ${({ theme }) => theme.colors.FOREGROUND_90};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.BACKGROUND};
  max-height: calc(100vh - 60px);
  overflow-y: scroll;
  padding: 20px 16px;

  label span {
    color: ${({ theme }) => theme.colors.BACKGROUND};
  }
  input,
  select {
    background-color: ${({ theme }) => theme.colors.FOREGROUND_90};
    border-color: ${({ theme }) => theme.colors.FOREGROUND_50};
    color: ${({ theme }) => theme.colors.BACKGROUND};
  }
`

const PageOptions: React.FunctionComponent<{ reference: HTMLElement; file: FolderFile }> = ({
  reference,
  file,
}) => {
  const {
    files: { update, getByFolder },
  } = useActions()

  const selectOptions = [{ value: '', display: 'No image' }].concat(
    getByFolder('image').map((image) => ({
      value: `/${image.filename}`,
      display: image.filename,
    })),
  )

  return (
    <Popper
      PopperWrapper={PopperWrapper}
      PopperArrow={MarkerArrow}
      reference={reference}
      placement="bottom"
      colour="FOREGROUND_90"
      offset={10}
    >
      {() => (
        <PopperInner>
          <Form<FormData>
            name="page-options"
            validationSchema={{
              bodyClass: string(),
              metaDescription: string(),
              metaTitle: string(),
              sharingDescription: string(),
              sharingImage: string(),
              sharingTitle: string(),
              isPasswordProtected: boolean(),
              password: string(),
              passwordConfirmation: string(),
            }}
            initialValues={{
              bodyClass: file.bodyClass || '',
              metaDescription: file.metaDescription || '',
              metaTitle: file.metaTitle || '',
              sharingDescription: file.sharingDescription || '',
              sharingImage: file.sharingImage || '',
              sharingTitle: file.sharingTitle || '',
              isPasswordProtected: file.isPasswordProtected,
              password: '',
              passwordConfirmation: '',
            }}
            submitButtonText="Update file"
            onSubmit={async (values, { setFieldError }) => {
              if (values.password !== values.passwordConfirmation) {
                const message = 'Password and password confirmation must match'
                setFieldError('password', message)
                setFieldError('passwordConfirmation', message)
              } else {
                await update({ id: file.id, ...values })
              }
            }}
          >
            <Form.Row>
              <FormTextInput
                name="bodyClass"
                ariaLabel="Body class"
                label="Body class"
                placeholder="e.g. about"
              />
            </Form.Row>
            <Form.Row>
              <FormTextInput
                name="metaTitle"
                ariaLabel="Default search engine title"
                label="Default search engine title"
                placeholder="e.g. My Awesome Website"
              />
            </Form.Row>
            <Form.Row>
              <FormTextInput
                name="metaDescription"
                ariaLabel="Default search engine description"
                label="Default search engine description"
                placeholder="e.g. Come and see all the fantastic projects that I can do for you"
              />
            </Form.Row>
            <Form.Row>
              <FormTextInput
                name="sharingTitle"
                ariaLabel="Default sharing title"
                label="Default sharing title"
                placeholder="e.g. My Awesome Website"
              />
            </Form.Row>
            <Form.Row>
              <FormTextInput
                name="sharingDescription"
                ariaLabel="Default sharing description"
                label="Default sharing description"
                placeholder="e.g. Come and see all the fantastic projects that I can do for you"
              />
            </Form.Row>
            <Form.Row>
              <FormSelectInput
                name="sharingImage"
                ariaLabel="Default sharing image"
                label="Default sharing image"
                options={selectOptions}
              />
            </Form.Row>

            <Form.Row>
              <FormCheckboxInput
                name="isPasswordProtected"
                ariaLabel="Password Protected?"
                label="Password Protected?"
              >
                Is your file password protected?
              </FormCheckboxInput>
            </Form.Row>

            <Form.Peek<FormData, FormData['isPasswordProtected']> name="isPasswordProtected">
              {({ meta }) =>
                meta.value && (
                  <>
                    <Form.Row>
                      <FormTextInput
                        name="password"
                        ariaLabel="Project password"
                        label="Project password"
                        placeholder=""
                        required
                      />
                    </Form.Row>
                    <Form.Row>
                      <FormTextInput
                        name="passwordConfirmation"
                        ariaLabel="Confirm project password"
                        label="Confirm project password"
                        placeholder=""
                        required
                      />
                    </Form.Row>
                  </>
                )
              }
            </Form.Peek>
          </Form>
        </PopperInner>
      )}
    </Popper>
  )
}

export default PageOptions
