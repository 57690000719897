import Sizzle from 'sizzle'

type Type = 'TAG' | 'ID' | 'CLASS' | 'PSEUDO' | 'ATTR' | '>' | '~' | '+'
type Match = { index: number; input: string }
type Token = { type: Type; value: string; matches: Match[] }

type InternalToken = {
  type: 'gap' | 'tag'
  gap: Type | undefined
  tag: string
  ids: string[]
  classes: string[]
  states: string[]
  attributes: string[]
}

const parseGap = (obj: InternalToken): string => {
  switch (obj.gap) {
    case '>':
      return 'directly inside'
    case '~':
      return 'that are siblings of'
    case '+':
      return 'after'
    default:
      return 'inside'
  }
}

const parseTag = (obj: InternalToken): string => {
  const states = cleanStates(obj.states)
  const tag = cleanTag(obj.tag)
  const start = isSingular(obj) ? 'the' : 'any'
  const withExtra = cleanAttributes(obj)
  return `${start} ${states}${tag}${withExtra}`
}

const cleanAttributes = ({ classes, ids, attributes }: InternalToken) => {
  const selectors: { [selector: string]: string } = {
    '=': ' ',
    '^=': ' starting with ',
    '~=': ' containing ',
    '*=': ' containing ',
    '$=': ' ending with ',
  }
  if (classes.length > 0 || ids.length > 0 || attributes.length > 0) {
    let extras = []
    ids = ids.map((id: string) => `"${id}"`)
    classes = classes.map((className: string) => `"${className}"`)

    if (ids.length > 1) {
      extras.push(`the ids ${toSentence(ids)}`)
    } else if (ids.length === 1) {
      extras.push(`the id ${ids[0]}`)
    }

    if (classes.length > 1) {
      extras.push(`the classes ${toSentence(classes)}`)
    } else if (classes.length === 1) {
      extras.push(`the class ${classes[0]}`)
    }

    attributes.forEach((a) => {
      let aSplit = a.split(/(=|\^=|~=|\$=|\*=])/)

      if (aSplit.length > 1) {
        extras.push(
          `${aSplit[0]}${selectors[aSplit[1]]}"${aSplit[2]
            .substring(0, aSplit[2].length - 1)
            .replace(/["']/g, '')}"]`,
        )
      } else if (aSplit.length === 1) {
        extras.push(`a ${a} attribute`)
      }
    })

    return ` with ${toSentence(extras)}`
  } else {
    return ''
  }
}

const toSentence = (arr: string[]): string =>
  arr.length > 1
    ? `${arr.slice(0, arr.length - 1).join(', ')} and ${arr.slice(-1).toString()}`
    : arr.join(' and ')

const cleanStates = (states: string[]): string =>
  states.length > 0 ? states.map((s) => cleanState(s)).join(', ') + ' ' : ''

const cleanState = (state: string): string => {
  const tag = state.split('(')[0]
  const matches = state.match(/\(([^)]+)\)/)
  const specials: { [state: string]: string } = {
    scope: 'scoped',
    hover: 'hovered',
    focus: 'focused',
    link: 'unvisited',
    valid: 'validated',
    'read-write': 'editable',
    'first-child': 'first',
    'last-child': 'last',
    'first-letter': 'first letter of a',
    'first-line': 'first line of a',
    after: 'content after a',
    before: 'content before a',
    selection: 'selected content of a',
    'first-of-type': 'first',
    'last-of-type': 'last',
  }
  if (state.slice(0, 4) === 'nth-' && matches) {
    return `${tag.split('-').join(' ')} that matches the pattern "${matches[1]}" and is a`
  } else {
    return `${specials[state] || state.split('-').join(' ')}`
  }
}

const isSingular = (obj: InternalToken) =>
  obj.tag === 'body' ||
  obj.tag === 'html' ||
  obj.states.some(
    (elem) =>
      [
        'after',
        'before',
        'selection',
        'default',
        'first-child',
        'last-child',
        'first-of-type',
        'last-of-type',
        'only-child',
        'first',
        'first-letter',
        'first-line',
        'root',
      ].indexOf(elem) > -1,
  )

const cleanTag = (tagName: string): string => {
  const specials: { [tagName: string]: string } = {
    a: 'link',
    img: 'image',
    nav: 'navigation',
    input: 'input',
    textarea: 'textarea',
    table: 'table',
    iframe: 'iframe',
    div: 'divider',
    blockquote: 'blockquote',
    html: 'root of the page',
    body: 'whole page',
    h1: 'first-level heading',
    h2: 'second-level heading',
    h3: 'third-level heading',
    h4: 'fourth-level heading',
    h5: 'fifth-level heading',
    h6: 'sixth-level heading',
    p: 'paragraph',
    '*': 'element',
    '': 'element',
  }

  return specials[tagName] || `${tagName} element`
}

const trimValue = (s: Token): string => s.value.slice(1)

const hasType =
  (type: Type) =>
  (el: Token): boolean =>
    el.type === type

const tagsToSentence = (selectors: string): string => {
  try {
    // @ts-ignore `.tokenize` isn't part of Sizzle's public API
    const allSelectors: Token[][] | undefined = Sizzle.tokenize(selectors.split('::').join(':'))
    if (!allSelectors) return ''
    const spacers = [' ', '+', '>', '~']

    const allMergedSelectors = allSelectors.map((selectors): Token[] | Token[][] =>
      selectors.reduce((merged: Token[] | Token[][], selector: Token, i) => {
        const prevSelector = selectors[i - 1]
        if (!prevSelector) {
          return [selector]
        } else if (spacers.includes(selector.type)) {
          return (merged as any[]).concat([selector])
        } else if (!spacers.includes(prevSelector.type)) {
          const [last, ...rest] = merged.reverse()
          return rest // we're playing with arrays of arrays here and ts doesn't like that
            .reverse()
            .concat([Array.isArray(last) ? last.concat(selector) : [last, selector]])
        } else {
          return (merged as any[]).concat([selector])
        }
      }, []),
    )

    const allFormattedSelectors = allMergedSelectors.map((mergedSelectors) =>
      (mergedSelectors as any[]).map((selector: Token | Token[]): InternalToken => {
        const els = Array.isArray(selector) ? selector : [selector]
        const spacer = els.find((el) => spacers.includes(el.type))
        const tag = els.find(hasType('TAG'))
        return {
          type: spacer ? 'gap' : 'tag',
          gap: spacer && spacer.type,
          tag: tag ? tag.value : '',
          ids: els.filter(hasType('ID')).map(trimValue),
          classes: els.filter(hasType('CLASS')).map(trimValue),
          states: els.filter((el) => ['CHILD', 'PSEUDO'].includes(el.type)).map(trimValue),
          attributes: els.filter(hasType('ATTR')).map((el) => el.value),
        }
      }),
    )

    const query = allFormattedSelectors
      .map((block) =>
        block
          .reverse()
          .map((obj) => {
            if (obj.type === 'gap') {
              return ` ${parseGap(obj)} `
            } else {
              return `<strong>${parseTag(obj)}</strong>`
            }
          })
          .join(''),
      )
      .join(' OR ')

    return `This selects ${query}.`
  } catch (error) {
    return ''
  }
}

export default tagsToSentence
