import { graphql } from 'overmind-graphql'
import * as sessionQueries from './session/queries'
import * as sessionMutations from './session/mutations'
import * as projectQueries from './projects/queries'
import * as projectMutations from './projects/mutations'
import * as projectSubscriptions from './projects/subscriptions'
import * as versionQueries from './versions/queries'
import * as versionMutations from './versions/mutations'
import * as versionSubscriptions from './versions/subscriptions'
import * as documentQueries from './documents/queries'
import * as documentMutations from './documents/mutations'
import * as documentSubscriptions from './documents/subscriptions'
import * as domainQueries from './domains/queries'
import * as domainMutations from './domains/mutations'
import * as editorSettingsMutations from './editorSettings/mutations'

export default graphql({
  queries: {
    ...sessionQueries,
    ...projectQueries,
    ...versionQueries,
    ...documentQueries,
    ...domainQueries,
  },
  mutations: {
    ...sessionMutations,
    ...projectMutations,
    ...versionMutations,
    ...documentMutations,
    ...domainMutations,
    ...editorSettingsMutations,
  },
  subscriptions: { ...projectSubscriptions, ...versionSubscriptions, ...documentSubscriptions },
})
