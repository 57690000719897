import * as React from 'react'
import styled from 'styled-components/macro'
import { useState } from '../../overmind'
import { Folder as IFolder } from '../Sidebar'
import DraggableFile from './DraggableFile'
import File from './File'
import { Add, Upload } from './File/Create'

const Wrapper = styled.div`
  padding: 30px 0 0;
`

const H4 = styled.h4`
  color: ${({ theme }) => theme.colors.PRIMARY};
  font-family: 'Px';
  font-size: 13px;
  font-weight: 700;
  padding: 0 30px 2px;
  text-transform: uppercase;
`

const Folder: React.FunctionComponent<{ folder: IFolder }> = ({ folder }) => {
  const {
    files: { folderedFiles },
  } = useState()
  // note that we use the `folderedFiles` state here rather than `actions.files.getByFolder()`
  // because overmind needs to know we want to re-render this component on foldered file changes
  const files = folderedFiles
    .filter((file) => file.folder === folder.slug)
    .sort((fileA, fileB) => fileA.rank - fileB.rank)

  return files.length || folder.showOnEmpty ? (
    <Wrapper>
      <H4>{folder.name}</H4>
      {files.map((file, i) => {
        const canDrag = !['_fonts.css', '_base.css'].includes(file.filename) && !file.isRenaming
        return canDrag ? (
          <DraggableFile key={file.id} index={i} file={file} />
        ) : (
          <File key={file.id} file={file} />
        )
      })}
      {folder.useUpload ? (
        <Upload value={folder.slug} />
      ) : (
        <Add value={folder.slug} defaultExtension={folder.defaultExtension} />
      )}
    </Wrapper>
  ) : null
}

export default Folder
