import { theme, VerticalSpacer } from '@superhi/design'
import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  text-align: center;
  ${theme.elevation.ELEVATION_50}
  border-radius:10px;
  background-color: ${theme.colors.WHITE};
  overflow: hidden;
`

export const Content = styled(VerticalSpacer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  @media ${theme.breakpoints.medium} {
    padding: 40px;
  }
  @media ${theme.breakpoints.large} {
    padding: 40px;
  }
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: ${theme.colors.PALE_BLUE_50};
  padding: 24px 24px;
  > * {
    margin: 4px 16px !important;
  }
`
