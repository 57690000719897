import { Mode } from '../namespaces/preview/state'

type OnPopState = (data: { url: string; back: boolean; mode: Mode; forward: boolean }) => void
type LastChange = 'REFRESH' | 'BACK' | 'FORWARD' | 'POPSTATE'
type Frame = {
  mode: Mode
  historyIndex: number
  historyTotal: number
  lastChange: LastChange
  iframe: HTMLIFrameElement | null
}

const initialFrame: Frame = {
  mode: 'FULLSCREEN',
  historyIndex: -1,
  historyTotal: -1,
  lastChange: 'POPSTATE',
  iframe: null,
}

export default (() => {
  let frames: { [key in Mode]: Frame } = {
    FULLSCREEN: initialFrame,
    INLINE: { ...initialFrame, mode: 'INLINE' },
  }

  const initialise = (options: { onPopState: OnPopState }) => {
    window.addEventListener(
      'message',
      async ({ data: action }: { data: { type: 'POPSTATE'; mode: Mode; url: string } }) => {
        const { mode } = action
        const frame = frames[mode]
        if (frame) {
          switch (action.type) {
            case 'POPSTATE': {
              if (frame.lastChange === 'POPSTATE') {
                frame.historyIndex = frame.historyIndex + 1
                frame.historyTotal = frame.historyIndex
              }
              frame.lastChange = 'POPSTATE'
              options.onPopState({
                url: action.url,
                mode,
                back: frame.historyIndex > 0,
                forward: frame.historyIndex < frame.historyTotal,
              })
              break
            }
          }
        }
      },
    )
  }

  const addIFrame = (incomingIFrame: HTMLIFrameElement, mode: Mode) => {
    frames[mode].iframe = incomingIFrame
    incomingIFrame.addEventListener('load', () => {
      if (incomingIFrame.contentWindow) {
        incomingIFrame.contentWindow.postMessage({ type: 'LOAD', mode }, '*')
      }
    })
  }

  const removeIFrame = (mode: Mode) => {
    frames[mode] = { ...initialFrame, mode }
  }

  const reload = (mode: Mode | 'ALL') => {
    if (mode === 'ALL') {
      Object.values(frames).forEach((frame) => {
        const { iframe } = frame
        if (iframe?.contentWindow) {
          frame.lastChange = 'REFRESH'
          iframe.contentWindow.postMessage({ type: 'REFRESH', mode }, '*')
        }
      })
    } else {
      const frame = frames[mode]
      if (frame.iframe?.contentWindow) {
        frame.lastChange = 'REFRESH'
        frame.iframe.contentWindow.postMessage({ type: 'REFRESH', mode }, '*')
      }
    }
  }

  const goBack = (mode: Mode) => {
    const frame = frames[mode]
    if (frame?.iframe?.contentWindow) {
      frame.historyIndex = frame.historyIndex - 1
      frame.lastChange = 'BACK'
      frame.iframe.contentWindow.postMessage({ type: 'BACK', mode }, '*')
    }
  }

  const goForward = (mode: Mode) => {
    const frame = frames[mode]
    if (frame.iframe?.contentWindow) {
      frame.historyIndex = frame.historyIndex + 1
      frame.lastChange = 'FORWARD'
      frame.iframe.contentWindow.postMessage({ type: 'FORWARD', mode }, '*')
    }
  }

  return { initialise, addIFrame, removeIFrame, reload, goBack, goForward }
})()
