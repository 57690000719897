import { Action, AsyncAction } from '../../../overmind'
import { Project } from './state'
import { ProjectUpdateVariables } from '../../effects/gql/projects/graphql-types/projectUpdate'
import { ProjectCreate_projectCreate } from '../../effects/gql/projects/graphql-types/projectCreate'
import { ProjectCreated } from '../../effects/gql/projects/graphql-types/projectCreated'
import { ProjectUpdated } from '../../effects/gql/projects/graphql-types/projectUpdated'
import { ProjectDeleted } from '../../effects/gql/projects/graphql-types/projectDeleted'
import { ProjectSort } from '../../effects/gql/graphql-global-types'

export const load: AsyncAction = async ({ state, effects, actions }) => {
  try {
    const { projects } = await effects.gql.queries.projects({})
    if (projects?.nodes && state.user) {
      const userId = state.user.id
      state.projects.hasNextPage = projects.pageInfo.hasNextPage
      state.projects.entries = projects.nodes.reduce(
        (projects, project) => ({ ...projects, [project.id]: project }),
        {},
      )
      state.projects.ordered = projects.nodes
      effects.gql.subscriptions.projectCreated({ userId }, actions.projects.onCreated)
      effects.gql.subscriptions.projectUpdated({ userId }, actions.projects.onUpdated)
      effects.gql.subscriptions.projectDeleted({ userId }, actions.projects.onDeleted)
    } else {
      throw new Error('error fetching projects')
    }
  } catch (error) {
    console.error(error)
  }
}

export const unload: Action = ({ state, effects }) => {
  state.projects.currentSort = ProjectSort.NEWEST
  state.projects.currentPage = 1
  state.projects.hasNextPage = false
  state.projects.entries = {}
  state.projects.ordered = []
  effects.gql.subscriptions.projectCreated.dispose()
  effects.gql.subscriptions.projectUpdated.dispose()
  effects.gql.subscriptions.projectDeleted.dispose()
}

export const buildUrl: Action<string | void, string> = ({ state, effects }, projectId) => {
  const project = projectId ? state.projects.entries[projectId] : state.projects.active
  const domain = effects.env.get('HOST_DOMAIN')
  const protocol = effects.env.get('HTTP_PROTOCOL')
  return `${protocol}${project?.slug}.${domain}`
}

export const buildDraftUrl: Action<void, string> = ({ state, effects }) => {
  const activeProject = state.projects.active
  const domain = effects.env.get('HOST_DOMAIN')
  const protocol = effects.env.get('HTTP_PROTOCOL')
  return `${protocol}${activeProject?.slug}-draft.${domain}`
}

export const buildEditorUrl: Action<void, string> = ({ state, effects }) => {
  const activeProject = state.projects.active
  const domain = effects.env.get('HOST_DOMAIN')
  const protocol = effects.env.get('HTTP_PROTOCOL')
  return `${protocol}${activeProject?.slug}-editor.${domain}`
}

export const waitForProject: AsyncAction<
  { projectId: string; count: number },
  Project | null
> = async ({ state, actions }, { projectId, count }) => {
  const project = state.projects.entries[projectId]
  if (project) {
    return project
  } else if (Object.keys(state.projects.entries).length || count > 1000) {
    return null
  } else {
    await new Promise(requestAnimationFrame)
    return actions.projects.waitForProject({ projectId, count: count + 1 })
  }
}

export const setActiveId: Action<string> = ({ state }, projectId) => {
  state.projects.activeId = projectId
}

// TODO: should we show a loading state?
export const activate: AsyncAction<string> = async ({ actions, effects }, projectId) => {
  actions.projects.setActiveId(projectId)
  try {
    // it's possible that we'll try to activate a project before the projects are in the store.
    // for example: if a user visits the project url directly and react-router sends the
    // `activate` action but overmind itself is still populating things. therefore we wait for
    // either the project to come back or the knowledge that the projects have loaded _or_
    // a timeout
    const currentProject = await actions.projects.waitForProject({ projectId, count: 0 })
    if (currentProject) {
      const { project } = await effects.gql.queries.project({ id: projectId })
      if (project) {
        // make sure current draft version is attached
        project.versions?.nodes.push(project.draftVersion)
        actions.files.load(project)
        actions.versions.load(project)
        actions.domains.load(project)
      } else {
        throw new Error('project not found')
      }
    } else {
      throw new Error('projects not loaded')
    }
  } catch (error) {
    console.error(error)
  }
}

export const deactivate: Action = ({ state, actions }) => {
  state.projects.activeId = null
  actions.files.unload()
  actions.versions.unload()
  actions.domains.unload()
}

export const updateCurrentSort: AsyncAction<keyof typeof ProjectSort> = async (
  { state, effects },
  newSort,
) => {
  state.projects.currentSort = newSort
  const { projects } = await effects.gql.queries.projects({ sort: ProjectSort[newSort] })
  if (projects?.nodes) {
    state.projects.currentPage = 1
    state.projects.hasNextPage = projects.pageInfo.hasNextPage
    state.projects.entries = projects.nodes.reduce(
      (projects, project) => ({ ...projects, [project.id]: project }),
      {},
    )
    state.projects.ordered = projects.nodes
  }
}

export const loadNextPage: AsyncAction = async ({ state, effects }) => {
  const nextPage = state.projects.currentPage + 1
  const { projects } = await effects.gql.queries.projects({
    sort: ProjectSort[state.projects.currentSort],
    page: nextPage,
  })
  if (projects?.nodes) {
    state.projects.currentPage = nextPage
    state.projects.hasNextPage = projects.pageInfo.hasNextPage
    projects.nodes.forEach((project) => {
      state.projects.entries[project.id] = project
      state.projects.ordered.push(project)
    })
  }
}

export const toggleView: Action = ({ state }) => {
  state.projects.currentView = state.projects.currentView === 'LIST' ? 'CARD' : 'LIST'
}

export const create: AsyncAction<string, ProjectCreate_projectCreate | null> = async (
  { state, effects },
  name,
) => {
  try {
    const { projectCreate: project } = await effects.gql.mutations.projectCreate({ name })
    if (project) {
      state.projects.entries[project.id] = project
      return project
    } else {
      throw new Error('project not created')
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export const update: AsyncAction<ProjectUpdateVariables> = async ({ state, effects }, project) => {
  try {
    const { projectUpdate: updatedProject } = await effects.gql.mutations.projectUpdate(project)
    if (updatedProject) {
      state.projects.entries[project.id] = {
        ...state.projects.entries[project.id],
        ...updatedProject,
      }
    } else {
      throw new Error('project not updated')
    }
  } catch (error) {
    console.error(error)
  }
}

export const remove: AsyncAction<string> = async ({ state, actions, effects }, id) => {
  const activeProject = state.projects.active
  try {
    const { projectDelete: project } = await effects.gql.mutations.projectDelete({ id })
    if (project) {
      if (project.id === activeProject?.id) {
        actions.projects.deactivate()
        effects.browser.history.push('/projects')
      }
      delete state.projects.entries[project.id]
    } else {
      throw new Error('project not deleted')
    }
  } catch (error) {
    console.error(error)
  }
}

export const onCreated: Action<ProjectCreated> = ({ state }, projectCreated) => {
  const { projectCreated: project } = projectCreated
  if (project) {
    state.projects.entries[project.id] = project
  }
}

export const onUpdated: Action<ProjectUpdated> = ({ state }, projectUpdated) => {
  const { projectUpdated: project } = projectUpdated
  if (project) {
    state.projects.entries[project.id] = project
  }
}

export const onDeleted: Action<ProjectDeleted> = ({ state }, projectDeleted) => {
  const { projectDeleted: project } = projectDeleted
  if (project) {
    delete state.projects.entries[project.id]
  }
}
