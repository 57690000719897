import axios from 'axios'
import { DocumentUpload_documentUpload } from '../gql/documents/graphql-types/documentUpload'

type Headers = () => { Authorization: string }

export default (() => {
  let headers: Headers | null
  const initialise = (options: { endpoint: string; headers: Headers }) => {
    axios.defaults.baseURL = options.endpoint
    headers = options.headers
  }

  const uploadDocument = async ({
    versionId,
    projectId,
    file,
    onProgress,
  }: {
    versionId: string
    projectId: string
    file: File
    onProgress: (progress: number) => void
  }): Promise<{ uploadDocument: DocumentUpload_documentUpload | null }> => {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('project_id', projectId)
    formData.append('version_id', versionId)
    if (headers) {
      const res = await axios.post<
        FormData,
        { data: { document: DocumentUpload_documentUpload | null } }
      >('/documents', formData, {
        headers: headers(),
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          onProgress(progress)
        },
      })
      return { uploadDocument: res.data.document }
    } else {
      return { uploadDocument: null }
    }
  }

  return { initialise, uploadDocument }
})()
