import { Action, AsyncAction } from '../../../overmind'
import { VersionUpdateVariables } from '../../effects/gql/versions/graphql-types/versionUpdate'
import { VersionCreated } from '../../effects/gql/versions/graphql-types/versionCreated'
import { VersionUpdated } from '../../effects/gql/versions/graphql-types/versionUpdated'
import { VersionDeleted } from '../../effects/gql/versions/graphql-types/versionDeleted'
import { Project_project } from '../../effects/gql/projects/graphql-types/project'
import { Version } from './state'

export const getUrl: Action<string, string> = ({ state, effects }, versionId) => {
  try {
    const domain = effects.env.get('HOST_DOMAIN')
    const protocol = effects.env.get('HTTP_PROTOCOL')
    const version = state.versions.entries[versionId]
    const activeProject = state.projects.active
    if (version && activeProject) {
      if (version.isLive) {
        return `${protocol}${activeProject.slug}.${domain}/`
      } else if (version.isDraft) {
        return `${protocol}${activeProject.slug}-draft.${domain}/`
      } else {
        return `${protocol}${activeProject.slug}-v-${version.key}.${domain}/`
      }
    } else {
      throw new Error('version not found')
    }
  } catch (error) {
    console.error(error)
    return ''
  }
}

export const getFriendlyKey: Action<string, string | undefined> = ({ state }, versionId) => {
  const version = state.versions.entries[versionId]
  if (version) {
    return version.key.replace(/[^0-9a-z]+/g, '').substr(0, 6)
  }
}

export const load: Action<Project_project> = ({ state, effects, actions }, project) => {
  if (project.versions) {
    state.versions.entries = project.versions.nodes.reduce(
      (versions, version) => ({ ...versions, [version.id]: version }),
      {},
    )
  }
  state.versions.haveLoaded = true
  effects.gql.subscriptions.versionCreated({ projectId: project.id }, actions.versions.onCreated)
  effects.gql.subscriptions.versionUpdated({ projectId: project.id }, actions.versions.onUpdated)
  effects.gql.subscriptions.versionDeleted({ projectId: project.id }, actions.versions.onDeleted)
}

export const unload: Action = ({ state, effects }) => {
  state.versions.entries = {}
  state.versions.haveLoaded = false
  effects.gql.subscriptions.versionCreated.dispose()
  effects.gql.subscriptions.versionUpdated.dispose()
  effects.gql.subscriptions.versionDeleted.dispose()
}

export const create: AsyncAction<
  {
    title: string
    isDraft?: boolean
    isLive?: boolean
    description?: string
    fromVersionId?: string
  },
  Version | undefined | null
> = async ({ state, effects }, version) => {
  try {
    const activeProject = state.projects.active
    if (activeProject) {
      const fullVersion = {
        projectId: activeProject.id,
        fromVersionId: activeProject.draftVersion.id,
        ...version,
      }
      const { versionCreate: newVersion } = await effects.gql.mutations.versionCreate(fullVersion)
      if (newVersion) {
        state.versions.entries[newVersion.id] = newVersion
        return newVersion
      } else {
        throw new Error('version not created')
      }
    } else {
      throw new Error('no project found to attach version to')
    }
  } catch (error) {
    console.error(error)
  }
}

export const publish: AsyncAction = async ({ actions, state }) => {
  const activeProject = state.projects.active
  const newVersion = await actions.versions.create({ title: 'Published version', isLive: true })
  if (activeProject && newVersion) {
    await actions.projects.update({ id: activeProject.id, liveVersionId: newVersion.id })
  }
}

export const revert: AsyncAction<string> = async ({ state, effects, actions }, versionId) => {
  try {
    const previousVersion = state.versions.entries[versionId]
    const activeProjectId = state.projects.activeId
    if (previousVersion && activeProjectId) {
      const newVersion = await actions.versions.create({
        title: previousVersion.title,
        fromVersionId: versionId,
        isDraft: true,
      })
      if (newVersion) {
        // TODO: is this the best way of handling moving versions around?
        // what happens if a version is changed in a different device etc. we should just
        // update the project draft version and in the project update subscription check
        // to see if it's different from previously and if so reload the project
        await actions.projects.update({ id: activeProjectId, draftVersionId: newVersion.id })
        actions.projects.deactivate()
        actions.projects.activate(activeProjectId)
      }
    } else {
      throw new Error('previous version not found')
    }
  } catch (error) {
    console.error(error)
  }
}

export const update: AsyncAction<VersionUpdateVariables> = async ({ state, effects }, version) => {
  try {
    const { versionUpdate: updatedVersion } = await effects.gql.mutations.versionUpdate(version)
    if (updatedVersion) {
      state.versions.entries[updatedVersion.id] = {
        ...state.versions.entries[updatedVersion.id],
        ...updatedVersion,
      }
    } else {
      throw new Error('version not updated')
    }
  } catch (error) {
    console.error(error)
  }
}

export const remove: AsyncAction<string> = async ({ state, effects }, id) => {
  const version = state.versions.entries[id]
  try {
    const { versionDelete } = await effects.gql.mutations.versionDelete({ id })
    if (version && versionDelete) {
      delete state.versions.entries[version.id]
    } else {
      throw new Error('version not deleted')
    }
  } catch (error) {
    console.error(error)
  }
}

export const onCreated: Action<VersionCreated> = ({ state }, versionCreated) => {
  const { versionCreated: version } = versionCreated
  if (version) {
    state.versions.entries[version.id] = version
  }
}

export const onUpdated: Action<VersionUpdated> = ({ state }, versionUpdated) => {
  const { versionUpdated: version } = versionUpdated
  if (version) {
    state.versions.entries[version.id] = {
      ...state.versions.entries[version.id],
      ...version,
    }
  }
}

export const onDeleted: Action<VersionDeleted> = ({ state }, versionDeleted) => {
  const { versionDeleted: version } = versionDeleted
  if (version) {
    delete state.versions.entries[version.id]
  }
}
