import * as React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useLockBodyScroll } from './Hooks'

const Wrapper = styled.div`
  background-color: rgba(246, 246, 246, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`

const Modal = styled.div`
  background: ${({ theme }) => theme.colors.BACKGROUND};
  border: 1px solid ${({ theme }) => theme.colors.FOREGROUND_20};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  left: 50%;
  max-width: 600px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 24px;
  @media (min-width: 601px) {
    padding: 24px 32px 32px 32px;
  }
`

const NewProject: React.FC<{ back: string }> = ({ children, back }) => {
  useLockBodyScroll()
  const history = useHistory()

  return (
    <Wrapper onClick={() => history.push(back)}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <Content>{children}</Content>
      </Modal>
    </Wrapper>
  )
}

export default NewProject
