import * as React from 'react'
import { ButtonComponent } from '../Button'
import List from './List'

const values = [
  { value: 'underline', hint: 'Underline text' },
  { value: 'line-through', hint: 'Strike through text' },
  { value: 'none', hint: 'Turn off any decoration' },
]

const TextDecoration: ButtonComponent = ({ button }) => {
  return <List values={values} button={button} />
}

export default TextDecoration
