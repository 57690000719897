import * as React from 'react'
import styled from 'styled-components/macro'
import Image from './Image'
import { useActions } from '../../overmind'
import { FolderFile } from '../../overmind/namespaces/files/state'

const Video = styled.video`
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
`

const Audio = styled.video`
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
`

const P = styled.p`
  object-fit: contain;
  font-size: 140%;
  max-height: 100%;
  max-width: 500px;
`

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  padding: 30px;
  position: relative;
  text-align: center;
  width: 100%;
`

const Unpreviewable: React.FunctionComponent<{ file: FolderFile; src: string }> = ({
  file,
  src,
}) => (
  <P>
    This document isn&#8217;t viewable in the SuperHi Editor, you can instead open it in a new tab:
    <br />
    <a href={src} target="_blank" rel="noopener noreferrer">
      {file.filename}
    </a>
  </P>
)

const Preview: React.FunctionComponent<{ file: FolderFile; src: string }> = ({ file, src }) => {
  if (file.folder === 'video') {
    return <Video src={src} controls={true} />
  } else if (file.folder === 'audio') {
    return <Audio src={src} />
  } else {
    return <Unpreviewable file={file} src={src} />
  }
}

const Media: React.FunctionComponent<{ file: FolderFile }> = ({ file }) => {
  const {
    files: { buildUrl },
  } = useActions()
  return file.folder === 'image' ? (
    <Image file={file} src={buildUrl(file)} />
  ) : (
    <Wrapper>
      <Preview file={file} src={buildUrl(file)} />
    </Wrapper>
  )
}

export default Media
