import * as React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro'
import { ThemeProvider as DesignThemeProvider } from '@superhi/design'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { useState, useEffects, useActions } from '../overmind'
import LoginPage from '../pages/Login'
import ProjectPage from '../pages/Project'
import ProjectsPage from '../pages/Projects'
import PrivateRoute from './PrivateRoute'
import NewProject from './NewProject'
import Modal from './Modal'
import Loading from './Loading'
import Keys from './Keys'
import { light } from './theme'

const EditorGlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'PlexMono';
    src: url('/IBMPlexMono-Text.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'PlexMono';
    src: url('/IBMPlexMono-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'PlexMono';
    src: url('/IBMPlexMono-SemiBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Px';
    src: url('https://superhi-assets.s3-us-west-1.amazonaws.com/fonts/px-grotesk/PxGrotesk-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
  }

  * {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
`

const App: React.FunctionComponent = () => {
  const { hasAppMounted, title } = useState()
  const { mountApp } = useActions()
  const {
    browser: { history },
  } = useEffects()

  React.useEffect(() => {
    mountApp()
  }, [mountApp])

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <DesignThemeProvider>
        <ThemeProvider theme={light}>
          <EditorGlobalStyles />
          <Keys />
          {hasAppMounted ? (
            <Router history={history}>
              <Switch>
                <Route path="/login">
                  <LoginPage />
                </Route>
                <PrivateRoute path="/projects/new">
                  <ProjectsPage />
                  <Modal back="/projects">
                    <NewProject />
                  </Modal>
                </PrivateRoute>
                <PrivateRoute path="/projects/:projectId">
                  <ProjectPage />
                </PrivateRoute>
                <PrivateRoute path="/projects">
                  <ProjectsPage />
                </PrivateRoute>
                <Redirect to="/projects" />
              </Switch>
            </Router>
          ) : (
            <Loading />
          )}
        </ThemeProvider>
      </DesignThemeProvider>
    </HelmetProvider>
  )
}

export default App
